import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { FiChevronUp } from 'react-icons/fi';

import Urls from '../../routes/urls';

import { Red, useReds } from '../../hooks/reds';

import isEmptyObject from '../../utils/isEmptyObject';

// import ViewVideo from '../../components/ViewVideo';
import getCategoryStructure from '../../utils/getCategoryStructure';
import HeaderTitle from '../../components/HeaderTitle';
import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';
import { useResponding } from '../../hooks/responding';

import { Container, TreeRow, TreeCol } from './styles';

interface RedParams {
  key: string;
}

const SelectRed: React.FC = () => {
  const [redDetail, setRedDetail] = useState<Red>({} as Red);
  const [categories, setCategories] = useState<string[]>([]);

  const { reds, onSelectedRed } = useReds();
  const { responding } = useResponding();

  const history = useHistory();
  const { params } = useRouteMatch<RedParams>();

  const handleOnSelectedRed = useCallback(
    (red: Red) => {
      onSelectedRed(red);
      history.push(
        getIdSpecificationRoute(Urls.SPECIFICATION.url, responding.id),
      );
    },
    [history, onSelectedRed, responding.id],
  );

  useEffect(() => {
    const red = reds.find(item => item.key === params.key);

    if (!red || isEmptyObject(red)) {
      history.push(
        getIdSpecificationRoute(Urls.SPECIFICATION.url, responding.id),
      );
    } else if (red !== undefined) {
      setRedDetail(red);
    }
  }, [reds, params, history, responding.id]);

  useEffect(() => {
    if (!isEmptyObject(redDetail) && redDetail.red_category) {
      const arrayCategories = Array<string>();

      getCategoryStructure(redDetail.red_category, arrayCategories);

      setCategories([...arrayCategories, redDetail.description]);
    }
  }, [redDetail]);

  return (
    <ContentTechnicalSpecification
      title="Deseja selecionar esse RED?"
      prev={{
        name: 'Voltar',
        icon: FiChevronUp,
        onAction: () =>
          history.push(
            getIdSpecificationRoute(Urls.SPECIFICATION.url, responding.id),
          ),
      }}
      done={{
        name: 'Selecionar',
        variant: 'primary',
        onAction: () => handleOnSelectedRed(redDetail),
      }}
    >
      <Container>
        <TreeRow items={categories} type="row" />
        <TreeCol items={categories} type="column" />

        <HeaderTitle title={redDetail.description} />

        <div>
          <h2>Termos internacionais:</h2>
          <div>{ReactHtmlParser(redDetail.international_terms)}</div>

          <h2>Definição:</h2>
          <div>{ReactHtmlParser(redDetail.definition)}</div>

          <h2>Principais funcionalidades:</h2>
          <div>{ReactHtmlParser(redDetail.main_features)}</div>

          <h2>Exemplos:</h2>
          <div>{ReactHtmlParser(redDetail.examples)}</div>
        </div>

        {/* <ViewVideo src="https://www.youtube.com/embed/dhoG-305Acw" /> */}

        <strong>Quer fazer a especificação offline?</strong>
        <a
          href="https://toolkit.plataformaedutec.com.br/files/apresentacao-grupos-toolkit.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Baixar um PDF com as especificação técnica dos 20 REDs
        </a>
      </Container>
    </ContentTechnicalSpecification>
  );
};

export default SelectRed;
