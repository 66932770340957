export default {
  title: 'light',

  // -------- Colors -----------
  colors: {
    primary: '#85C440',
    secundary: '#131D3C',
    info: '#28c3d7',
    success: '#02bc77',
    warning: '#ffd950',
    error: '#e74c3c',
    white: '#ffffff',
    black: '#000000',
    gray: 'rgba(24, 28, 33, 0.5)',
    grayDark: 'rgba(24, 28, 33, 0.8)',
    light: 'rgba(24, 28, 33, 0.06)',
    dark: 'rgba(24, 28, 33, 0.9)',
    blue: '#1e70cd',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#e83e8c',
    red: '#e74c3c',
    danger: '#d9534f',
    orange: '#feb744',
    yellow: '#ffd950',
    green: '#02bc77',
    teal: '#20c997',
    cyan: '#28c3d7',

    background: '#fafafa',
    text: '#707070',

    btnPrimaryColor: '#fff',
    btnPrimaryBg: '#85C440',

    btnDefaultColor: '#85C440',
    btnDefaultBg: '#F3F3F3',
  },

  screen: {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
};
