import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  variant?: 'success' | 'info' | 'warning' | 'error';
}

const alertVariant = {
  success: css`
    background: ${props => tint(0.9, props.theme.colors.success)};
    border-color: ${props => tint(0.5, props.theme.colors.success)};

    > svg {
      color: ${props => props.theme.colors.success};
    }
  `,
  info: css`
    background: ${props => tint(0.9, props.theme.colors.info)};
    border-color: ${props => tint(0.5, props.theme.colors.info)};

    > svg {
      color: ${props => props.theme.colors.info};
    }
  `,
  warning: css`
    background: ${props => tint(0.85, props.theme.colors.warning)};
    border-color: ${props => tint(0.5, props.theme.colors.warning)};

    > svg {
      color: ${props => props.theme.colors.warning};
    }
  `,
  error: css`
    background: ${props => tint(0.9, props.theme.colors.error)};
    border-color: ${props => tint(0.5, props.theme.colors.error)};

    > svg {
      color: ${props => props.theme.colors.error};
    }
  `,
};

export const Container = styled.div<ContainerProps>`
  background: #f2f2f2;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;

  display: flex;

  ${props => alertVariant[props.variant || 'info']}

  > svg {
    margin-right: 15px;
    font-size: 22px;
  }

  strong {
    color: ${props => props.theme.colors.black};
  }

  p {
    font-weight: 300;
  }

  ul {
    margin-top: 15px;

    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }
`;
