import React from 'react';

import { Container } from './styles';

interface ProgressProps {
  title?: string;
  progress: number;
  className?: string;
}

const Progress: React.FC<ProgressProps> = ({
  progress = 0,
  title,
  className,
}) => {
  return (
    <Container className={className} progress={progress}>
      {title && <span>{title}</span>}

      <div />
    </Container>
  );
};

export default Progress;
