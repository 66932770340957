import React, { useCallback, useEffect, useState } from 'react';
import {
  FiChevronDown,
  FiChevronUp,
  FiMaximize,
  FiMinimize,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';

import {
  RequirementCategory,
  Requirement as IRequirement,
} from '../../hooks/reds';

import { useRoutesRequirements } from '../../hooks/routesRequirements';
import useStepper from '../../hooks/useStepper';
import { useResponding } from '../../hooks/responding';
import { useCalculateProdess } from '../../hooks/CalculateProdess';
import { useToast } from '../../hooks/toast';

import Urls from '../../routes/urls';
import { getLegentStatus } from '../../utils/const';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';

import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import HeaderTitle from '../../components/HeaderTitle';
import ItemRespondingRequirements from '../../components/ItemRespondingRequirements';
import Legend from '../../components/Legend';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import ModalDiagram from '../../components/ModalDiagram';

import {
  Container,
  ContentRespondingRequirements,
  TreeRow,
  TreeCol,
  CategoryDivider,
  CategoryContent,
  ModalContentInfo,
  TitleActionExpandAll,
} from './styles';

interface ErrorUpdateResponding {
  key: number;
  title: string;
}

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

let indexAlphabet = -1;

const RespondingRequirements: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<number[]>([]);
  const [structure, setStructure] = useState<string[]>([]);
  const [isNonFunctional, setIsNonFunctional] = useState(true);
  const [category, setCategory] = useState<RequirementCategory>(
    {} as RequirementCategory,
  );
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [visibleDiagram, setVisibleDiagram] = useState(false);
  const [errorsUpdateResponding, setErrorsUpdateResponding] = useState<
    ErrorUpdateResponding[]
  >([]);

  const { routesRequirement } = useRoutesRequirements();
  const { nextRoute, prevRoute, current } = useStepper();
  const { updateResponding, responding } = useResponding();
  const { generateProgress, progress } = useCalculateProdess();
  const { addToast } = useToast();

  const history = useHistory();

  const handlePrevRoute = useCallback(() => {
    setErrorsUpdateResponding([]);

    if (current === 0 || current === -1) {
      history.push(
        getIdSpecificationRoute(Urls.SPECIFICATION.url, responding.id),
      );
    } else {
      prevRoute();
    }
  }, [current, history, prevRoute, responding.id]);

  const handleNextRoute = useCallback(async () => {
    try {
      setLoading(true);

      await updateResponding({
        responding: {
          ...responding,
          validate_requirement_category_id: category.id,
        },
        progress,
        isUpdade: true,
      });

      setLoading(false);

      if (current === routesRequirement.length - 1) {
        history.push(
          getIdSpecificationRoute(Urls.SPECIFICATION_TREE.url, responding.id),
        );
      } else {
        nextRoute();
      }

      setLoading(false);
      setErrorsUpdateResponding([]);
    } catch (err) {
      setLoading(false);

      if (err.isAxiosError) {
        const response = err.response as AxiosResponse;

        if (response?.status === 422 && response?.data?.errors) {
          const errors = response.data.errors.map(
            (error: string, index: number) => ({
              key: index,
              title: error,
            }),
          );

          setErrorsUpdateResponding(errors);

          addToast({
            type: 'warning',
            title: 'Não foi possível avançar.',
            description:
              'Verifique a legenda dessa categoria de requisitos e tente novamente.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Error!',
            description: 'Ocorreu um erro ao salvar, tente novamente.',
          });
        }
      }
    }
  }, [
    current,
    nextRoute,
    updateResponding,
    routesRequirement.length,
    history,
    category.id,
    responding,
    progress,
    addToast,
  ]);

  const handleExpandedItem = useCallback(
    (itemId: number) => {
      setExpanded(_.xor(expanded, [itemId]));
    },
    [expanded],
  );

  const handleSelected = useCallback(
    async (requirement: IRequirement) => {
      const functional_requirements = [...responding.functional_requirements];
      const non_functional_requirements = [
        ...responding.non_functional_requirements,
      ];

      if (isNonFunctional) {
        const requimentIndex = non_functional_requirements.findIndex(
          item => item === requirement.id,
        );

        if (requimentIndex >= 0) {
          non_functional_requirements.splice(requimentIndex, 1);
        } else {
          non_functional_requirements.push(requirement.id);
        }
      } else {
        const requimentIndex = functional_requirements.findIndex(
          item => item === requirement.id,
        );

        if (requimentIndex >= 0) {
          functional_requirements.splice(requimentIndex, 1);
        } else {
          functional_requirements.push(requirement.id);
        }
      }

      await updateResponding({
        responding: {
          ...responding,
          functional_requirements,
          non_functional_requirements,
        },
        progress,
        isUpdade: false,
      });
    },
    [responding, updateResponding, isNonFunctional, progress],
  );

  const getAlphabetIndex = useCallback((): number => {
    indexAlphabet += 1;
    return indexAlphabet;
  }, []);

  const handleCloseModal = useCallback(() => {
    setVisibleInfo(false);
    setVisibleDiagram(false);
  }, []);

  useEffect(() => {
    if (responding?.finished) {
      history.replace(getIdSpecificationRoute(Urls.SPECIFICATION.url));
    }
  }, [history, responding.finished]);

  useEffect(() => {
    let currentRoute = current;

    if (currentRoute === -1) {
      currentRoute = 0;
    }

    if (routesRequirement.length > 0) {
      const requirement = routesRequirement[currentRoute];
      const { requirements } = requirement.requirement_categories;

      setStructure(requirement.structure);
      setIsNonFunctional(requirement.isNonFunctional);
      setCategory(requirement.requirement_categories);

      if (requirements && requirements.length > 0) {
        setExpanded([requirements[0].id]);
      }
    }
  }, [current, routesRequirement]);

  useEffect(() => {
    if (current > 0) {
      generateProgress(current);
    }
  }, [generateProgress, current]);

  indexAlphabet = -1;

  const expandAll = useCallback(() => {
    setExpanded([
      ..._.map(category.requirements, 'id'),
      ..._.map(
        _.flatMap(category.requirement_categories, 'requirements'),
        'id',
      ),
    ]);
  }, [category]);

  const shrinkAll = useCallback(() => {
    setExpanded([]);
  }, []);

  return (
    <>
      <ContentTechnicalSpecification
        loading={loading}
        title={
          isNonFunctional
            ? 'Requisitos não funcionais'
            : 'Requisitos funcionais'
        }
        handleInfo={() => setVisibleInfo(true)}
        handleDiagram={() => setVisibleDiagram(true)}
        prev={{
          name: 'Voltar',
          icon: FiChevronUp,
          disabled: loading,
          onAction: handlePrevRoute,
        }}
        next={{
          name: 'Avançar',
          icon: FiChevronDown,
          loading,
          onAction: handleNextRoute,
        }}
        done={{
          name: 'Salvar',
          variant: 'primary',
          isSave: true,
          loading,
          onAction: handleNextRoute,
        }}
      >
        <Container>
          {structure.length > 1 && (
            <>
              <TreeRow items={structure} type="row" />
              <TreeCol items={structure} type="column" />
            </>
          )}

          <strong>{category.description}</strong>
          {category.full_description && (
            <p>{ReactHtmlParser(category.full_description)}</p>
          )}

          <ContentRespondingRequirements>
            <TitleActionExpandAll>
              <div>
                <Button icon={FiMaximize} onClick={expandAll} />
                <Button icon={FiMinimize} onClick={shrinkAll} />
              </div>
            </TitleActionExpandAll>

            {errorsUpdateResponding.length > 0 && (
              <Alert
                variant="warning"
                title="Não foi possível avançar."
                description="Verifique a legenda dessa categoria de requisitos e tente novamente."
                items={errorsUpdateResponding}
              />
            )}

            {category.requirements?.map(item => (
              <ItemRespondingRequirements
                key={item.id}
                keyItem={item.id}
                keyPress={alphabet[getAlphabetIndex()]}
                title={item.description}
                description={item.full_description || ''}
                expanded={_.includes(expanded, item.id)}
                variant={item.legend}
                selected={
                  isNonFunctional
                    ? _.includes(
                        responding.non_functional_requirements,
                        item.id,
                      )
                    : _.includes(responding.functional_requirements, item.id)
                }
                onExpanded={handleExpandedItem}
                onSelect={() => handleSelected(item)}
              />
            ))}

            {category.requirement_categories &&
              category.requirement_categories.map(item => (
                <div key={item.id}>
                  <CategoryDivider>
                    <div className={getLegentStatus('')} />
                    <strong>{item.description}</strong>
                  </CategoryDivider>

                  <CategoryContent>
                    {item.requirements?.map(r => (
                      <ItemRespondingRequirements
                        key={r.id}
                        keyItem={r.id}
                        keyPress={alphabet[getAlphabetIndex()]}
                        title={r.description}
                        description={r.full_description || ''}
                        expanded={_.includes(expanded, r.id)}
                        variant={r.legend}
                        selected={
                          isNonFunctional
                            ? _.includes(
                                responding.non_functional_requirements,
                                r.id,
                              )
                            : _.includes(
                                responding.functional_requirements,
                                r.id,
                              )
                        }
                        onExpanded={handleExpandedItem}
                        onSelect={() => handleSelected(r)}
                      />
                    ))}
                  </CategoryContent>
                </div>
              ))}
          </ContentRespondingRequirements>
          <Legend />
        </Container>
      </ContentTechnicalSpecification>

      <Modal visible={visibleInfo} onClose={handleCloseModal}>
        <HeaderTitle
          title={
            isNonFunctional
              ? 'Requisitos não funcioanis'
              : 'Requisitos funcionais'
          }
        />

        <ModalContentInfo>
          {isNonFunctional && (
            <p>
              Requisitos não funcionais são aqueles ligados às condições de
              aquisição e uso do RED. Preste atenção à legenda presente ao final
              desta página para saber quais as regras de seleção de cada um dos
              requisitos. Sugerimos que o preenchimento seja realizado com apoio
              da área de TI.
            </p>
          )}

          {!isNonFunctional && (
            <p>
              Requisitos funcionais são aqueles ligados às funcionalidades do
              bem ou serviço que se pretende adquirir. Em outras palavras, o
              requisito funcional representa o que o recurso educacional faz, ou
              seja, quais tarefas e serviços ele executa. Preste atenção à
              legenda presente ao final desta página para saber quais as regras
              de seleção de cada um dos requisitos. Sugerimos que o
              preenchimento seja realizado com apoio da área de TI.
            </p>
          )}
        </ModalContentInfo>
      </Modal>

      <ModalDiagram
        idSpecification={responding.id}
        visible={visibleDiagram}
        title="Minha especificação"
        onClose={handleCloseModal}
      />
    </>
  );
};

export default RespondingRequirements;
