import React from 'react';

import { Container, Loading } from './styles';

interface LoaderProps {
  isInFront?: boolean;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ isInFront = false, className }) => {
  return (
    <Container className={className} isInFront={isInFront}>
      <Loading />
    </Container>
  );
};

export default Loader;
