import React, { createContext, useCallback, useContext, useState } from 'react';
import { useRoutesRequirements } from './routesRequirements';

interface CalculateProdessContextData {
  progress: number;
  startProgress(current: number): void;
  generateProgress(current: number): void;
}

const CalculateProdessContext = createContext<CalculateProdessContextData>(
  {} as CalculateProdessContextData,
);

const CalculateProdessProvider: React.FC = ({ children }) => {
  const [progress, setProgress] = useState<number>(() => {
    const progressStorage = localStorage.getItem('@Taxonomia:progress');

    if (progressStorage) {
      return JSON.parse(progressStorage) || 0;
    }

    return 0;
  });

  const { routesRequirement } = useRoutesRequirements();

  const startProgress = useCallback((current: number) => {
    setProgress(current);
  }, []);

  const generateProgress = useCallback(
    (current: number) => {
      let newProgress = progress;

      if (routesRequirement.length > 0) {
        const quantityRoutes = routesRequirement.length + 3;

        const progressDecimal = (current / quantityRoutes) * 100;

        newProgress = Math.floor(progressDecimal);

        localStorage.setItem(
          '@Taxonomia:progress',
          JSON.stringify(newProgress),
        );
        setProgress(newProgress);
      } else {
        localStorage.setItem(
          '@Taxonomia:progress',
          JSON.stringify(newProgress),
        );

        setProgress(current);
      }
    },
    [routesRequirement, progress],
  );

  return (
    <CalculateProdessContext.Provider
      value={{ progress, startProgress, generateProgress }}
    >
      {children}
    </CalculateProdessContext.Provider>
  );
};

function useCalculateProdess(): CalculateProdessContextData {
  const context = useContext(CalculateProdessContext);

  if (!context) {
    throw new Error(
      'useCalculateProdess must be used within an CalculateProdessProvider',
    );
  }

  return context;
}

export { CalculateProdessProvider, useCalculateProdess };
