import styled, { css } from 'styled-components';
import { shade, tint } from 'polished';

interface ContainerProps {
  variant?: 'default' | 'primary';
  block?: boolean;
}

const buttonVariant = {
  default: css`
    border-color: ${props => tint(0.9, props.theme.colors.black)};
    color: ${props => props.theme.colors.primary};

    &:hover {
      background: ${props => tint(0.97, props.theme.colors.black)};
    }
  `,
  primary: css`
    background: ${props => props.theme.colors.primary};
    border-color: ${props => tint(0.9, props.theme.colors.primary)};
    color: ${props => props.theme.colors.btnPrimaryColor};

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.primary)};
    }
  `,
};

export const Container = styled.a<ContainerProps>`
  min-height: 42px;
  border-radius: 10px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  transition: background-color 0.2s;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${props => buttonVariant[props.variant || 'default']}

  ${props =>
    props.block &&
    css`
      width: 100%;
    `}
`;
