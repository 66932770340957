import styled, { css } from 'styled-components';
import { shade, tint, transparentize } from 'polished';

import { LEGEND } from '../../../utils/const';

interface ButtonRedProps {
  variant?: string;
  disabled?: boolean;
  isActive?: boolean;
}

export const Container = styled.ul`
  li {
    position: relative;

    > div {
      background-color: ${prosp => prosp.theme.colors.white};
      position: absolute;
      left: 80%;
      top: 50%;
      width: 100%;
      max-width: 270px;
      padding: 15px;
      border-radius: 8px;
      transform: translateY(-50%);
      box-shadow: 0 0 10px
        ${prosp => transparentize(0.9, prosp.theme.colors.black)};
      z-index: 10;
      /* display: none; */

      &::before {
        content: '';
        position: absolute;
        left: -9px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid ${prosp => prosp.theme.colors.white};
        border-bottom: 10px solid transparent;
      }

      strong {
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  @media (max-width: 999px) {
    padding-left: 20px;

    > li {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: ${props => props.theme.colors.primary};
        width: 2px;
        height: calc(100% + 20px);
        left: -19px;
        top: -20px;
      }

      &:last-child::before {
        height: calc(50% + 20px);
      }
    }
  }
`;

const buttonRedTypeVariations = (type: string) => {
  switch (type) {
    case LEGEND.OPCIONAL:
      return css`
        background: ${props => tint(0.9, props.theme.colors.green)};
        border-color: ${props => props.theme.colors.green};

        &:not(:disabled):hover {
          background: ${props => tint(0.84, props.theme.colors.green)};
        }
      `;

    case LEGEND.AO_MENOS_UM:
      return css`
        background: ${props => tint(0.9, props.theme.colors.yellow)};
        border-color: ${props => props.theme.colors.yellow};

        &:not(:disabled):hover {
          background: ${props => tint(0.84, props.theme.colors.yellow)};
        }
      `;

    case LEGEND.UM_E_APENAS_UM:
      return css`
        background: ${props => tint(0.8, props.theme.colors.indigo)};
        border-color: ${props => props.theme.colors.indigo};

        &:not(:disabled):hover {
          background: ${props => tint(0.84, props.theme.colors.indigo)};
        }
      `;

    case LEGEND.OBRIGATORIO:
      return css`
        background: ${props => tint(0.9, props.theme.colors.error)};
        border-color: ${props => props.theme.colors.error};

        &:not(:disabled):hover {
          background: ${props => tint(0.84, props.theme.colors.error)};
        }
      `;

    default:
      return css`
        background: ${props => tint(0.95, props.theme.colors.black)};
        border-color: ${props => tint(0.9, props.theme.colors.black)};

        &:not(:disabled):hover {
          background: ${props => tint(0.92, props.theme.colors.black)};
        }
      `;
  }
};

export const ButtonRed = styled.button<ButtonRedProps>`
  width: 100%;
  background: ${props => tint(0.95, props.theme.colors.black)};
  min-height: 42px;
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 10px 16px;
  margin-top: 10px;
  color: ${props => shade(0.2, props.theme.colors.text)};
  font-size: 16px;
  font-weight: 300;
  transition: background 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  ${props => buttonRedTypeVariations(props.variant || '')}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > span {
    flex: 1;
  }

  > svg {
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 20px;
    color: ${props => props.theme.colors.primary};
    transition: color 0.2s;

    &.red-remove-icon {
      display: none;
    }
  }

  ${props =>
    props.isActive &&
    css`
      font-weight: 500;

      &:hover {
        > span,
        > svg {
          color: ${props.theme.colors.error};
        }

        svg {
          &:not(.red-remove-icon) {
            display: none;
          }

          &.red-remove-icon {
            display: block;
          }
        }
      }
    `}

  @media (max-width: 999px) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: ${props => props.theme.colors.primary};
      width: 20px;
      height: 2px;
      left: -20px;
      top: 50%;
    }
  }
`;
