import styled from 'styled-components';
import { tint, transparentize } from 'polished';

export const Container = styled.div`
  padding: 30px;

  > p {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;

export const Card = styled.div`
  background: ${props => props.theme.colors.white};
  height: 100%;
  border-radius: 12px;
  border: 1px solid ${props => tint(0.95, props.theme.colors.black)};
  overflow: hidden;

  transition: transform 0.2s, box-shadow 0.2s;

  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 3px 6px
      ${props => transparentize(0.8, props.theme.colors.dark)};
  }
`;

export const ContentCard = styled.div`
  flex: 1;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  strong {
    display: block;
    margin-top: 15px;
    font-size: 18px;
    color: ${props => props.theme.colors.secundary};
  }

  p {
    margin-top: 10px;
  }

  a {
    margin-top: 15px;
  }
`;
