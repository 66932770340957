import styled, { keyframes } from 'styled-components';
import Tree from '../../components/Tree';

const appearNavRigth = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 30px;
  animation: ${appearNavRigth} 1s;

  > h1 {
    margin-top: 30px;
  }

  > div {
    margin-top: 30px;

    + div {
      max-width: 500px;
    }

    > h2 {
      margin-bottom: 5px;
      font-size: 20px;
      color: ${props => props.theme.colors.secundary};
    }

    > div {
      margin-bottom: 15px;
    }

    div > ul {
      li {
        position: relative;
        padding-left: 25px;
        margin-top: 5px;

        &::before {
          content: '';
          position: absolute;
          background: ${props => props.theme.colors.primary};
          width: 15px;
          height: 6px;
          left: 0;
          top: 8px;
          border-radius: 3px;
        }
      }
    }
  }

  > strong {
    margin-top: 30px;
    font-size: 18px;
    color: ${props => props.theme.colors.secundary};
    display: block;
  }

  > a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;

export const TreeRow = styled(Tree)`
  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    display: none;
  }
`;

export const TreeCol = styled(Tree)`
  display: none;

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    display: block;
  }
`;
