import { tint, transparentize } from 'polished';
import styled from 'styled-components';

import imgBGMain from '../../assets/bg-content.svg';
import Loader from '../../components/Loader';

export const Container = styled.div`
  background: ${props => tint(0.981, props.theme.colors.black)};
  height: 100vh;
  overflow: hidden;
`;

export const Main = styled.main`
  background: transparent url(${imgBGMain}) 0% 0% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100% - 80px);
  margin-top: 80px;
  padding: 0 30px;
  overflow: auto;

  > div {
    max-width: 1120px;
    margin: auto;
    padding: 100px 0;

    > div {
      margin-top: 30px;
    }
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    height: calc(100% - 70px);
    margin-top: 70px;
    padding: 0 20px;

    > div {
      padding: 50px 0;
    }
  }
`;

export const LoaderSpecifications = styled(Loader)`
  height: 100%;
  padding: 5rem 0;
`;

export const CardItem = styled.div`
  background: ${props => props.theme.colors.white};
  height: 350px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 10px
    ${props => transparentize(0.92, props.theme.colors.black)};
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: scale(1.02);
  }
`;

export const CardItemContent = styled.div`
  flex: 1;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > svg {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
  }

  > div {
    flex: 1;
    position: relative;
    margin-top: 20px;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      height: 50px;
      left: 0;
      bottom: 0;
    }

    strong {
      font-size: 18px;
      color: ${props => props.theme.colors.secundary};
    }

    span {
      display: block;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
    }

    ul {
      margin-top: 10px;

      li {
        display: flex;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
`;

export const CardActions = styled.div`
  margin-top: 20px;

  display: flex;

  button {
    + button {
      margin-left: 15px;
    }
  }
`;

export const CardItemButton = styled.div`
  background: ${props => tint(0.99, props.theme.colors.black)};
  height: 350px;
  padding: 20px;
  border-radius: 12px;
  border: 2px dashed ${props => tint(0.9, props.theme.colors.black)};
  transition: transform 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.02);
  }

  svg {
    width: 50px;
    height: 50px;
    color: ${props => props.theme.colors.primary};
  }
`;

export const DateCreate = styled.span`
  position: absolute;
  top: 0;
  right: 0;
`;
