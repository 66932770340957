import { createGlobalStyle } from 'styled-components';
import { tint } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    background: #FFF;
    min-height: 100%;
    color: ${props => props.theme.colors.text};
  }

  *, input, button {
    border: 0;
    background: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .optional-status {
    background: ${props => tint(0.9, props.theme.colors.green)};
    border-color: ${props => props.theme.colors.green};
  }

  .at-least-one-status {
    background: ${props => tint(0.9, props.theme.colors.yellow)};
    border-color: ${props => props.theme.colors.yellow};
  }

  .one-and-minus-one-status {
    background: ${props => tint(0.9, props.theme.colors.indigo)};
    border-color: ${props => props.theme.colors.indigo};
  }

  .mandatory-status {
    background: ${props => tint(0.9, props.theme.colors.error)};
    border-color: ${props => props.theme.colors.error};
  }

  .default-status {
    background: ${props => tint(0.95, props.theme.colors.black)};
    border-color: ${props => tint(0.9, props.theme.colors.black)};
  }
`;
