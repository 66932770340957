import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 30px;
  overflow: hidden;

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;
