import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FiFileText, FiLogIn } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';

import { RequirementCategory, useReds } from '../../hooks/reds';
import { useNonFunctional } from '../../hooks/nonFunctional';

import Urls from '../../routes/urls';

import imgLogoWhite from '../../assets/logo-white.svg';

import { URL_NEW_SPECIFICATION } from '../../utils/const';
import { useResponding } from '../../hooks/responding';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import {
  HeaderLayoutCustom,
  Container,
  Navigation,
  MenuItem,
  TailItem,
  Content,
} from './styles';

interface RouteParams {
  idSpecification: string;
  keyRed: string;
  keyCategory: string;
}

interface RespondingResponse {
  id: string;
  what_is: string;
  how: string;
  what_you_want_acquire: string;
  description_requirement_nf: string;
  description_requirement_f: string;
  observation_1: string;
  observation_2: string;
  functional_requirements: {
    id: number;
  }[];
  non_functional_requirements: {
    id: number;
  }[];
  quantities: {
    description: string;
    estimated_quantity: number;
    unit_of_measurement: string;
  }[];
  red: {
    id: number;
    requirement_categories: RequirementCategory;
  };
  additional_reds: Array<{
    id: number;
    requirement_categories: RequirementCategory;
  }>;
  progress?: number;
  finished?: boolean;
}

const TechnicalSpecification: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState(1);
  const { selectedReds, setRedData } = useReds();
  const { nonFunctional } = useNonFunctional();
  const { initResponding, setRespondingData } = useResponding();
  const { user, signOut } = useAuth();

  const history = useHistory();

  const match = useRouteMatch<RouteParams>([
    Urls.FUNCTIONAL_REQUIREMENT.path,
    Urls.NON_FUNCTIONAL_REQUIREMENT.path,
    Urls.FILL_IN_FINAL_DATA.path,
    Urls.SPECIFICATION_TREE.path,
    Urls.REVIEW_SPECIFICATION.path,
    Urls.NEXT_STEPS.path,
    Urls.SELECT_RED_DETAIL.path,
    Urls.SPECIFICATION.path,
  ]);

  const menus = [
    { key: 1, title: 'Selecione seu RED' },
    { key: 2, title: 'Defina os requisitos funcionais' },
    { key: 3, title: 'Defina os requisitos não funcionais' },
    { key: 4, title: 'Confirme e exporte sua especificação' },
  ];

  const options = [
    {
      id: '1',
      title: 'Especificações',
      value: 'specifications',
      icon: FiFileText,
      action: () => history.push(Urls.SPECIFICATIONS.url),
    },
    { id: '2', title: 'Sair', value: 'close', icon: FiLogIn, action: signOut },
  ];

  const getSpecificationById = useCallback(async () => {
    if (match?.params.idSpecification === URL_NEW_SPECIFICATION) {
      initResponding();

      history.push(getIdSpecificationRoute(Urls.SPECIFICATION.url));
    } else {
      const id = match?.params.idSpecification || '';

      const { data } = await api.get<RespondingResponse>(
        `specifications/${id}`,
      );

      setRespondingData({
        ...data,
        functional_requirements: data.functional_requirements.map(
          functionalRequirement => functionalRequirement.id,
        ),
        non_functional_requirements: data.non_functional_requirements.map(
          nonFunctionalRequirement => nonFunctionalRequirement.id,
        ),
        red_id: data.red.id,
        additional_reds_ids: data.additional_reds.map(rs => rs.id),
      });

      setRedData({
        red: data.red,
        additionalReds: data.additional_reds,
      });
    }
  }, [
    setRespondingData,
    setRedData,
    history,
    initResponding,
    match?.params.idSpecification,
  ]);

  useEffect(() => {
    getSpecificationById();
  }, [getSpecificationById]);

  useEffect(() => {
    switch (match?.path) {
      case Urls.SPECIFICATION.path:
      case Urls.SELECT_RED_DETAIL.path:
        setCurrent(1);
        break;

      case Urls.FUNCTIONAL_REQUIREMENT.path:
        setCurrent(2);
        break;

      case Urls.NON_FUNCTIONAL_REQUIREMENT.path:
        setCurrent(3);
        break;

      case Urls.REVIEW_SPECIFICATION.path:
      case Urls.SPECIFICATION_TREE.path:
      case Urls.FILL_IN_FINAL_DATA.path:
      case Urls.NEXT_STEPS.path:
        setCurrent(4);
        break;

      default:
        break;
    }
  }, [match]);

  return (
    <Container>
      <HeaderLayoutCustom
        options={user ? options : undefined}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      />

      <Navigation collapsed={collapsed}>
        <div>
          <Link to={Urls.SPECIFICATION.url}>
            <img src={imgLogoWhite} alt="Cieb" />
          </Link>
        </div>

        <nav>
          <ul>
            {menus.map(({ key, title }) => (
              <MenuItem
                key={key}
                isActive={current === key}
                isCheck={current > key}
              >
                {key < menus.length && <TailItem />}

                <span>{current > key ? <FaCheck /> : `0${key}`}</span>

                <div className="nav-item-title">
                  <span>{`Etapa 0${key}`}</span>
                  <strong>{title}</strong>

                  {current === 2 && current === key && (
                    <ul>
                      {selectedReds.map(item => (
                        <li key={item.id}>
                          {item.description}

                          {item.key === match?.params.keyRed && (
                            <ul>
                              {item.functionalRequirements?.requirement_categories?.map(
                                rc => (
                                  <li
                                    key={rc.id}
                                    className={
                                      rc.key === match.params.keyCategory
                                        ? 'item-active'
                                        : ''
                                    }
                                  >
                                    {rc.description}
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}

                  {current === 3 && current === key && (
                    <ul>
                      <li>
                        <ul>
                          {nonFunctional.map(item => (
                            <li
                              key={item.id}
                              className={
                                item.key === match?.params.keyCategory
                                  ? 'item-active'
                                  : ''
                              }
                            >
                              {item.description}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  )}

                  {current === 4 && current === key && (
                    <ul>
                      <li>
                        <ul>
                          <li
                            className={
                              match?.path === Urls.SPECIFICATION_TREE.path
                                ? 'item-active'
                                : ''
                            }
                          >
                            Árvore da especificação
                          </li>
                          <li
                            className={
                              match?.path === Urls.FILL_IN_FINAL_DATA.path
                                ? 'item-active'
                                : ''
                            }
                          >
                            Descrição e estimativa de quantidade
                          </li>
                          <li
                            className={
                              match?.path === Urls.REVIEW_SPECIFICATION.path
                                ? 'item-active'
                                : ''
                            }
                          >
                            Revisar especificação
                          </li>
                          <li
                            className={
                              match?.path === Urls.NEXT_STEPS.path
                                ? 'item-active'
                                : ''
                            }
                          >
                            Próximos passos
                          </li>
                        </ul>
                      </li>
                    </ul>
                  )}
                </div>
              </MenuItem>
            ))}
          </ul>
        </nav>
      </Navigation>

      <Content>{children}</Content>
    </Container>
  );
};

export default TechnicalSpecification;
