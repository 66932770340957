import styled, { css, keyframes } from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';

interface ContainerProps {
  status: 'success' | 'error' | 'info' | 'warning' | 'primary';
}

const iconColor = {
  success: css`
    color: ${props => props.theme.colors.success};
  `,
  error: css`
    color: ${props => props.theme.colors.error};
  `,
  info: css`
    color: ${props => props.theme.colors.info};
  `,
  warning: css`
    color: ${props => props.theme.colors.warning};
  `,
  primary: css`
    color: ${props => props.theme.colors.primary};
  `,
};

export const Container = styled.div<ContainerProps>`
  min-height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 700px;
    text-align: center;

    > svg {
      font-size: 60px;

      ${props => iconColor[props.status]}
    }

    strong {
      margin-top: 20px;
      font-size: 20px;
      color: ${props => props.theme.colors.secundary};
      display: block;
    }

    p {
      font-size: 16px;
    }
  }
`;

const loading = keyframes`
  100% {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  }
`;

export const Loading = styled(AiOutlineLoading)`
  animation: ${loading} 1s infinite linear;
`;
