import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface MenuProps {
  placement:
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
}

export const Container = styled.div`
  position: relative;
`;

const placementType = {
  bottomLeft: css`
    left: 0;
    top: calc(100% + 5px);
  `,
  bottomCenter: css`
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 5px);
  `,
  bottomRight: css`
    right: 0;
    top: calc(100% + 5px);
  `,
  topLeft: css`
    left: 0;
    bottom: calc(100% + 5px);
  `,
  topCenter: css`
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 5px);
  `,
  topRight: css`
    right: 0;
    bottom: calc(100% + 5px);
  `,
};

export const Menu = styled.div<MenuProps>`
  position: absolute;
  background: ${props => props.theme.colors.white};
  min-width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  overflow: hidden;
  z-index: 15;

  ${props => placementType[props.placement]}

  > div {
    max-height: 300px;
    padding: 10px 0;
    overflow: auto;
  }
`;

export const Item = styled.div`
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  display: flex;
  align-items: center;

  > svg {
    margin-right: 15px;
  }

  &:hover {
    background: ${props => tint(0.98, props.theme.colors.black)};
  }
`;
