import React, { useRef } from 'react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiMaximize,
  FiZoomIn,
  FiZoomOut,
} from 'react-icons/fi';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@react-pdf-viewer/full-screen';
import {
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
  RenderZoomProps,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@react-pdf-viewer/zoom';
import {
  pageNavigationPlugin,
  RenderGoToNextPageProps,
  RenderGoToPreviousPageProps,
  RenderCurrentPageLabelProps,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@react-pdf-viewer/page-navigation';

import { AiOutlineColumnHeight, AiOutlineColumnWidth } from 'react-icons/ai';
import Button from '../Button';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Container, Toolbar, Content } from './styles';

interface ViewPDF {
  fileUrl: string | Uint8Array;
}

interface ContainerElement extends HTMLDivElement {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
  webkitExitFullscreen?: () => void;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
}

// const defaultLayoutPluginInstance = defaultLayoutPlugin();

const ViewPDF: React.FC<ViewPDF> = ({ fileUrl }) => {
  // const [isFullScreen, setIsFullScreen] = useState(false);

  const refCotnainer = useRef<ContainerElement>(null);

  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {
    GoToNextPage,
    GoToPreviousPage,
    CurrentPageLabel,
  } = pageNavigationPluginInstance;

  // const heandleFullScreen = useCallback(() => {
  //   if (isFullScreen) {
  //     setIsFullScreen(false);

  //     if (refCotnainer.current?.ownerDocument.exitFullscreen) {
  //       refCotnainer.current?.ownerDocument.exitFullscreen();
  //     } else if (refCotnainer.current?.webkitExitFullscreen) {
  //       /* Safari */
  //       refCotnainer.current?.webkitExitFullscreen();
  //     } else if (refCotnainer.current?.msExitFullscreen) {
  //       /* IE11 */
  //       refCotnainer.current?.msExitFullscreen();
  //     }
  //   } else {
  //     setIsFullScreen(true);
  //     if (refCotnainer.current?.requestFullscreen) {
  //       refCotnainer.current?.requestFullscreen();
  //     } else if (refCotnainer.current?.webkitRequestFullscreen) {
  //       /* Safari */
  //       refCotnainer.current?.webkitRequestFullscreen();
  //     } else if (refCotnainer.current?.msRequestFullscreen) {
  //       /* IE11 */
  //       refCotnainer.current?.msRequestFullscreen();
  //     }
  //   }
  // }, [isFullScreen]);

  return (
    <Container ref={refCotnainer}>
      <Toolbar>
        <div>
          <GoToPreviousPage>
            {(props: RenderGoToPreviousPageProps) => (
              <Button
                variant="link"
                icon={FiChevronLeft}
                disabled={props.isDisabled}
                onClick={props.onClick}
              />
            )}
          </GoToPreviousPage>
          {/* <CurrentPageInput /> / */}

          <CurrentPageLabel>
            {(props: RenderCurrentPageLabelProps) => (
              <span>{`${props.currentPage + 1} / ${props.numberOfPages}`}</span>
            )}
          </CurrentPageLabel>

          <GoToNextPage>
            {(props: RenderGoToNextPageProps) => (
              <Button
                variant="link"
                icon={FiChevronRight}
                disabled={props.isDisabled}
                onClick={props.onClick}
              />
            )}
          </GoToNextPage>
        </div>

        <div>
          <div className="hidden">
            <Zoom>
              {(props: RenderZoomProps) => (
                <Button
                  variant="link"
                  icon={AiOutlineColumnHeight}
                  onClick={() => props.onZoom(SpecialZoomLevel.PageFit)}
                />
              )}
            </Zoom>
            <Zoom>
              {(props: RenderZoomProps) => (
                <Button
                  variant="link"
                  icon={AiOutlineColumnWidth}
                  onClick={() => props.onZoom(SpecialZoomLevel.PageWidth)}
                />
              )}
            </Zoom>
          </div>
          <ZoomOut>
            {(props: RenderZoomOutProps) => (
              <Button variant="link" icon={FiZoomOut} onClick={props.onClick} />
            )}
          </ZoomOut>
          {/* <CurrentScale>
            {(props: RenderCurrentScaleProps) => (
              <span>{`${Math.round(props.scale * 100)}%`}</span>
            )}
          </CurrentScale> */}
          <ZoomIn>
            {(props: RenderZoomInProps) => (
              <Button variant="link" icon={FiZoomIn} onClick={props.onClick} />
            )}
          </ZoomIn>
        </div>

        <div>
          {/* <Button variant="link"
          icon={isFullScreen ? FiMinimize : FiMaximize}
          onClick={heandleFullScreen}
          /> */}

          <EnterFullScreen>
            {(props: RenderEnterFullScreenProps) => (
              <Button
                variant="link"
                icon={FiMaximize}
                onClick={props.onClick}
              />
            )}
          </EnterFullScreen>
        </div>
      </Toolbar>

      <Content>
        <Viewer
          fileUrl={fileUrl}
          plugins={[
            fullScreenPluginInstance,
            zoomPluginInstance,
            pageNavigationPluginInstance,
          ]}
        />
      </Content>
    </Container>
  );
};

export default ViewPDF;
