import React, { createContext, useCallback, useContext, useState } from 'react';

import { useToast } from './toast';
import api from '../services/api';

export interface Requirement {
  id: number;
  key: string;
  description: string;
  full_description?: string;
  legend: string;
  selected?: boolean;
}

export interface RequirementCategory extends Requirement {
  requirement_categories?: RequirementCategory[];
  requirements?: Requirement[];
}

export interface Category {
  id: number;
  key: string;
  description: string;
  red_category?: Category;
}

export interface Relationship {
  id: number;
  description: string;
  legend: string;
}

export interface Red {
  id: number;
  key: string;
  description: string;
  definition: string;
  international_terms: string;
  main_features: string;
  examples: string;
  red_category: Category;
  red_relationships: Relationship[];
  legend?: string;
  disabled?: boolean;
  active?: boolean;
  functionalRequirements?: RequirementCategory;
}

interface RedDataSet {
  red: {
    id: number;
    requirement_categories: RequirementCategory;
  };
  additionalReds: Array<{
    id: number;
    requirement_categories: RequirementCategory;
  }>;
}

interface RedsContextData {
  reds: Red[];
  selectedReds: Red[];
  primaryRed: Red;
  getReds(): void;
  setRedData(data: RedDataSet): void;
  onSelectedRed(red: Red): void;
  onRemoveSelectRed(id: number): void;
  initReds(): void;
}

const RedsContext = createContext<RedsContextData>({} as RedsContextData);

const RedsProvider: React.FC = ({ children }) => {
  const [primaryRed, setPrimaryRed] = useState<Red>(() => {
    const primaryRedStorage = localStorage.getItem('@Taxonomia:primaryRed');

    if (primaryRedStorage) {
      return JSON.parse(primaryRedStorage);
    }

    return {} as Red;
  });

  const [reds, setReds] = useState<Red[]>(() => {
    const redsStorage = localStorage.getItem('@Taxonomia:reds');

    if (redsStorage && redsStorage.length > 0) {
      return JSON.parse(redsStorage);
    }

    return [];
  });

  const [selectedReds, setSelectedReds] = useState<Red[]>(() => {
    const selectedRedsStorage = localStorage.getItem('@Taxonomia:selectedReds');

    if (selectedRedsStorage && selectedRedsStorage.length > 0) {
      return JSON.parse(selectedRedsStorage);
    }

    return [];
  });

  const { addToast } = useToast();

  const getReds = useCallback(async () => {
    if (reds.length === 0) {
      const { data } = await api.get<Red[]>('reds');

      localStorage.setItem('@Taxonomia:reds', JSON.stringify(data));

      setReds(data);
    }
  }, [reds]);

  const onSelectedRed = useCallback(
    async (red: Red) => {
      const redExist = selectedReds.find(item => item.id === red.id);

      if (selectedReds.length === 0) {
        setPrimaryRed(red);

        localStorage.setItem('@Taxonomia:primaryRed', JSON.stringify(red));
      }

      if (!redExist) {
        const redFunctionalRequirements = red;

        const { data } = await api.get<RequirementCategory>(
          `reds/${red.id}/requirements/functional`,
        );

        redFunctionalRequirements.functionalRequirements = data;

        localStorage.setItem(
          '@Taxonomia:selectedReds',
          JSON.stringify([...selectedReds, redFunctionalRequirements]),
        );

        setSelectedReds([...selectedReds, redFunctionalRequirements]);
      }
    },
    [selectedReds],
  );

  const onRemoveSelectRed = useCallback(
    (id: number) => {
      const selectedRedsFilter = selectedReds.filter(item => item.id !== id);

      localStorage.setItem(
        '@Taxonomia:selectedReds',
        JSON.stringify(selectedRedsFilter),
      );

      setSelectedReds(selectedRedsFilter);

      addToast({ type: 'success', title: 'Red removido com sucesso!' });
    },
    [selectedReds, addToast],
  );

  const setRedData = useCallback(
    ({ red, additionalReds }: RedDataSet) => {
      const redsResponding = [red, ...additionalReds];

      const newSelectedReds: Red[] = [];

      redsResponding.forEach(redResponding => {
        const newRed = reds.find(r => r.id === redResponding.id);

        if (newRed) {
          newSelectedReds.push({
            ...newRed,
            functionalRequirements: redResponding?.requirement_categories,
          });
        }
      });

      const newPrimaryRed = newSelectedReds.find(r => r.id === red.id);

      if (newPrimaryRed) {
        localStorage.setItem(
          '@Taxonomia:primaryRed',
          JSON.stringify(newPrimaryRed),
        );
        setPrimaryRed(newPrimaryRed);
      }

      localStorage.setItem(
        '@Taxonomia:selectedReds',
        JSON.stringify(newSelectedReds),
      );

      setSelectedReds(newSelectedReds);
    },
    [reds],
  );

  const initReds = useCallback(() => {
    localStorage.removeItem('@Taxonomia:selectedReds');
    localStorage.removeItem('@Taxonomia:primaryRed');

    setSelectedReds([]);
    setPrimaryRed({} as Red);
  }, []);

  return (
    <RedsContext.Provider
      value={{
        reds,
        selectedReds,
        primaryRed,
        getReds,
        setRedData,
        onSelectedRed,
        onRemoveSelectRed,
        initReds,
      }}
    >
      {children}
    </RedsContext.Provider>
  );
};

function useReds(): RedsContextData {
  const context = useContext(RedsContext);

  if (!context) {
    throw new Error('useReds must be used within an RedsProvider');
  }

  return context;
}

export { RedsProvider, useReds };
