import React, { ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons/lib';

import { Container, Loading } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'link' | 'danger';
  block?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  block,
  icon: Icon,
  loading = false,
  disabled = false,
  children,
  ...rest
}) => (
  <Container
    variant={variant}
    block={block}
    isTitle={!!children}
    isIcon={!!Icon && !children}
    type="button"
    disabled={disabled || loading}
    {...rest}
  >
    {Icon && !loading && <Icon />}
    {loading && <Loading />}

    {children}
  </Container>
);

export default Button;
