import styled, { css } from 'styled-components';

interface ContainerProps {
  size?: 'large' | 'default' | 'small';
}

const typeVariant = {
  large: css`
    padding-bottom: 15px;
    font-size: 30px;

    &::before {
      width: 30px;
      height: 8px;
      border-radius: 4px;
    }
  `,
  default: css`
    padding-bottom: 10px;
    font-size: 22px;

    &::before {
      width: 20px;
      height: 6px;
      border-radius: 3px;
    }
  `,
  small: css`
    padding-bottom: 10px;
    font-size: 18px;

    &::before {
      width: 16px;
      height: 4px;
      border-radius: 2px;
    }
  `,
};

export const Container = styled.h1<ContainerProps>`
  position: relative;
  font-weight: 700;
  color: ${props => props.theme.colors.secundary};

  &::before {
    content: '';
    position: absolute;
    background: ${props => props.theme.colors.primary};
    left: 0;
    bottom: 0;
  }

  ${props => typeVariant[props.size || 'default']}
`;
