import styled from 'styled-components';
import { animated } from 'react-spring';
import { tint, transparentize } from 'polished';

interface ContentProps {
  widthmax: number | string;
}

export const Container = styled(animated.div)<ContentProps>`
  position: fixed;
  background: ${props => transparentize(0.4, props.theme.colors.black)};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 30px;
  overflow: auto;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 100%;
    max-width: ${props =>
      props.widthmax > 0 ? `${props.widthmax}px` : props.widthmax};
  }

  @media (max-width: 999px) {
    padding: 0 15px;
  }
`;

export const Content = styled(animated.div)`
  position: relative;
  background: ${props => props.theme.colors.white};
  width: 100%;
  min-height: 100px;
  padding: 30px;
  margin: 30px 0;
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 999px) {
    margin: 15px 0;
  }
`;

export const CloseModal = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 30px;
  top: 30px;
  border-radius: 12px;
  color: ${props => props.theme.colors.error};
  transition: background 0.2s;
  z-index: 9999;

  border: 1px solid ${props => tint(0.9, props.theme.colors.black)};

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${props => tint(0.97, props.theme.colors.black)};
  }

  svg {
    font-size: 20px;
  }
`;
