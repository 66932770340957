import styled, { keyframes } from 'styled-components';

const appearNavRigth = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 30px;
  animation: ${appearNavRigth} 1s;

  > strong {
    font-size: 18px;
    color: ${props => props.theme.colors.secundary};
    display: block;
  }

  > div {
    margin: 30px 0;
  }

  > a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;

export const ModalContent = styled.div`
  margin: 30px 0;
  text-align: center;

  svg {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    color: ${props => props.theme.colors.warning};
  }

  div {
    margin-top: 30px;

    strong {
      font-size: 18px;
      color: ${props => props.theme.colors.secundary};
    }

    p {
      margin-top: 15px;
    }
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    button:last-child {
      margin-left: 20px;
    }
  }
`;
