import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
  overflow: hidden;

  display: flex;
  justify-content: center;
`;

export const Diagram = styled.div`
  overflow: auto;
  display: flex;

  > ul {
    padding: 30px;
  }
`;
