import { RequirementCategory } from '../hooks/reds';

import { RoutesRequirementsData } from '../hooks/routesRequirements';

interface RouteStructureRequirementsData {
  url: string;
  category: RequirementCategory;
  route: RoutesRequirementsData[];
  structure: string[];
  newRoute: string;
  isNonFunctional: boolean;
}

const getRouteStructureRequirements = ({
  url,
  category,
  route,
  structure,
  newRoute,
  isNonFunctional,
}: RouteStructureRequirementsData): void => {
  const { key, description, requirement_categories, requirements } = category;

  if (
    requirements &&
    requirements.length > 0 &&
    requirement_categories &&
    requirement_categories.length > 0
  ) {
    route.push({
      path: newRoute ? `${url}${newRoute}/${key}` : `${url}${key}`,
      structure: [...structure, description],
      requirement_categories: category,
      isNonFunctional,
    });
  } else {
    if (requirements && requirements.length > 0) {
      route.push({
        path: newRoute ? `${url}${newRoute}/${key}` : `${url}${key}`,
        structure: [...structure, description],
        requirement_categories: category,
        isNonFunctional,
      });
    }

    if (requirement_categories && requirement_categories.length > 0) {
      requirement_categories.forEach(rc => {
        getRouteStructureRequirements({
          url,
          category: rc,
          route,
          structure: [...structure, description],
          newRoute: newRoute ? `${newRoute}/${key}` : key,
          isNonFunctional,
        });
      });
    }
  }
};

export default getRouteStructureRequirements;
