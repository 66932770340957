import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { LEGEND } from '../../utils/const';

import HeaderTitle from '../HeaderTitle';
import Modal from '../Modal';
import Diagram, { ItemProps } from '../Diagram';

import { Container } from './styles';

interface ModalDragramProps {
  idSpecification?: string;
  visible: boolean;
  title: string;
  description?: string;
  onClose(): void;
}

interface treeData {
  title: string;
  legend: string;
  items?: ItemProps[];
}

const ModalDiagram: React.FC<ModalDragramProps> = ({
  visible,
  idSpecification,
  title,
  description,
  onClose,
}) => {
  const [tree, setTree] = useState<treeData>();

  useEffect(() => {
    if (visible && idSpecification) {
      api
        .get(`specifications/${idSpecification}/hierarchy`)
        .then(({ data }) => {
          setTree({
            title: 'Especifição',
            legend: LEGEND.DIVISAO_SECAO,
            items: [
              {
                id: 1,
                title: 'Requisitos funcionais',
                legend: LEGEND.DIVISAO_SECAO,
                items: data.functional_requirements,
              },
              {
                id: 2,
                title: 'Requisitos não funcionais',
                legend: LEGEND.DIVISAO_SECAO,
                items: data.non_functional_requirements,
              },
            ],
          });
        });
    }
  }, [visible, idSpecification]);

  return (
    <Modal visible={visible} width="100%" onClose={onClose}>
      <HeaderTitle title={title} />
      {description && <p>{description}</p>}

      <Container>{tree && <Diagram tree={tree} isScroll isBorder />}</Container>
    </Modal>
  );
};

export default ModalDiagram;
