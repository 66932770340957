import React from 'react';

import { Container } from './styles';

interface HeaderTitleProps {
  title: String;
  size?: 'large' | 'default' | 'small';
  className?: string;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title,
  size,
  className,
}) => {
  return (
    <Container size={size} className={className}>
      {title}
    </Container>
  );
};

export default HeaderTitle;
