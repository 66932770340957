import React from 'react';

import { RequirementCategory } from '../../hooks/reds';

import Requirements from './Requirements';
import RequirementTitle from './RequirementTitle';

import { Container } from './styles';

interface DiagramProps {
  requirements: RequirementCategory | undefined;
}

const Diagram: React.FC<DiagramProps> = ({ requirements }) => {
  return (
    <Container>
      <ul>
        <li>
          <RequirementTitle
            title={requirements?.description || ''}
            type="divisao-secao"
          />

          {requirements?.requirement_categories && (
            <Requirements categories={requirements.requirement_categories} />
          )}
        </li>
      </ul>
    </Container>
  );
};

export default Diagram;
