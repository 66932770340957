import React, { useCallback, useEffect, useState } from 'react';
import {
  FiFacebook,
  FiFileText,
  FiInstagram,
  FiLogIn,
  FiMapPin,
  FiPhone,
  FiTwitter,
  FiUser,
  FiYoutube,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Red, useReds } from '../../hooks/reds';
import { useAuth } from '../../hooks/auth';

import Urls from '../../routes/urls';

import Reds from '../../components/Reds';
import ViewVideo from '../../components/ViewVideo';
import Button from '../../components/Button';
import HeaderTitle from '../../components/HeaderTitle';
import ButtonLink from '../../components/ButtonLink';
import ButtonLinkExternal from '../../components/ButtonLinkExternal';
import Dropdown from '../../components/Dropdown';

import RedModal from './RedModal';

import {
  URL_IDENTIDICACAO_RED,
  URL_NEW_SPECIFICATION,
} from '../../utils/const';

import imgLogo from '../../assets/logo.svg';
import imgLogoFooter from '../../assets/logo-footer.svg';

import {
  Container,
  Header,
  Main,
  Content,
  InfoContentVideo,
  InfoContent,
  FooterContainer,
  SocialNetwork,
  Contacts,
} from './styles';
import User from '../../components/User';

const Home: React.FC = () => {
  const [redSelected, setRedSelected] = useState<Red>({} as Red);
  const [visibleRedModal, setVisibleRedModal] = useState(false);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);

  const { getReds, reds } = useReds();
  const { user, signOut } = useAuth();

  const history = useHistory();

  const options = [
    {
      id: '1',
      title: 'Especificações',
      value: 'specifications',
      icon: FiFileText,
      action: () => history.push(Urls.SPECIFICATIONS.url),
    },
    { id: '2', title: 'Sair', value: 'close', icon: FiLogIn, action: signOut },
  ];

  const handleOnSelected = useCallback((red: Red) => {
    setRedSelected(red);
    setVisibleRedModal(true);
  }, []);

  const openUserModal = useCallback((signIn: boolean) => {
    setVisibleUserModal(true);
    setIsSignIn(signIn);
  }, []);

  useEffect(() => {
    getReds();
  }, [getReds]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <img src={imgLogo} alt="Cieb" />

            <div>
              {user ? (
                <Dropdown
                  placement="bottomRight"
                  options={options}
                  button={{ variant: 'link', icon: FiUser }}
                />
              ) : (
                <>
                  <Button variant="link" onClick={() => openUserModal(true)}>
                    Fazer login
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => openUserModal(false)}
                  >
                    Criar conta
                  </Button>
                </>
              )}
            </div>
          </div>
        </Header>

        <Main>
          <section className="full-section header-title">
            <Content>
              <div>
                <HeaderTitle
                  title="GUIA DE ESPECIFICAÇÃO DE RECURSOS EDUCACIONAIS DIGITAIS"
                  size="large"
                />
                <p>
                  Entenda o que são recursos educacionais digitais, como são
                  classificados e qual a melhor forma de descrevê-los
                  tecnicamente para atender às demandas de sua rede de ensino.
                </p>
              </div>
            </Content>
          </section>

          <section>
            <InfoContentVideo>
              <div>
                <HeaderTitle
                  title="O que são Recursos Educacionais Digitais (REDs)"
                  size="large"
                />
                <p>
                  Recursos educacionais digitais, ou REDs, são produtos e
                  serviços que apoiam tanto os processos de ensino e
                  aprendizagem como a gestão pedagógica e
                  administrativa-financeira das escolas. De uso abrangente, eles
                  facilitam as atividades de docentes, estudantes e gestores(as)
                  e são disponibilizados com todos os recursos necessários para
                  a sua execução, sem dependência externa. Em termos técnicos,
                  são replicáveis e autocontidos.
                </p>
              </div>

              {/* <div>
                <ViewVideo src="https://www.youtube.com/embed/c6EMNj1SQ3A" />
              </div> */}
            </InfoContentVideo>
          </section>

          <section className="reds-section">
            <Reds reds={reds} onSelected={handleOnSelected} />
          </section>

          {/* <section className="full-section">
            <InfoContent>
              <div>
                <h1>
                  Você ainda não sabe quais são os recursos digitais mais
                  apropriados para os desafios de sua rede de ensino? Então
                  acesse a ferramenta Identificação de Recursos Educacionais
                  Digitais
                </h1>
                <ButtonLinkExternal
                  variant="primary"
                  href={URL_IDENTIDICACAO_RED}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Acesse a ferramenta de identificação de REDs
                </ButtonLinkExternal>
              </div>
            </InfoContent>
          </section> */}

          <section>
            <InfoContentVideo>
              {/*
              * Como ainda não existe vídeo disponível, comentamos essa parte.
              * Ao descomentar, deve alterar width: calc(100% - 25px) para
              * width: calc(50% - 25px) no styled component InfoContentVideo.
              <div>
                <ViewVideo src="https://www.youtube.com/embed/dhoG-305Acw" />
              </div>
              */}

              <div>
                <HeaderTitle title="Especificação Técnica" size="large" />
                <p>
                  Agora que você já sabe quais recursos digitais quer utilizar
                  em sua rede de ensino, gere um relatório com as especificações
                  técnicas para dar encaminhamento aos processos de compras
                  públicas. Para consultar e fazer o download do documento,
                  basta preencher algumas informações.
                </p>

                <ButtonLink
                  to={URL_NEW_SPECIFICATION + Urls.SPECIFICATION.url}
                  variant="primary"
                >
                  Clique aqui para acessar a ferramenta
                </ButtonLink>
              </div>
            </InfoContentVideo>
          </section>
        </Main>

        <FooterContainer>
          <div>
            <div>
              <img src={imgLogoFooter} alt="Cieb" />

              <Contacts>
                <div>
                  <FiMapPin />
                  <span>
                    RUA LABORIOSA, 37
                    <span>CEP 05434-060 - SÃO PAULO-SP</span>
                  </span>
                </div>
                <div>
                  <FiPhone />
                  <span>(11) 3031-7899</span>
                </div>
              </Contacts>

              <SocialNetwork>
                <a href="instagram" target="_blank" rel="noopener noreferrer">
                  <FiInstagram />
                </a>
                <a href="facebook" target="_blank" rel="noopener noreferrer">
                  <FiFacebook />
                </a>
                <a href="twitter" target="_blank" rel="noopener noreferrer">
                  <FiTwitter />
                </a>
                <a href="youtube" target="_blank" rel="noopener noreferrer">
                  <FiYoutube />
                </a>
              </SocialNetwork>
            </div>

            <strong>CC BY-NC-SA 4.0</strong>
            <p>
              O conteúdo publicado no site do CIEB está licenciado com a Licença
              Creative Commons - Atribuição-Não Comercial-Compartilha Igual 4.0
              Internacional, a menos que condições e/ou restrições adicionais
              específicas estejam claramente explícitas na página
              correspondente.
            </p>
          </div>
        </FooterContainer>

        <RedModal
          visible={visibleRedModal}
          red={redSelected}
          onclose={() => setVisibleRedModal(false)}
        />
      </Container>

      <User
        visible={visibleUserModal}
        isSignIn={isSignIn}
        setIsSignIn={setIsSignIn}
        onClose={() => setVisibleUserModal(false)}
      />
    </>
  );
};

export default Home;
