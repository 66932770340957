import React, { AnchorHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonLinkExternalProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: 'default' | 'primary';
  block?: boolean;
}

const ButtonLinkExternal: React.FC<ButtonLinkExternalProps> = ({
  variant,
  block,
  children,
  ...rest
}) => (
  <Container variant={variant} block={block} {...rest}>
    {children}
  </Container>
);

export default ButtonLinkExternal;
