import React from 'react';
import { FiMenu, FiUser, FiX } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';

import Dropdown from '../Dropdown';

import imgLogo from '../../assets/logo-white.svg';

import { Container } from './styles';
import Button from '../Button';

interface HeaderLayoutProps {
  collapsed?: boolean;
  onCollapse?(): void;
  options?: Array<{
    id: string;
    title: string;
    value: string;
    icon?: React.ComponentType<IconBaseProps>;
    action?: () => void;
  }>;
  className?: string;
}

const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  collapsed = false,
  onCollapse,
  options,
  className,
}) => {
  return (
    <Container className={className}>
      <div>
        {onCollapse && (
          <Button
            variant="link"
            icon={collapsed ? FiX : FiMenu}
            onClick={onCollapse}
          />
        )}

        <div>
          <img src={imgLogo} alt="Cieb" />

          {options && (
            <Dropdown
              placement="bottomRight"
              options={options}
              button={{ variant: 'link', icon: FiUser }}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default HeaderLayout;
