import styled, { css } from 'styled-components';
import { tint } from 'polished';

import { LEGEND } from '../../../utils/const';

interface RequirementProps {
  type?: string;
  isFull: boolean;
}

const requirementTitleTypeVariations = (type: string, isFull: boolean) => {
  switch (type) {
    case LEGEND.OPCIONAL:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.green : props.theme.colors.white};
        border-color: ${props => props.theme.colors.green};
      `;

    case LEGEND.AO_MENOS_UM:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.yellow : props.theme.colors.white};
        border-color: ${props => props.theme.colors.yellow};
      `;

    case LEGEND.DIVISAO_SECAO:
      return css`
        background: ${props => tint(0.95, props.theme.colors.black)};
        border-color: ${props => tint(0.9, props.theme.colors.black)};
        color: ${props => props.theme.colors.black};
      `;

    case LEGEND.UM_E_APENAS_UM:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.indigo : props.theme.colors.white};
        border-color: ${props => props.theme.colors.indigo};
      `;

    case LEGEND.OBRIGATORIO:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.error : props.theme.colors.white};
        border-color: ${props => props.theme.colors.error};
      `;

    default:
      return css`
        background: ${props => props.theme.colors.white};
        border-color: ${props => tint(0.9, props.theme.colors.black)};
        color: ${props => props.theme.colors.black};
      `;
  }
};

export const Container = styled.div<RequirementProps>`
  padding: 8px 0;

  > span {
    padding: 8px 0;
    width: 100%;
    min-height: 40px;
    padding: 10px 15px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    color: ${props =>
      props.isFull ? props.theme.colors.white : props.theme.colors.black};

    ${props => requirementTitleTypeVariations(props.type || '', props.isFull)};

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
