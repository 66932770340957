import styled, { css } from 'styled-components';

interface ContainerProps {
  span?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xxl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  flexcol?: number | 'none' | 'auto' | string;
}

const colNumber = {
  1: css`
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  `,
  2: css`
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  `,
  3: css`
    flex: 0 0 25%;
    max-width: 25%;
  `,
  4: css`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `,
  5: css`
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `,
  6: css`
    flex: 0 0 50%;
    max-width: 50%;
  `,
  7: css`
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `,
  8: css`
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  `,
  9: css`
    flex: 0 0 75%;
    max-width: 75%;
  `,
  10: css`
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  `,
  11: css`
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  `,
  12: css`
    flex: 0 0 100%;
    max-width: 100%;
  `,
};

export const Container = styled.div<ContainerProps>`
  ${props => colNumber[props.span || 12]};

  ${props =>
    typeof props.flexcol === 'string' &&
    css`
      flex: ${props.flexcol};
    `}

  ${props =>
    typeof props.flexcol === 'number' &&
    css`
      flex: ${props.flexcol} ${props.flexcol} auto;
    `}

  @media (min-width: ${props => props.theme.screen.xs}px) {
    ${props => props.xs && colNumber[props.xs]};
  }

  @media (min-width: ${props => props.theme.screen.sm}px) {
    ${props => props.sm && colNumber[props.sm]};
  }

  @media (min-width: ${props => props.theme.screen.md}px) {
    ${props => props.md && colNumber[props.md]};
  }

  @media (min-width: ${props => props.theme.screen.lg}px) {
    ${props => props.lg && colNumber[props.lg]};
  }

  @media (min-width: ${props => props.theme.screen.xl}px) {
    ${props => props.xl && colNumber[props.xl]};
  }

  @media (min-width: ${props => props.theme.screen.xxl}px) {
    ${props => props.xxl && colNumber[props.xxl]};
  }
`;
