import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../services/api';

import { RequirementCategory } from './reds';

interface NonFunctionalContextData {
  nonFunctional: RequirementCategory[];
  getNonFunctional(): Promise<RequirementCategory[]>;
  initNonFunctional(): void;
}

const NonFunctionalContext = createContext<NonFunctionalContextData>(
  {} as NonFunctionalContextData,
);

const NonFunctionalProvider: React.FC = ({ children }) => {
  const [nonFunctional, setNonFunctional] = useState<RequirementCategory[]>(
    () => {
      const nonFunctionalStorage = localStorage.getItem(
        '@Taxonomia:nonFunctional',
      );

      if (nonFunctionalStorage && nonFunctionalStorage.length > 0) {
        return JSON.parse(nonFunctionalStorage);
      }

      return [];
    },
  );

  const getNonFunctional = useCallback(async (): Promise<
    RequirementCategory[]
  > => {
    if (nonFunctional.length === 0) {
      const { data } = await api.get<RequirementCategory[]>(
        '/requirements/nonFunctional',
      );

      localStorage.setItem('@Taxonomia:nonFunctional', JSON.stringify(data));

      setNonFunctional(data);

      return data;
    }

    return nonFunctional;
  }, [nonFunctional]);

  const initNonFunctional = useCallback(() => {
    setNonFunctional([]);
    localStorage.removeItem('@Taxonomia:nonFunctional');
  }, []);

  return (
    <NonFunctionalContext.Provider
      value={{
        nonFunctional,
        getNonFunctional,
        initNonFunctional,
      }}
    >
      {children}
    </NonFunctionalContext.Provider>
  );
};

function useNonFunctional(): NonFunctionalContextData {
  const context = useContext(NonFunctionalContext);

  if (!context) {
    throw new Error(
      'useNonFunctional must be used within an NonFunctionalProvider',
    );
  }

  return context;
}

export { NonFunctionalProvider, useNonFunctional };
