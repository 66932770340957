import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRoutesRequirements } from './routesRequirements';

interface StepperData {
  current: number;
  getCurrentIndex(): number;
  prevRoute(): void;
  nextRoute(): void;
}

const useStepper = (): StepperData => {
  const [current, setCurrent] = useState(-1);

  const { routesRequirement } = useRoutesRequirements();
  const history = useHistory();

  const getCurrentIndex = useCallback(
    () =>
      routesRequirement.findIndex(({ path }) => {
        return path === history.location.pathname;
      }),
    [history.location.pathname, routesRequirement],
  );

  const nextRoute = useCallback(() => {
    const index = getCurrentIndex();

    if (index === routesRequirement.length - 1) {
      return;
    }

    setCurrent(index + 1);
  }, [getCurrentIndex, routesRequirement]);

  const prevRoute = useCallback(() => {
    const index = getCurrentIndex();

    if (index === 0) {
      return;
    }

    setCurrent(index - 1);
  }, [getCurrentIndex]);

  useEffect(() => {
    if (current === -1) {
      return;
    }

    const { path } = routesRequirement[current];

    if (path === history.location.pathname) {
      return;
    }

    history.push({
      pathname: path,
      state: { previousPath: history.location.pathname },
    });
  }, [current, routesRequirement, history]);

  useEffect(() => {
    setCurrent(getCurrentIndex);
  }, [getCurrentIndex]);

  return {
    current,
    prevRoute,
    nextRoute,
    getCurrentIndex,
  };
};

export default useStepper;
