import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiTrash2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useRoutesRequirements } from '../../hooks/routesRequirements';
import { useResponding } from '../../hooks/responding';
import { useCalculateProdess } from '../../hooks/CalculateProdess';
import { useReds } from '../../hooks/reds';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';
import Urls from '../../routes/urls';
import { getExampleWhatIs } from '../../utils/const';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';

import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import HeaderTitle from '../../components/HeaderTitle';
import Input from '../../components/Input';
import RichTextEditor from '../../components/RichTextEditor';
import Button from '../../components/Button';
import Row from '../../components/Row';
import Col from '../../components/Col';

import {
  Container,
  ModelSpecification,
  ExempleWhatIs,
  TableCustom,
  ColTitleItem,
  RowTitle,
  RowTitleCol,
} from './styles';

interface Amount {
  description: string;
  estimated_quantity: number;
  unit_of_measurement: string;
}

interface FillInFinalFormData {
  email: string;
  what_is: string;
  what_you_want_acquire: string;
  quantities: Amount[];
}

const FillInFinalData: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [quantities, setQuantities] = useState<number[]>([0]);
  const formRef = useRef<FormHandles>(null);

  const { routesRequirement } = useRoutesRequirements();
  const { responding, updateResponding } = useResponding();
  const { primaryRed } = useReds();
  const { generateProgress, progress } = useCalculateProdess();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (values: FillInFinalFormData) => {
      try {
        setLoading(true);

        const data = values;

        formRef.current?.setErrors({});

        data.quantities = data.quantities.filter(
          q => q.description || q.estimated_quantity || q.unit_of_measurement,
        );

        const schema = Yup.object().shape({
          what_is: Yup.string().required('Campo obrigatório'),
          quantities: Yup.array().of(
            Yup.object().shape({
              description: Yup.string().required('Campo obrigatório'),
              estimated_quantity: Yup.number()
                .typeError('Campo requerido, apenas números')
                .required('Campo obrigatório'),
              unit_of_measurement: Yup.string().required('Campo obrigatório'),
            }),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await updateResponding({
          responding: { ...responding, ...data },
          progress,
          isUpdade: true,
        });

        history.push(
          getIdSpecificationRoute(Urls.REVIEW_SPECIFICATION.url, responding.id),
        );

        setLoading(false);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'warning',
            title: 'Verifique todos os campos',
            description: 'Tente novamente.',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Error!',
          description: 'Ocorreu um erro ao salvar, tente novamente.',
        });
      }
    },
    [updateResponding, history, addToast, responding, progress],
  );

  const removeQuantities = useCallback(
    (index: number) => {
      if (quantities.length === 1) {
        return;
      }

      const values = [...quantities];
      values.splice(index, 1);

      setQuantities(values);
    },
    [quantities],
  );

  const addQuantities = useCallback(() => {
    setQuantities(state => [...state, state[state.length - 1] + 1]);
  }, []);

  useEffect(() => {
    let whatIs = '';

    if (primaryRed) {
      whatIs = getExampleWhatIs(
        primaryRed.description,
        primaryRed.definition.toLowerCase(),
      );
    }

    formRef.current?.setData({
      what_is: responding.what_is || whatIs,
      quantities: responding.quantities,
    });
  }, [responding, primaryRed]);

  useEffect(() => {
    if (responding?.finished) {
      history.replace(getIdSpecificationRoute(Urls.SPECIFICATION.url));
    }
  }, [history, responding?.finished]);

  useEffect(() => {
    if (routesRequirement.length > 0) {
      generateProgress(routesRequirement.length + 1);
    }
  }, [generateProgress, routesRequirement]);

  return (
    <ContentTechnicalSpecification
      loading={loading}
      title="Exportar Especificação"
      prev={{
        name: 'Voltar',
        icon: FiChevronUp,
        disabled: loading,
        onAction: () =>
          history.push(
            getIdSpecificationRoute(Urls.SPECIFICATION_TREE.url, responding.id),
          ),
      }}
      next={{
        name: 'Avançar',
        icon: FiChevronDown,
        loading,
        onAction: () => formRef.current?.submitForm(),
      }}
      done={{
        name: 'Salvar',
        variant: 'primary',
        isSave: true,
        onAction: () => formRef.current?.submitForm(),
      }}
    >
      <Container>
        <HeaderTitle title="Peencher dados finais" />
        <p>
          Aqui você pode customizar a descrição do RED selecionado e indicar, se
          possuir, a estimativa de quantidade a ser adquirida.
        </p>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModelSpecification>
            <TableCustom>
              <RowTitle>
                <Col>Definição da solução</Col>
              </RowTitle>
              <Row>
                <Col>
                  <RichTextEditor name="what_is" />
                </Col>
              </Row>
            </TableCustom>

            <ExempleWhatIs>
              <div>
                Na definição da solução você deve descrever, de forma sucinta,
                precisa e clara, o RED que deseja contratar. Para isso, além de
                descrever o texto de definição da solução, é recomendável
                identificar para que se pretende adquiri-la. Em outras palavras,
                deve-se relatar a demanda/problema/desafio educacional que se
                pretende superar com a aquisição do RED, trazendo, sempre que
                possível, evidências, dados e resultados que justifiquem sua
                relevância.
              </div>
              <div>
                <strong>Como:</strong>
                <ol>
                  <li>
                    Descrever o que se pretende adquirir: descrever o objeto,
                    seja ele fornecimento de bem ou prestação de serviço;
                  </li>
                  <li>
                    Identificar para que se pretende adquirir a solução:
                    descrever a demanda/problema/desafio estabelecida no plano
                    de I&amp;T (p.ex. evasão ensino médio), trazendo evidências
                    (dados, resultados) que justifiquem sua relevância;
                  </li>
                  <li>
                    Escrever o texto de definição do objeto: elaborar texto de
                    definição da solução que integrará o estudo técnico
                    preliminar.
                  </li>
                </ol>
              </div>
            </ExempleWhatIs>
            <br />
            <br />
            <TableCustom>
              <RowTitle>
                <Col>Definição da quantidade</Col>
              </RowTitle>

              <RowTitleCol>
                <ColTitleItem lg={1}>Item</ColTitleItem>
                <ColTitleItem flex="1">Descrição</ColTitleItem>
                <ColTitleItem lg={2}>Unidade de medida</ColTitleItem>
                <ColTitleItem lg={2}>Quantidade estimada</ColTitleItem>
                <Col flex="0 0 70px" />
              </RowTitleCol>

              {quantities.map((item, index) => (
                <Scope key={item} path={`quantities[${item}]`}>
                  <Row>
                    <ColTitleItem lg={1}>
                      <span>Item</span>
                      {`${index + 1}.`}
                    </ColTitleItem>
                    <Col flex="1">
                      <Input name="description" placeholder="Descrição" />
                    </Col>
                    <Col lg={2}>
                      <Input
                        name="unit_of_measurement"
                        placeholder="Unidade de medida"
                      />
                    </Col>
                    <Col lg={2}>
                      <Input
                        name="estimated_quantity"
                        placeholder="Quantidade estimada"
                      />
                    </Col>
                    <Col flex="0 0 70px">
                      <Button
                        variant="danger"
                        icon={FiTrash2}
                        disabled={quantities.length === 1}
                        onClick={() => removeQuantities(index)}
                      />
                    </Col>
                  </Row>
                </Scope>
              ))}
            </TableCustom>

            <Button variant="primary" onClick={addQuantities}>
              Adicionar
            </Button>
          </ModelSpecification>
        </Form>
        <br />
        <p>
          Após preencher os dados acima, aperte em Avançar e um documento será
          gerado com o registro de todas as escolhas de especificação do RED
          selecionado. Você poderá revisá-lo para decidir se irá fazer o
          download final da especificação ou retornar à navegação para realizar
          ajustes na especificação.
        </p>
      </Container>
    </ContentTechnicalSpecification>
  );
};

export default FillInFinalData;
