import React from 'react';
import { LinkProps } from 'react-router-dom';

import { Container } from './styles';

interface ButtonLinkProps extends LinkProps {
  variant?: 'default' | 'primary';
  block?: boolean;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  variant,
  block,
  children,
  ...rest
}) => (
  <Container variant={variant} block={Number(!block)} {...rest}>
    {children}
  </Container>
);

export default ButtonLink;
