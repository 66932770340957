import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  ul {
    div {
      padding: 8px 0;

      &.title-diagram {
        display: flex;
        justify-content: center;

        span {
          width: auto;
        }
      }
    }
  }
`;

export const Requirement = styled.ul`
  display: flex;
  position: relative;

  &.child-requirement {
    flex-direction: column;

    > li {
      width: 100%;
      margin-left: 0;
    }
  }

  > li {
    position: relative;
    display: inline-block;
    /* width: 25%; */
    max-width: 250px;
    z-index: 1;

    div {
      position: relative;
      z-index: 2;
    }

    + li {
      margin-left: 30px;
    }

    > ul {
      position: relative;
      padding-left: 20px;
      z-index: 1;

      li {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: #e8e8e8;
          width: 30px;
          height: 1px;
          left: -20px;
          top: 25px;
        }

        &::after {
          content: '';
          position: absolute;
          background: #e8e8e8;
          width: 1px;
          height: calc(100% + 40px);
          left: -20px;
          bottom: 0;
        }

        &:last-child::after {
          bottom: calc(100% - 25px);
          height: 40px;
        }
      }
    }
  }
`;
