import { tint } from 'polished';
import styled, { css } from 'styled-components';

interface ButtonExpandProps {
  isExpanded: boolean;
}

export const Container = styled.div`
  background: ${props => props.theme.colors.white};
  /* padding: 15px; */
  border: 1px solid ${props => tint(0.95, props.theme.colors.black)};
  border-radius: 12px;

  /* & + div { */
  margin-top: 10px;
  /* } */

  section {
    padding-left: 66px;
    overflow: hidden;

    p {
      padding-right: 15px;
      padding-bottom: 15px;
    }
  }
`;

export const HeadearCollapse = styled.div`
  padding-right: 15px;
  display: flex;
  align-items: center;
`;

export const TitleCollapse = styled.div`
  flex: 1;
  padding: 15px;
  margin-right: 15px;

  display: flex;
  align-items: center;

  strong {
    margin-left: 15px;
    transition: color 0.2s;
  }

  &:hover {
    cursor: pointer;

    strong {
      color: ${props => props.theme.colors.primary};
    }

    > div > div > div {
      display: block;
    }
  }
`;

export const PressTheLetter = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 36px;
  height: 36px;

  display: flex;

  > div {
    position: absolute;
    right: 0;
    top: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.press-the-letter-active {
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }

    > div {
      padding-left: 8px;
      font-size: 12px;
      display: none;
    }

    > span {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      font-size: 20px;
      text-transform: uppercase;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ButtonExpand = styled.button<ButtonExpandProps>`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  transition: background 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 20px;
    color: ${props => props.theme.colors.primary};
    transition: transform 0.2s;
  }

  &:hover {
    background: ${props => tint(0.98, props.theme.colors.black)};
  }

  ${props =>
    props.isExpanded &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

// export const Container = styled.div`
//   background: ${props => props.theme.colors.white};
//   padding: 15px;
//   border: 1px solid ${props => tint(0.95, props.theme.colors.black)};
//   border-radius: 12px;
//   transition: background 0.2s;

//   display: flex;

//   &:hover {
//     background: ${props => tint(0.9, props.theme.colors.primary)};
//     cursor: pointer;

//     > div {
//       &.checkbox-requirements {
//         > div > div {
//           display: block;
//         }
//       }
//     }

//     strong {
//       color: ${props => props.theme.colors.primary};
//     }
//   }

//   > div {
//     &.checkbox-requirements {
//       flex-shrink: 0;
//       position: relative;
//       width: 36px;
//       height: 36px;

//       display: flex;

//       > div {
//         position: absolute;
//         background: ${props => tint(0.95, props.theme.colors.black)};
//         right: 0;
//         top: 0;
//         border: 1px solid ${props => tint(0.9, props.theme.colors.black)};
//         border-radius: 8px;

//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         > div {
//           padding-left: 8px;
//           font-size: 12px;
//           display: none;
//         }

//         > span {
//           flex-shrink: 0;
//           width: 36px;
//           height: 36px;
//           font-size: 20px;

//           display: flex;
//           justify-content: center;
//           align-items: center;
//         }
//       }
//     }

//     + div {
//       margin-left: 15px;

//       .content-requirements {
//         flex: 1;
//         overflow: hidden;
//       }
//     }

//     strong {
//       display: block;
//       margin-top: 5px;
//       font-size: 18px;
//       transition: color 0.2s;
//     }

//     p {
//       margin-top: 10px;
//     }
//   }
// `;
