import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons/lib';

import Button, { ButtonProps } from '../Button';

import { Container, Menu, Item } from './styles';

interface Button extends ButtonProps {
  title?: string;
}

interface Option {
  id: string;
  title: string;
  value: string;
  icon?: React.ComponentType<IconBaseProps>;
  action?: () => void;
}

interface DropdownProps {
  className?: string;
  button?: Button;
  options: Option[];
  placement?:
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
  onChangeValue?: (value: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  className,
  button,
  options,
  placement = 'bottomLeft',
  onChangeValue,
}) => {
  const [visible, setVisible] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleOnVisible = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (containerRef.current && !containerRef.current.contains(target)) {
      setVisible(false);
    }
  }, []);

  const handleOnChange = useCallback(
    (value: string, action?: () => void) => {
      onChangeValue && onChangeValue(value);
      action && action();
    },
    [onChangeValue],
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleOnVisible);
    }

    return () => {
      document.removeEventListener('mousedown', handleOnVisible);
    };
  }, [visible, handleOnVisible]);

  return (
    <Container ref={containerRef} className={className}>
      <Button {...button} onClick={() => setVisible(!visible)}>
        {button?.title}
      </Button>

      {visible && (
        <Menu placement={placement}>
          <div>
            {options.map(({ id, title, value, icon: Icon, action }) => (
              <Item key={id} onClick={() => handleOnChange(value, action)}>
                {Icon && <Icon />}
                {title}
              </Item>
            ))}
          </div>
        </Menu>
      )}
    </Container>
  );
};

export default Dropdown;
