import React, { useCallback, useEffect, useState } from 'react';

import Modal from '../Modal';
import SignIn from './SignIn';
import SignUp from './SignUp';

interface UserProps {
  visible: boolean;
  isSignIn?: boolean;
  info?: string;
  setIsSignIn(isSignIn: boolean): void;
  onClose(): void;
  onFinished?(): void;
}

const User: React.FC<UserProps> = ({
  visible,
  isSignIn = true,
  setIsSignIn,
  info,
  onClose,
  onFinished,
}) => {
  return (
    <Modal visible={visible} width={isSignIn ? 500 : 700} onClose={onClose}>
      {isSignIn ? (
        <SignIn
          description={info}
          onSignUp={() => setIsSignIn(false)}
          onClose={onClose}
          onFinished={onFinished}
        />
      ) : (
        <SignUp
          description={info}
          onSignIn={() => setIsSignIn(true)}
          onClose={onClose}
          onFinished={onFinished}
        />
      )}
    </Modal>
  );
};

export default User;
