import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  isErrorred: boolean;
}

export const Container = styled.div<ContainerProps>`
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + label {
      span {
        border-color: ${props => props.theme.colors.primary};

        svg {
          display: block;
          color: ${props => props.theme.colors.primary};
        }
      }
    }
  }

  label {
    margin-top: 8px;
    cursor: pointer;

    display: inline-flex;

    > span {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 2px solid ${props => tint(0.9, props.theme.colors.black)};
      border-radius: 4px;

      ${props =>
        props.isErrorred &&
        css`
          border-color: ${props.theme.colors.error};
        `}

      svg {
        display: none;
      }
    }
  }
`;

export const Error = styled.div`
  margin-top: 5px;
  color: ${props => props.theme.colors.error};
`;
