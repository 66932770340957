import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './hooks/intex';

import ligth from './styles/theme/light';
import GlobalStyles from './styles/global';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js">
      <ThemeProvider theme={ligth}>
        <BrowserRouter>
          <AppProvider>
            <Routes />
          </AppProvider>
        </BrowserRouter>
        <GlobalStyles />
      </ThemeProvider>
    </Worker>
  );
};

export default App;
