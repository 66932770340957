import { Category } from '../hooks/reds';

const getCategoryStructure = (
  redCategory: Category,
  lista: Array<string>,
): void => {
  if (redCategory.red_category?.description) {
    getCategoryStructure(redCategory.red_category, lista);
  }

  lista.push(redCategory.description);
};

export default getCategoryStructure;
