import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiChevronDown, FiChevronUp, FiHelpCircle } from 'react-icons/fi';
import { AxiosResponse } from 'axios';

import Urls from '../../routes/urls';

import { Red, Relationship, useReds } from '../../hooks/reds';
import { useRoutesRequirements } from '../../hooks/routesRequirements';
import { useResponding } from '../../hooks/responding';
import { useCalculateProdess } from '../../hooks/CalculateProdess';
import { useToast } from '../../hooks/toast';

import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import Reds from '../../components/Reds';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Alert from '../../components/Alert';

import isEmptyObject from '../../utils/isEmptyObject';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';

import { Container, ModalContent } from './styles';

interface ErrorRed {
  key: number;
  title: string;
}

const ListReds: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [visibleModalRemoveRed, setVisibleModalRemoveRed] = useState(false);
  const [redList, setRedList] = useState<Red[]>([]);
  const [isRelationships, setIsRelationships] = useState(false);
  const [errorsReds, setErrorsReds] = useState<ErrorRed[]>([]);

  const {
    getReds,
    reds,
    selectedReds,
    primaryRed,
    onRemoveSelectRed,
  } = useReds();

  const {
    generateRouteRequirements,
    initRoutesRequirements,
  } = useRoutesRequirements();

  const {
    responding,
    createResponding,
    updateResponding,
    initResponding,
  } = useResponding();

  const { generateProgress, progress } = useCalculateProdess();

  const { addToast } = useToast();

  const history = useHistory();

  const handleOnSelected = useCallback(
    (red: Red) => {
      if (red.active) {
        if (red.id === primaryRed.id) {
          setVisibleModalRemoveRed(true);
        } else {
          updateResponding({
            responding: {
              ...responding,
              non_functional_requirements: [],
              functional_requirements: [],
            },
            progress: 0,
            isUpdade: false,
          });
          initRoutesRequirements();
          onRemoveSelectRed(red.id);
        }
      } else {
        history.push(
          getIdSpecificationRoute(
            `${Urls.SELECT_RED_DETAIL.url}${red.key}`,
            responding.id,
          ),
        );
      }
    },
    [
      history,
      onRemoveSelectRed,
      updateResponding,
      initRoutesRequirements,
      primaryRed,
      responding,
    ],
  );

  const handleToClean = useCallback(() => {
    initResponding();
    setVisibleModalRemoveRed(false);
  }, [initResponding]);

  const handleNext = useCallback(async () => {
    setLoading(true);

    try {
      let newResponding = null;

      if (isEmptyObject(responding)) {
        newResponding = await createResponding(progress);
      } else {
        newResponding = await updateResponding({
          responding: {
            ...responding,
            validate_requirement_category_id: undefined,
          },
          progress,
          isUpdade: true,
        });
      }

      const currentRoute = await generateRouteRequirements(
        getIdSpecificationRoute('', newResponding.id),
      );

      if (currentRoute) {
        history.push(currentRoute);
      }

      setLoading(false);
    } catch (err) {
      if (err.isAxiosError) {
        const response = err.response as AxiosResponse;

        if (response.status === 422 && response.data?.errors) {
          const errors = response.data.errors.map(
            (error: string, index: number) => ({
              key: index,
              title: error,
            }),
          );
          setErrorsReds(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Error!',
            description: 'Ocorreu um erro ao salvar, tente novamente.',
          });
        }
      }

      setLoading(false);
    }
  }, [
    generateRouteRequirements,
    history,
    createResponding,
    responding,
    updateResponding,
    progress,
  ]);

  useEffect(() => {
    getReds();
  }, [getReds]);

  useEffect(() => {
    if (selectedReds.length > 0) {
      generateProgress(1);
    } else {
      generateProgress(0);
    }
  }, [selectedReds, generateProgress]);

  useEffect(() => {
    if (selectedReds.length > 0) {
      const relationships: Relationship[] = [];

      selectedReds.forEach(red => {
        relationships.push(...red.red_relationships);
      });

      const treatedReds = reds.map(red => {
        const relationship = relationships.find(item => item.id === red.id);
        const isRedSelect = selectedReds.find(item => item.id === red.id);

        return {
          ...red,
          disabled: !relationship && !isRedSelect,
          legend: relationship?.legend,
          active: !!isRedSelect,
        };
      });

      setIsRelationships(relationships.length > 0);
      setRedList(treatedReds);
    } else {
      setRedList(reds);
    }
  }, [selectedReds, reds]);

  const title = useMemo(() => {
    return isRelationships
      ? 'Esse RED permite adicionar outros REDs'
      : 'Selecione seu RED';
  }, [isRelationships]);

  return (
    <>
      <ContentTechnicalSpecification
        loading={loading}
        title={title}
        prev={{
          name: 'Voltar',
          icon: FiChevronUp,
          disabled: loading,
          onAction: () => history.push(Urls.HOME.path),
        }}
        next={
          selectedReds.length > 0
            ? {
                name: 'Avançar',
                icon: FiChevronDown,
                loading,
                onAction: handleNext,
              }
            : null
        }
        done={
          selectedReds.length > 0 && responding?.id
            ? {
                name: 'Salvar',
                variant: 'primary',
                isSave: true,
                onAction: handleNext,
              }
            : null
        }
      >
        <Container>
          <strong>
            Clique em uma das opções de recursos educacionais digitais abaixo
            para realizar sua especificação de forma guiada, selecionando as
            características e requisitos técnicos necessários.
          </strong>

          {errorsReds.length > 0 && (
            <Alert
              variant="warning"
              title="Não foi possível avançar."
              description="Verifique a legenda dos reds e tente novamente."
              items={errorsReds}
            />
          )}

          <div>
            <Reds reds={redList} onSelected={handleOnSelected} />
          </div>

          <strong>Quer fazer a especificação offline?</strong>
          <a
            href="https://toolkit.plataformaedutec.com.br/files/apresentacao-grupos-toolkit.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Baixe aqui um modelo de especificação técnica
          </a>
        </Container>
      </ContentTechnicalSpecification>

      <Modal
        visible={visibleModalRemoveRed}
        onClose={() => setVisibleModalRemoveRed(false)}
      >
        <ModalContent>
          <FiHelpCircle />
          <div>
            <strong>Você tem certeza que deseja remover o RED primário?</strong>
            <p>
              Ao remover o RED primário altomaticamente a especificação será
              reiniciada.
            </p>
          </div>

          <footer>
            <Button onClick={() => setVisibleModalRemoveRed(false)}>Não</Button>
            <Button variant="primary" onClick={handleToClean}>
              Sim
            </Button>
          </footer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ListReds;
