const Urls = {
  HOME: {
    path: '/',
    url: '/',
  },
  SPECIFICATIONS: {
    path: '/especificacoes',
    url: '/especificacoes',
  },
  SPECIFICATION: {
    path: '/:idSpecification/especificacao',
    url: '/especificacao',
  },
  SELECT_RED_DETAIL: {
    path: '/:idSpecification/especificacao/detalhe/:key',
    url: '/especificacao/detalhe/',
  },
  FUNCTIONAL_REQUIREMENT: {
    path:
      '/:idSpecification/especificacao/requisito-funcional/:keyRed/:keyCategory',
    url: '/especificacao/requisito-funcional/',
  },
  NON_FUNCTIONAL_REQUIREMENT: {
    path:
      '/:idSpecification/especificacao/requisito-nao-funcional/:keyCategory',
    url: '/especificacao/requisito-nao-funcional/',
  },
  SPECIFICATION_TREE: {
    path:
      '/:idSpecification/especificacao/expotar-especificacao/arvore-especificacao',
    url: '/especificacao/expotar-especificacao/arvore-especificacao',
  },
  FILL_IN_FINAL_DATA: {
    path:
      '/:idSpecification/especificacao/expotar-especificacao/preencher-dados-finais',
    url: '/especificacao/expotar-especificacao/preencher-dados-finais',
  },
  REVIEW_SPECIFICATION: {
    path:
      '/:idSpecification/especificacao/expotar-especificacao/revisar-especificacao',
    url: '/especificacao/expotar-especificacao/revisar-especificacao',
  },
  NEXT_STEPS: {
    path:
      '/:idSpecification/especificacao/expotar-especificacao/proximos-passos',
    url: '/especificacao/expotar-especificacao/proximos-passos',
  },
};

export default Urls;
