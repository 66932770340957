import React from 'react';

import { Container } from './styles';

interface NodeProps {
  title: string;
  legend?: string;
  block?: boolean;
  isFull?: boolean;
}

const Node: React.FC<NodeProps> = ({ title, legend, block, isFull }) => (
  <Container block={block} legend={legend || ''} isFull={isFull || false}>
    <span>{title}</span>
  </Container>
);

export default Node;
