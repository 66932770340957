export const URL_GUIAEDUTEC_RED =
  'https://plataformaedutec.com.br/buscaportag/';

export const URL_IDENTIDICACAO_RED =
  'https://identificacaodered.plataformaedutec.com.br/';

export const URL_NEW_SPECIFICATION = 'novo';

export const LEGEND = {
  OPCIONAL: 'opcional',
  AO_MENOS_UM: 'ao-menos-um',
  UM_E_APENAS_UM: 'um-e-apenas-um',
  OBRIGATORIO: 'obrigatorio',
  DIVISAO_SECAO: 'divisao-secao',
};

export const getLegentStatus = (variant: string): string => {
  switch (variant) {
    case 'opcional':
      return 'optional-status';

    case 'ao-menos-um':
      return 'at-least-one-status';

    case 'um-e-apenas-um':
      return 'one-and-minus-one-status';

    case 'obrigatorio':
      return 'mandatory-status';

    default:
      return 'default-status';
  }
};

export const getExampleWhatIs = (
  description: string,
  definition: string,
): string => {
  return `O presente instrumento tem como objetivo a contratação de <b>${description}</b> que se constitui em ${definition}`;
};
