import { tint } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background: #fbfbfb;
  height: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #eee;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Toolbar = styled.div`
  padding-bottom: 10px;
  margin: 0 -5px;
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;

    & + div {
      position: relative;
      padding-left: 10px;
      margin-left: 10px;

      &:before {
        content: '';
        position: absolute;
        background: ${props => tint(0.9, props.theme.colors.dark)};
        width: 2px;
        height: 50%;
        left: -1px;
        top: 50%;
        border-radius: 1px;
        transform: translateY(-50%);
      }
    }

    /* &:nth-child(-n + 2) {
      padding-right: 10px;
    }

    &:nth-child(n + 2) {
      padding-left: 10px;
    } */

    span {
      min-width: 40px;
      text-align: center;
    }

    button {
      margin: 0 5px;
    }
  }

  span {
    margin: 0 5px;
  }

  @media (max-width: ${props => props.theme.screen.sm - 1}px) {
    .hidden {
      display: none;
    }

    button {
      padding: 5px;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;

  > div > div {
    background: ${props => tint(0.9, props.theme.colors.dark)};
    border: 1px solid ${props => tint(0.9, props.theme.colors.dark)};

    > div {
      background: ${props => tint(0.9, props.theme.colors.dark)};

      > div > div {
        box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 10%);
      }
    }
  }
`;
