import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Urls from './urls';

import Route from './Route';

import Home from '../pages/Home';
import TechnicalSpecification from '../pages/TechnicalSpecification';
import ListReds from '../pages/ListReds';
import SelectRed from '../pages/SelectRed';
import FillInFinalData from '../pages/FillInFinalData';
import ReviewSpecification from '../pages/ReviewSpecification';
import NextSteps from '../pages/NextSteps';
import RespondingRequirements from '../pages/RespondingRequirements';
import SpecificationTree from '../pages/SpecificationTree';
import Specifications from '../pages/Specifications';

const RouteTaxonomia: React.FC = () => (
  <TechnicalSpecification>
    <Switch>
      <Route path={Urls.SPECIFICATION.path} exact component={ListReds} />
      <Route path={Urls.SELECT_RED_DETAIL.path} exact component={SelectRed} />
      <Route
        path={Urls.SPECIFICATION_TREE.path}
        exact
        component={SpecificationTree}
      />
      <Route
        path={Urls.FILL_IN_FINAL_DATA.path}
        exact
        component={FillInFinalData}
      />
      <Route
        path={Urls.REVIEW_SPECIFICATION.path}
        exact
        component={ReviewSpecification}
      />
      <Route path={Urls.NEXT_STEPS.path} exact component={NextSteps} />
      <Route
        path={Urls.FUNCTIONAL_REQUIREMENT.path}
        component={RespondingRequirements}
      />
      <Route
        path={Urls.NON_FUNCTIONAL_REQUIREMENT.path}
        component={RespondingRequirements}
      />
    </Switch>
  </TechnicalSpecification>
);

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path={Urls.HOME.path} exact component={Home} />
      <Route
        path={Urls.SPECIFICATIONS.path}
        exact
        component={Specifications}
        isPrivate
      />
      <Route path={Urls.SPECIFICATION.path} component={RouteTaxonomia} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
