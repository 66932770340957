import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FiChevronDown } from 'react-icons/fi';
import ReactHtmlParser from 'react-html-parser';

import useKeyPress from '../../hooks/useKeyPress';

import { getLegentStatus } from '../../utils/const';

import {
  Container,
  HeadearCollapse,
  TitleCollapse,
  ButtonExpand,
  PressTheLetter,
} from './styles';

interface ItemRespondingRequirementsProps {
  keyItem: number;
  keyPress: string;
  title: string;
  description: string;
  variant: string;
  selected: boolean | undefined;
  expanded: boolean;
  onExpanded(itemId: number): void;
  onSelect(): void;
}

const ItemRespondingRequirements: React.FC<ItemRespondingRequirementsProps> = ({
  keyItem,
  keyPress,
  title,
  description,
  expanded,
  variant,
  selected,
  onExpanded,
  onSelect,
}) => {
  useKeyPress(keyPress, () => {
    onSelect();
  });

  return (
    <Container>
      <HeadearCollapse>
        <TitleCollapse onClick={onSelect}>
          <PressTheLetter>
            <div
              className={
                selected ? 'press-the-letter-active' : getLegentStatus(variant)
              }
            >
              <div>Tecla</div>
              <span>{keyPress}</span>
            </div>
          </PressTheLetter>

          <strong>{title}</strong>
        </TitleCollapse>

        {description && (
          <ButtonExpand
            isExpanded={expanded}
            onClick={() => onExpanded(keyItem)}
          >
            <FiChevronDown />
          </ButtonExpand>
        )}
      </HeadearCollapse>

      {description && (
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <p>{ReactHtmlParser(description)}</p>
            </motion.section>
          )}
        </AnimatePresence>
      )}
    </Container>
  );
};

export default ItemRespondingRequirements;
