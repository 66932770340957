import styled, { css, keyframes } from 'styled-components';
import { shade, tint } from 'polished';
import { AiOutlineLoading } from 'react-icons/ai';

interface ContainerProps {
  variant?: 'default' | 'primary' | 'link' | 'danger';
  block?: boolean;
  isTitle: boolean;
  isIcon: boolean;
}

const buttonVariant = {
  default: css`
    border-color: ${props => tint(0.9, props.theme.colors.black)};
    color: ${props => props.theme.colors.primary};

    &:hover {
      background: ${props => tint(0.97, props.theme.colors.black)};
    }
  `,
  link: css`
    border: none;
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => shade(0.1, props.theme.colors.primary)};
    }
  `,
  danger: css`
    border-color: ${props => tint(0.9, props.theme.colors.black)};
    color: ${props => props.theme.colors.red};

    &:hover {
      background: ${props => tint(0.97, props.theme.colors.black)};
    }
  `,
  primary: css`
    background: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.btnPrimaryColor};

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.primary)};
      border-color: ${props => shade(0.1, props.theme.colors.primary)};
    }
  `,
};

export const Container = styled.button<ContainerProps>`
  min-height: 42px;
  border-radius: 10px;
  border: 0;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  transition: background-color 0.2s color 0.2s;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${props => buttonVariant[props.variant || 'default']}

  ${props =>
    props.block &&
    css`
      width: 100%;
    `}

  ${props =>
    props.isIcon &&
    css`
      padding: 10px;
    `}

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  > svg {
    flex-shrink: 0;
    font-size: 22px;

    ${props =>
      props.isTitle &&
      css`
        margin-right: 10px;
      `}
  }
`;

const loading = keyframes`
  100% {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  }
`;

export const Loading = styled(AiOutlineLoading)`
  animation: ${loading} 1s infinite linear;
`;
