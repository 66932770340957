import styled from 'styled-components';
import { lighten, transparentize } from 'polished';

import imgBGContainer from '../../assets/bg-container.svg';
import imgBGContainer1 from '../../assets/bg-container1.svg';

export const Container = styled.div`
  background: transparent url(${imgBGContainer}) 0% 0% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -250px;
`;

export const Header = styled.header`
  width: 100%;
  height: 80px;
  margin-top: 20px;
  display: block;

  & > div {
    max-width: 1120px;
    height: 80px;
    margin: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;

      img {
        height: 60px;
      }

      ul {
        margin-right: 15px;
        display: flex;

        li {
          a {
            height: 100%;
            padding: 0 10px;
            font-size: 18px;
            text-decoration: none;
            color: ${props => props.theme.colors.secundary};
            transition: color 0.2s;

            display: flex;
            align-items: center;

            &:hover {
              color: ${props => lighten(0.2, props.theme.colors.secundary)};
            }

            &.active {
              color: ${props => props.theme.colors.primary};
            }
          }
        }
      }
    }
  }

  @media (max-width: 1180px) {
    > div {
      padding: 0 30px;
    }
  }
`;

export const Main = styled.main`
  section {
    width: 100%;
    max-width: 1120px;
    margin: 100px auto 100px auto;

    &.full-section {
      max-width: none;
    }

    &.reds-section {
      padding: 0 30px;
    }

    &.header-title {
      margin: 20px auto 100px auto;
    }
  }
`;

export const Content = styled.div`
  background: ${props => props.theme.colors.secundary} url(${imgBGContainer1})
    0% 0% no-repeat;
  background-size: auto auto;
  background-position: right;
  background-repeat: no-repeat;
  padding: 80px 0;

  div {
    max-width: 1024px;
    margin: 0 auto;
  }

  h1 {
    text-align: center;
    color: ${props => props.theme.colors.primary};

    &::before {
      left: 50%;
      margin-left: -15px;
    }
  }

  p {
    margin-top: 30px;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    color: white;
  }

  @media (max-width: 1180px) {
    padding: 80px 30px;
  }
`;

export const InfoContent = styled.div`
  background: ${props => transparentize(0.85, props.theme.colors.primary)};
  padding: 80px 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: ${props => props.theme.colors.secundary};
    }
  }
`;

export const InfoContentVideo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    width: calc(100% - 25px);

    p {
      margin-top: 30px;
      font-weight: 300;
      font-size: 20px;
    }

    a {
      margin-top: 30px;
    }
  }

  @media (max-width: 1180px) {
    & > div {
      padding: 0 30px;

      + div {
        margin-top: 50px;
      }
    }
  }

  @media (max-width: 999px) {
    flex-direction: column;

    & > div {
      width: 100%;
      text-align: center;

      h1:before {
        left: 50%;
        margin-left: -15px;
      }
    }
  }
`;

export const FooterContainer = styled.footer`
  background: ${props => props.theme.colors.secundary};
  padding: 30px;

  > div {
    max-width: 1120px;
    margin: auto;

    > div {
      max-width: 800px;
      margin: auto;
      padding: 50px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    strong {
      color: ${props => props.theme.colors.white};
      text-align: center;
      display: block;
      padding-top: 30px;
      padding-bottom: 10px;
      border-top: 1px solid
        ${props => transparentize(0.8, props.theme.colors.white)};
    }

    p {
      max-width: 1000px;
      margin: auto;
      font-size: 14px;
      color: ${props => transparentize(0.5, props.theme.colors.white)};
      text-align: center;
    }
  }

  @media (max-width: 999px) {
    > div > div {
      flex-direction: column;
    }
  }
`;

export const Contacts = styled.div`
  padding: 0 30px;

  > div {
    + div {
      margin-top: 10px;
    }

    display: flex;

    svg {
      flex-shrink: 0;
      font-size: 25px;
      color: ${props => props.theme.colors.primary};
    }

    > span {
      margin-left: 15px;
      font-size: 14px;
      color: ${props => props.theme.colors.white};

      span {
        display: block;
      }
    }
  }

  @media (max-width: 999px) {
    margin-top: 50px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      flex-direction: column;
      align-items: center;

      + div {
        margin-top: 30px;
      }

      svg {
        margin-bottom: 20px;
      }

      > span {
        margin-left: 0;
        text-align: center;
      }
    }
  }
`;

export const SocialNetwork = styled.div`
  display: flex;

  a {
    background: ${props => transparentize(0.95, props.theme.colors.white)};
    width: 50px;
    height: 50px;
    border-radius: 12px;
    transition: background 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${props => transparentize(0.9, props.theme.colors.white)};
    }

    & + a {
      margin-left: 10px;
    }

    svg {
      font-size: 25px;
      color: ${props => props.theme.colors.primary};
    }
  }

  @media (max-width: 999px) {
    margin-top: 50px;
  }
`;
