import styled, { css } from 'styled-components';
import { tint, transparentize } from 'polished';
import Legend from '../Legend';

interface ContainerProps {
  isMdHide?: boolean;
}
interface ContentProps {
  isScroll?: boolean;
  isBorder?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.export-diagram {
    height: auto;
    overflow: visible;
    display: block;
  }

  ${props =>
    props.isMdHide &&
    css`
      @media (max-width: ${props.theme.screen.md - 1}px) {
        height: 0;
      }
    `};
`;

export const Header = styled.header`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  > h1 {
    flex: 1;
    padding-right: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button {
    margin-left: 10px;
  }

  ${Container}.export-diagram & {
    padding: 30px;

    > div {
      display: none;
    }
  }
`;

export const Content = styled.div<ContentProps>`
  flex: 1;
  background: ${props => transparentize(0.8, props.theme.colors.white)};
  overflow: hidden;

  ${props =>
    props.isBorder &&
    css`
      border: 1px solid ${tint(0.9, props.theme.colors.black)};
      border-radius: 12px;
    `}

  > div {
    height: 100%;
    display: flex;

    ${props =>
      props.isScroll &&
      css`
        overflow: auto;
      `}

    > div {
      > ul {
        padding: 30px;
      }
    }
  }

  ${Container}.export-diagram & {
    overflow: initial;
    border: none;

    > div {
      height: auto;
      overflow: initial;
    }
  }
`;

export const LegendDiagram = styled(Legend)`
  ${Container}.export-diagram & {
    border: none;

    ul li {
      width: auto;
      max-width: none;
    }
  }
`;

export const ActionsResult = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  > button {
    margin: 0 10px;
  }
`;
