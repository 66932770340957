import styled, { css, keyframes } from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';
import { transparentize } from 'polished';

interface ContainerProps {
  isInFront: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isInFront &&
    css`
      position: fixed;
      background: ${transparentize(0.6, props.theme.colors.white)};
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 999;
    `}
`;

const loading = keyframes`
  100% {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  }
`;

export const Loading = styled(AiOutlineLoading)`
  font-size: 60px;
  color: ${props => props.theme.colors.primary};
  animation: ${loading} 1s infinite linear;
`;
