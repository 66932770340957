import React, { useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { FiX } from 'react-icons/fi';

import { Container, Content, CloseModal } from './styles';

interface ModalProps {
  visible: boolean;
  width?: number | string;
  onClose?(): void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  visible = false,
  width,
  onClose,
}) => {
  const modalTransition = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const modalContent = useTransition(visible, null, {
    from: { opacity: 0, transform: 'translateY(40px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(40px)' },
  });

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
      // document.body.style.paddingRight = '0';
    };
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
      // document.body.style.paddingRight = '15px';
    } else {
      setTimeout(() => {
        document.body.style.overflow = 'auto';
        // document.body.style.paddingRight = '0';
      }, 250);
    }
  }, [visible]);

  return (
    <>
      {modalTransition.map(({ item, key, props }) => {
        return (
          item && (
            <Container key={key} style={props} widthmax={width || 500}>
              {modalContent.map(
                ({
                  item: itemContent,
                  key: keyContent,
                  props: propsContent,
                }) => {
                  return (
                    itemContent && (
                      <animated.div key={keyContent} style={propsContent}>
                        <Content>
                          <CloseModal onClick={onClose}>
                            <FiX />
                          </CloseModal>

                          {children}
                        </Content>
                      </animated.div>
                    )
                  );
                },
              )}
            </Container>
          )
        );
      })}
    </>
  );
};

export default Modal;
