import styled, { css } from 'styled-components';

interface ContainerProps {
  gutter?: number | number[];
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: row wrap;

  ${props =>
    props.gutter &&
    css`
      row-gap: ${typeof props.gutter === 'number' ? 0 : props.gutter[1]}px;
      margin: 0 -${(typeof props.gutter === 'number' ? props.gutter : props.gutter[0]) / 2}px;

      > div {
        padding: 0
          ${(typeof props.gutter === 'number'
            ? props.gutter
            : props.gutter[0]) / 2}px;
      }
    `}
`;
