import styled from 'styled-components';
import { tint } from 'polished';

import Tree from '../../components/Tree';

export const Container = styled.div`
  padding: 30px;

  > strong {
    font-size: 25px;
    font-weight: 700;
    color: ${props => props.theme.colors.secundary};
  }

  > p {
    position: relative;
    padding-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      background: ${props => props.theme.colors.primary};
      width: 20px;
      height: 6px;
      left: 0;
      bottom: 0;
      border-radius: 3px;
    }
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;

export const ContentRespondingRequirements = styled.div`
  margin-top: 50px;
`;

export const TitleActionExpandAll = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  > strong {
    margin-right: 20px;
    line-height: 1.4;
    color: ${props => props.theme.colors.secundary};
    font-size: 18px;
  }

  > div {
    margin: 10px 0;

    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

export const TreeRow = styled(Tree)`
  padding-bottom: 30px;

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    display: none;
  }
`;

export const TreeCol = styled(Tree)`
  padding-bottom: 30px;
  display: none;

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    display: block;
  }
`;

export const CategoryDivider = styled.div`
  background: ${props => props.theme.colors.white};
  min-height: 68px;
  margin-top: 10px;
  padding: 15px;
  border: 1px solid ${props => tint(0.95, props.theme.colors.black)};
  border-radius: 12px;

  display: flex;
  align-items: center;

  > div {
    width: 36px;
    height: 36px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
  }

  strong {
    margin-left: 15px;
  }
`;

export const CategoryContent = styled.div`
  padding-left: 51px;
`;

export const ModalContentInfo = styled.div`
  margin-top: 30px;
`;
