import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;

  header {
    text-align: center;

    strong {
      font-size: 28px;
      font-weight: 900;
      color: ${props => props.theme.colors.secundary};
    }

    p {
      margin-top: 15px;
    }
  }

  form {
    margin: 30px 0 15px 0;

    > div + div {
      margin-top: 15px;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 10px;
      height: 58px;
    }

    a {
      margin-bottom: 20px;
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;
