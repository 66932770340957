import React from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiMinus,
  FiXCircle,
} from 'react-icons/fi';

import { Container } from './styles';

interface Items {
  key: number;
  title: string;
}

interface AlertProps {
  title: string;
  description?: string;
  variant?: 'success' | 'info' | 'warning' | 'error';
  items?: Items[];
}

const Alert: React.FC<AlertProps> = ({
  title,
  description,
  variant,
  items,
}) => (
  <Container variant={variant}>
    {variant === 'error' && <FiXCircle />}
    {variant === 'success' && <FiCheckCircle />}
    {variant === 'warning' && <FiAlertCircle />}
    {(!variant || variant === 'info') && <FiInfo />}

    <div>
      <strong>{title}</strong>
      {description && <p>{description}</p>}

      {items && items.length > 0 && (
        <ul>
          {items?.map(item => (
            <li key={item.key}>
              <FiMinus />
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  </Container>
);

export default Alert;
