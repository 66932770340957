import React from 'react';

import { Container } from './styles';

interface RowProps {
  gutter?: number | number[];
  className?: string;
}

const Row: React.FC<RowProps> = ({ gutter, className, children }) => {
  return (
    <Container className={className} gutter={gutter}>
      {children}
    </Container>
  );
};

export default Row;
