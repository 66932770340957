import React from 'react';
import { AuthProvider } from './auth';
import { CalculateProdessProvider } from './CalculateProdess';
import { NonFunctionalProvider } from './nonFunctional';

import { RedsProvider } from './reds';
import { RespondingProvider } from './responding';
import { RoutesRequirementsProvider } from './routesRequirements';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <RedsProvider>
        <NonFunctionalProvider>
          <RoutesRequirementsProvider>
            <CalculateProdessProvider>
              <RespondingProvider>{children}</RespondingProvider>
            </CalculateProdessProvider>
          </RoutesRequirementsProvider>
        </NonFunctionalProvider>
      </RedsProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
