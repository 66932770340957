import React from 'react';
import { FiArrowRight, FiCornerDownRight } from 'react-icons/fi';

import { Container, ItemTree } from './styles';

interface TreeProps {
  items: string[];
  type?: 'column' | 'row';
  className?: string;
}

const Tree: React.FC<TreeProps> = ({ items, type = 'column', className }) => {
  return (
    <Container className={className} type={type}>
      {items.map((item, index) => (
        <ItemTree key={item} current={index} type={type}>
          {index > 0 &&
            (type === 'row' ? <FiArrowRight /> : <FiCornerDownRight />)}

          <span>{item}</span>
        </ItemTree>
      ))}
    </Container>
  );
};

export default Tree;
