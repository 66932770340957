import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 30px;
  overflow: hidden;
`;

export const PDFView = styled.div`
  height: 100%;
  min-height: 400px;
  overflow: hidden;

  @media (max-width: 1199px) {
    height: calc(100vh - 370px);
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    height: calc(100vh - 320px);
  }
`;

export const ActionsResult = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  > button {
    margin: 0 10px;
  }
`;
