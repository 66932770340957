import React from 'react';
import { IconBaseProps } from 'react-icons/lib';

import { Container, Loading } from './styles';

interface ResultProps {
  icon?: React.ComponentType<IconBaseProps>;
  status?: 'success' | 'error' | 'info' | 'warning' | 'primary';
  loading?: boolean;
  title: string;
  description?: string;
}

const Result: React.FC<ResultProps> = ({
  icon: Icon,
  status = 'primary',
  loading = false,
  title,
  description,
  children,
}) => (
  <Container status={status}>
    <div>
      {!loading && Icon && <Icon />}
      {loading && <Loading />}

      <strong>{title}</strong>
      {description && <p>{description}</p>}

      {children}
    </div>
  </Container>
);

export default Result;
