import React, { createContext, useCallback, useContext, useState } from 'react';

import { RequirementCategory, useReds } from './reds';
import { useNonFunctional } from './nonFunctional';

import getRouteStructureRequirements from '../utils/getRouteStructureRequirements';
import Urls from '../routes/urls';

export interface RoutesRequirementsData {
  path: string;
  isNonFunctional: boolean;
  structure: string[];
  requirement_categories: RequirementCategory;
}

interface RoutesRequirementsContextData {
  routesRequirement: RoutesRequirementsData[];
  generateRouteRequirements(urlIdSpecification: string): Promise<string>;
  initRoutesRequirements(): void;
}

const RoutesRequirementsContext = createContext<RoutesRequirementsContextData>(
  {} as RoutesRequirementsContextData,
);

const RoutesRequirementsProvider: React.FC = ({ children }) => {
  const [routesRequirement, setRoutesRequirement] = useState<
    RoutesRequirementsData[]
  >(() => {
    const routesRequirementStorage = localStorage.getItem(
      '@Taxonomia:routesRequirement',
    );

    if (routesRequirementStorage && routesRequirementStorage.length > 0) {
      return JSON.parse(routesRequirementStorage);
    }

    return [];
  });

  const { selectedReds } = useReds();
  const { getNonFunctional } = useNonFunctional();

  const generateRouteRequirements = useCallback(
    async (urlIdSpecification: string): Promise<string> => {
      const nonFunctional = await getNonFunctional();

      const route = Array<RoutesRequirementsData>();

      selectedReds.forEach(({ key: keyRed, functionalRequirements }) => {
        if (functionalRequirements) {
          const { requirement_categories } = functionalRequirements;

          if (requirement_categories) {
            requirement_categories.forEach(rc => {
              getRouteStructureRequirements({
                url: `${urlIdSpecification}${Urls.FUNCTIONAL_REQUIREMENT.url}`,
                category: rc,
                route,
                structure: [],
                newRoute: keyRed,
                isNonFunctional: false,
              });
            });
          }
        }
      });

      nonFunctional.forEach(rc => {
        getRouteStructureRequirements({
          url: `${urlIdSpecification}${Urls.NON_FUNCTIONAL_REQUIREMENT.url}`,
          category: rc,
          route,
          structure: [],
          newRoute: '',
          isNonFunctional: true,
        });
      });

      localStorage.setItem(
        '@Taxonomia:routesRequirement',
        JSON.stringify(route),
      );

      setRoutesRequirement(route);

      return route[0].path;
    },
    [selectedReds, getNonFunctional],
  );

  const initRoutesRequirements = useCallback(() => {
    localStorage.removeItem('@Taxonomia:routesRequirement');

    setRoutesRequirement([]);
  }, []);

  return (
    <RoutesRequirementsContext.Provider
      value={{
        routesRequirement,
        generateRouteRequirements,
        initRoutesRequirements,
      }}
    >
      {children}
    </RoutesRequirementsContext.Provider>
  );
};

function useRoutesRequirements(): RoutesRequirementsContextData {
  const context = useContext(RoutesRequirementsContext);

  if (!context) {
    throw new Error(
      'useRoutesRequirements must be used within an RoutesRequirementsProvider',
    );
  }

  return context;
}

export { RoutesRequirementsProvider, useRoutesRequirements };
