import React from 'react';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { Red } from '../../../hooks/reds';

import { Container, ButtonRed } from './styles';

interface ItemsRedsProps {
  reds: Red[];
  onSelected(red: Red): void;
}

const ItemsReds: React.FC<ItemsRedsProps> = ({ reds, onSelected }) => {
  return (
    <Container>
      {reds.map((red: Red) => (
        <li key={red.id}>
          <ButtonRed
            disabled={red.disabled}
            variant={red.legend}
            isActive={red.active}
            onClick={() => onSelected(red)}
          >
            {red.active && <FiCheckCircle />}
            {red.active && <FiXCircle className="red-remove-icon" />}

            <span>{red.description}</span>
          </ButtonRed>
        </li>
      ))}
    </Container>
  );
};

export default ItemsReds;
