import React from 'react';

import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import HeaderTitle from '../../components/HeaderTitle';

import ImgPlataformaEvidencia from '../../assets/plataforma-evidencias.png';
import ImgPlataformaEduTec from '../../assets/plataforma-edutec.png';
import ImgToolkit from '../../assets/toolkit.png';

import { Container, Card, ContentCard } from './styles';
import ButtonLinkExternal from '../../components/ButtonLinkExternal';
import Row from '../../components/Row';
import Col from '../../components/Col';

const NextSteps: React.FC = () => {
  return (
    <ContentTechnicalSpecification title="Exportar Especificação" isFooter>
      <Container>
        <HeaderTitle title="Próximos passos" />

        <p>
          Agora que você já identificou quais são os Recursos Educacionais
          Digitais mais apropriados para os desafios da sua rede, você pode
          também:
        </p>

        <Row gutter={[16, 16]}>
          <Col sm={6} md={4}>
            <Card>
              <img src={ImgPlataformaEvidencia} alt="Plataforma de Evidência" />
              <ContentCard>
                <div>
                  <strong>Plataforma de Evidências</strong>
                  <p>
                    Conhecer a Plataforma de Evidências sobre tecnologias
                    educacionais para qualificar a tomada de decisão sobre
                    tecnologia e educação.
                  </p>
                </div>
                <ButtonLinkExternal
                  href="https://cieb.net.br/evidencias/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="primary"
                  block
                >
                  Conhecer
                </ButtonLinkExternal>
              </ContentCard>
            </Card>
          </Col>

          <Col sm={6} md={4}>
            <Card>
              <img src={ImgPlataformaEduTec} alt="Plataforma EdutTec" />
              <ContentCard>
                <div>
                  <strong>Plataforma EduTec</strong>
                  <p>
                    Aprofundar no conhecimento sobre Recursos Educacionais
                    Digitais na Plataforma Edutec e encontrar em um único lugar
                    soluções para sua instituição de ensino de maneira fácil.
                  </p>
                </div>
                <ButtonLinkExternal
                  href="https://plataformaedutec.cieb.net.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="primary"
                  block
                >
                  Conhecer
                </ButtonLinkExternal>
              </ContentCard>
            </Card>
          </Col>

          <Col sm={6} md={4}>
            <Card>
              <img src={ImgToolkit} alt="Toolkit" />
              <ContentCard>
                <div>
                  <strong>Toolkit</strong>
                  <p>
                    Utilizar nosso Toolkit que pode te ajudar a incorporar
                    tecnologias digitais de forma segura, ágil e eficiente.
                  </p>
                </div>
                <ButtonLinkExternal
                  href="https://toolkit.plataformaedutec.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="primary"
                  block
                >
                  Conhecer
                </ButtonLinkExternal>
              </ContentCard>
            </Card>
          </Col>
        </Row>
      </Container>
    </ContentTechnicalSpecification>
  );
};

export default NextSteps;
