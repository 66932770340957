import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Red, RequirementCategory } from '../../../hooks/reds';

import Urls from '../../../routes/urls';

import ButtonLink from '../../../components/ButtonLink';
import ButtonLinkExternal from '../../../components/ButtonLinkExternal';
import Diagram from '../../../components/DiagramAnt';
import HeaderTitle from '../../../components/HeaderTitle';
import Modal from '../../../components/Modal';
import Tree from '../../../components/Tree';
import ViewVideo from '../../../components/ViewVideo';
import Row from '../../../components/Row';
import Col from '../../../components/Col';

import api from '../../../services/api';

import {
  LEGEND,
  URL_GUIAEDUTEC_RED,
  URL_NEW_SPECIFICATION,
} from '../../../utils/const';
import getCategoryStructure from '../../../utils/getCategoryStructure';
import isEmptyObject from '../../../utils/isEmptyObject';

import { Container, LegendRed, LegendRedItem, VideoInfo } from './styles';

interface RedModalProps {
  visible: boolean;
  red: Red;
  onclose(): void;
}

const RedModal: React.FC<RedModalProps> = ({ visible, red, onclose }) => {
  const [requirements, setRequirements] = useState<RequirementCategory>(
    {} as RequirementCategory,
  );

  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    if (red.id) {
      api
        .get<RequirementCategory>(`reds/${red.id}/requirements/functional`)
        .then(response => {
          setRequirements(response.data);
        });
    }
  }, [red]);

  useEffect(() => {
    if (red && red.red_category) {
      const arrayCategories = Array<string>();
      getCategoryStructure(red.red_category, arrayCategories);
      setCategories([...arrayCategories, red.description]);
    }
  }, [red]);

  return (
    <Modal visible={visible} width={1300} onClose={onclose}>
      <HeaderTitle title={red.description} size="large" />

      <Container>
        <Row gutter={30}>
          <Col lg={6}>
            <h2>Termos internacionais:</h2>
            <div>{ReactHtmlParser(red.international_terms)}</div>

            <h2>Definição:</h2>
            <div>{ReactHtmlParser(red.definition)}</div>

            <h2>Principais funcionalidades:</h2>
            <div>{ReactHtmlParser(red.main_features)}</div>

            <h2>Exemplos:</h2>
            {ReactHtmlParser(red.examples)}
          </Col>

          <Col lg={6}>
            <VideoInfo>
              <Tree items={categories} />

              {/* <ViewVideo src="https://www.youtube.com/embed/dhoG-305Acw" /> */}

              <div>
                <ButtonLink
                  to={URL_NEW_SPECIFICATION + Urls.SPECIFICATION.url}
                  variant="primary"
                >
                  Faça a especificação técnica
                </ButtonLink>
                <ButtonLinkExternal
                  href={`${URL_GUIAEDUTEC_RED}${red.key}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Conheça soluções de mercado
                </ButtonLinkExternal>
              </div>
            </VideoInfo>
          </Col>
        </Row>
      </Container>

      {!isEmptyObject(requirements) && <Diagram requirements={requirements} />}

      <LegendRed>
        <strong>Legenda:</strong>

        <ul>
          <LegendRedItem>Divisões de seção (ignoradas)</LegendRedItem>
          <LegendRedItem variant={LEGEND.OPCIONAL}>
            Opcional (0,n)
          </LegendRedItem>
          <LegendRedItem variant={LEGEND.AO_MENOS_UM}>
            Ao menos um (1,n)
          </LegendRedItem>
          <LegendRedItem variant={LEGEND.UM_E_APENAS_UM}>
            Um e apenas um (1,1)
          </LegendRedItem>
          <LegendRedItem variant={LEGEND.OBRIGATORIO}>
            Obrigatório (n,n)
          </LegendRedItem>
        </ul>
      </LegendRed>
    </Modal>
  );
};

export default RedModal;
