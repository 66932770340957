import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Button from '../../Button';
import Input from '../../Input';
import CheckboxInput from '../../CheckboxInput';
import Select from '../../Select';
import Row from '../../Row';
import Col from '../../Col';
import Label from '../../Label';

import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, ColSelectCity } from './styles';

interface SignUpProps {
  description?: string;
  onSignIn(): void;
  onClose(): void;
  onFinished?(): void;
}

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  profile_ids: number[];
  secretary: string;
  state: number;
  city_id: number;
  organization: string;
}

interface Profile {
  id: string;
  key: string;
  name: string;
}

interface State {
  id: string;
  abbreviation: string;
}

interface Citie {
  id: string;
  name: string;
}

interface Option {
  id: string;
  value: string;
  label: string;
  key?: string;
}

const SignUp: React.FC<SignUpProps> = ({
  description,
  onSignIn,
  onClose,
  onFinished,
}) => {
  const [profilesOptions, setProfilesOptions] = useState<Option[]>([]);
  const [statesOptions, setStatesOptions] = useState<Option[]>([]);
  const [citiesOptions, setCitiesOptions] = useState<Option[]>([]);
  const [visibleSecretary, setVisibleSecretary] = useState(false);
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { signIn } = useAuth();

  const secretaryOptions = [
    { id: '1', value: 'STATE', label: 'Estadual' },
    { id: '2', value: 'MUNICIPAL', label: 'Municipal' },
  ];

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const checkProfile = profilesOptions
          .filter(
            profile => profile.key === 'gestor' || profile.key === 'professor',
          )
          .map(p => `${p.value}`);

        const schema = Yup.object().shape({
          first_name: Yup.string().required('Campo obrigatório'),
          last_name: Yup.string().required('Campo obrigatório'),
          email: Yup.string()
            .required('Campo obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .required('Campo obrigatório')
            .min(8, 'No mínimo 8 dígitos'),
          password_confirmation: Yup.string()
            .required('Campo obrigatório')
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
          profile_ids: Yup.array().min(1, 'Campo obrigatório'),
          secretary: Yup.string().when('profile_ids', {
            is: (val: string[]) =>
              !!val.find(v => checkProfile.indexOf(v) > -1),
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string(),
          }),
          state: Yup.string().required('Campo obrigatório'),
          city_id: Yup.string().required('Campo obrigatório'),
          organization: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/auth/register', data);

        await signIn({
          login: data.email,
          password: data.password,
        });

        onFinished && onFinished();

        setLoading(false);
        onClose();
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.isAxiosError) {
          const response = err.response as AxiosResponse;

          if (response?.status === 422 && response?.data?.errors) {
            addToast({
              type: 'warning',
              title: 'Não foi possível salvar.',
              description: response.data.errors[0],
            });
          } else {
            addToast({
              type: 'error',
              title: 'Error no cadastro',
              description:
                'Ocorreu um erro ao fazer cadastro, tente novamente.',
            });
          }
        }
      }
    },
    [addToast, profilesOptions, onClose, signIn, onFinished],
  );

  const handleSecretary = useCallback((profileOptionsActive: Option[]) => {
    const checkProfileExists = profileOptionsActive.find(profile => {
      return profile.key === 'gestor' || profile.key === 'professor';
    });

    setVisibleSecretary(!!checkProfileExists);
  }, []);

  const handleStates = useCallback(async () => {
    const { data } = await api.get<State[]>('states');

    setStatesOptions(
      data.map(state => ({
        id: state.id,
        value: state.id,
        label: state.abbreviation,
      })),
    );
  }, []);

  const handleCities = useCallback(async ({ label }: Option) => {
    const { data } = await api.get<Citie[]>(`states/${label}/cities`);

    setCitiesOptions(
      data.map(state => ({
        id: state.id,
        value: state.id,
        label: state.name,
      })),
    );
  }, []);

  const handleProfiles = useCallback(async () => {
    const { data } = await api.get<Profile[]>('profiles');

    setProfilesOptions(
      data.map(profile => ({
        id: profile.id,
        key: profile.key,
        value: profile.id,
        label: profile.name,
      })),
    );
  }, []);

  useEffect(() => {
    handleStates();
    handleProfiles();
  }, [handleProfiles, handleStates]);

  return (
    <Container>
      <header>
        <strong>Criar conta</strong>
        {description && <p>{description}</p>}
      </header>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col sm={6}>
            <Input
              label="Nome"
              name="first_name"
              type="text"
              placeholder="Nome"
            />
          </Col>
          <Col sm={6}>
            <Input
              label="Sobrenome"
              name="last_name"
              type="text"
              placeholder="Sobrenome"
            />
          </Col>
        </Row>

        <Input label="E-mail" name="email" type="email" placeholder="E-mail" />

        <Row gutter={16}>
          <Col sm={6}>
            <Input
              label="Senha"
              name="password"
              type="password"
              placeholder="Senha"
            />
          </Col>
          <Col sm={6}>
            <Input
              label="Confirmar senha"
              name="password_confirmation"
              type="password"
              placeholder="Confirmar senha"
            />
          </Col>
        </Row>

        <CheckboxInput
          label="Perfil"
          name="profile_ids"
          gutter={16}
          sm={6}
          options={profilesOptions}
          onChange={handleSecretary}
        />

        {visibleSecretary && (
          <Select
            label="Secretaria"
            name="secretary"
            placeholder="Selecione a secretaria"
            options={secretaryOptions}
          />
        )}

        <Label>Onde atua</Label>
        <Row gutter={16}>
          <Col sm={4}>
            <Select
              name="state"
              placeholder="UF"
              options={statesOptions}
              onChange={handleCities}
            />
          </Col>
          <ColSelectCity sm={8}>
            <Select
              name="city_id"
              placeholder="Selecione a cidade"
              options={citiesOptions}
            />
          </ColSelectCity>
        </Row>

        <Input
          label="Organização"
          name="organization"
          type="text"
          placeholder="Nome da organização"
        />
      </Form>

      <footer>
        <Button
          variant="primary"
          loading={loading}
          block
          onClick={() => formRef.current?.submitForm()}
        >
          Salvar
        </Button>

        <Button block onClick={onSignIn}>
          Fazer login
        </Button>
      </footer>
    </Container>
  );
};

export default SignUp;
