import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrorred: boolean;
  isIcon: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: ${props => props.theme.colors.white};
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  border: 2px solid ${props => tint(0.95, props.theme.colors.black)};
  color: ${props => props.theme.colors.text};

  transition: border-color 0.2s;

  display: flex;
  align-items: center;

  ${props =>
    props.isErrorred &&
    css`
      border-color: ${props.theme.colors.error};
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${props.theme.colors.primary};
      border-color: ${props.theme.colors.primary};
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props.theme.colors.primary};
    `}

  svg {
    position: absolute;
    left: 15px;
  }

  input {
    flex: 1;
    width: 100%;
    height: 58px;
    line-height: 58px;
    padding: 0 15px;
    color: ${props => props.theme.colors.text};

    ${props =>
      props.isIcon &&
      css`
        padding-left: 50px;
      `}

    &::placeholder {
      color: ${props => tint(0.8, props.theme.colors.black)};
    }
  }
`;

export const Error = styled.div`
  margin-top: 5px;
  color: ${props => props.theme.colors.error};
`;
