import React from 'react';

import { ItemProps } from '..';
import Node from '../Node';

import { Container, Item } from './styles';

export interface ItemsProps {
  items: ItemProps[];
  isVertical?: boolean;
  legend?: string;
}

const Items: React.FC<ItemsProps> = ({ items, isVertical, legend }) => {
  return (
    <Container
      variant={isVertical ? 'vertical' : 'horizontal'}
      legend={legend || ''}
    >
      {items.map(item => (
        <Item key={item.id} legend={legend || ''}>
          <Node
            title={item.title}
            legend={item.legend}
            block={item.is_vertical}
            isFull={!!item.items}
          />

          {item.items && item.items.length > 0 && (
            <Items
              items={item.items}
              isVertical={item.is_vertical}
              legend={item.legend}
            />
          )}
        </Item>
      ))}
    </Container>
  );
};

export default Items;
