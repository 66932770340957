import styled, { css } from 'styled-components';
import { tint } from 'polished';

import { LEGEND } from '../../../utils/const';

interface ContainerProps {
  block?: boolean;
  legend: string;
  isFull: boolean;
}

const nodeTypeVariations = (legend: string, isFull: boolean) => {
  switch (legend) {
    case LEGEND.OPCIONAL:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.green : props.theme.colors.white};
        border-color: ${props => props.theme.colors.green};
      `;

    case LEGEND.AO_MENOS_UM:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.yellow : props.theme.colors.white};
        border-color: ${props => props.theme.colors.yellow};
      `;

    case LEGEND.DIVISAO_SECAO:
      return css`
        background: ${props => tint(0.9, props.theme.colors.black)};
        border-color: ${props => tint(0.9, props.theme.colors.black)};
        color: ${props => props.theme.colors.text};
      `;

    case LEGEND.UM_E_APENAS_UM:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.indigo : props.theme.colors.white};
        border-color: ${props => props.theme.colors.indigo};
      `;

    case LEGEND.OBRIGATORIO:
      return css`
        background: ${props =>
          isFull ? props.theme.colors.error : props.theme.colors.white};
        border-color: ${props => props.theme.colors.error};
      `;

    default:
      return css`
        background: ${props => tint(0.9, props.theme.colors.black)};
        border-color: ${props => tint(0.9, props.theme.colors.black)};
        color: ${props => props.theme.colors.text};
      `;
  }
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;

  span {
    padding: 10px;
    margin: 0 7.5px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    color: ${props =>
      props.isFull ? props.theme.colors.white : props.theme.colors.text};

    ${props => nodeTypeVariations(props.legend, props.isFull)}
  }

  ${props =>
    props.block &&
    css`
      width: 100%;

      span {
        width: 100%;
      }
    `}
`;
