import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  & > div {
    &:first-child {
      flex: 1;
      padding-right: 10px;
    }

    &:last-child {
      width: 25%;

      h2 {
        min-height: 92px;
      }
    }

    & > div {
      padding-top: 10px;
      display: flex;

      & > div {
        flex: 1;
        max-width: 33.3333%;

        &:nth-child(n + 2) {
          padding-left: 10px;
        }
      }
    }

    > h2 {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  h2 {
    background: ${prosp => prosp.theme.colors.primary};
    min-height: 42px;
    padding: 0 15px;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 300;
    color: ${prosp => prosp.theme.colors.white};

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 999px) {
    flex-direction: column;

    > div {
      &:first-child {
        position: relative;
        padding-right: 0;

        > div > div {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            background: ${props => props.theme.colors.primary};
            width: 2px;
            height: calc(100% + 20px);
            left: -20px;
            top: -20px;
          }

          &:last-child::before {
            display: none;
          }

          > h2 {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              background: ${props => props.theme.colors.primary};
              width: 2px;
              height: calc(50% + 20px);
              left: -20px;
              top: -20px;
            }

            &::after {
              content: '';
              position: absolute;
              background: ${props => props.theme.colors.primary};
              width: 20px;
              height: 2px;
              left: -20px;
              top: 50%;
            }
          }
        }
      }

      &:last-child {
        width: 100%;
        margin-top: 50px;

        h2 {
          min-height: 42px;
        }
      }

      & > div {
        padding-left: 20px;
        flex-direction: column;

        & > div {
          max-width: none;

          &:nth-child(n + 2) {
            padding-left: 0;
          }

          + div {
            margin-top: 10px;
          }
        }
      }
    }
  }
`;
