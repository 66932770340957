import { tint, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { LEGEND } from '../../../utils/const';

interface LegendRedItemProps {
  variant?: string;
}

export const Container = styled.div`
  margin-top: 30px;

  > div {
    > div {
      width: calc(50% - 25px);

      > h2 {
        margin-bottom: 5px;
        font-size: 20px;
        color: ${props => props.theme.colors.secundary};
      }

      > div {
        margin-bottom: 15px;
      }

      > ul {
        li {
          position: relative;
          padding-left: 25px;
          margin-top: 5px;

          &::before {
            content: '';
            position: absolute;
            background: ${props => props.theme.colors.primary};
            width: 15px;
            height: 6px;
            left: 0;
            top: 8px;
            border-radius: 3px;
          }
        }
      }
    }
  }
`;

export const VideoInfo = styled.div`
  padding: 30px;
  border: 1px solid ${props => transparentize(0.9, props.theme.colors.black)};
  border-radius: 12px;

  > div {
    margin-top: 30px;

    a + a {
      margin-top: 10px;
    }
  }
`;

export const LegendRed = styled.div`
  background: ${props => transparentize(0.5, props.theme.colors.white)};
  margin-top: 50px;
  padding: 30px;
  border: 1px solid ${props => tint(0.95, props.theme.colors.black)};
  border-radius: 12px;

  strong {
    font-size: 20px;
  }

  ul {
    overflow: hidden;
  }
`;

const legendRedItemTypeVariations = (type: string) => {
  switch (type) {
    case LEGEND.OPCIONAL:
      return css`
        background: ${props => tint(0.9, props.theme.colors.green)};
        border-color: ${props => props.theme.colors.green};
      `;

    case LEGEND.AO_MENOS_UM:
      return css`
        background: ${props => tint(0.9, props.theme.colors.yellow)};
        border-color: ${props => props.theme.colors.yellow};
      `;

    case LEGEND.UM_E_APENAS_UM:
      return css`
        background: ${props => tint(0.8, props.theme.colors.orange)};
        border-color: ${props => props.theme.colors.orange};
      `;

    case LEGEND.OBRIGATORIO:
      return css`
        background: ${props => tint(0.9, props.theme.colors.error)};
        border-color: ${props => props.theme.colors.error};
      `;

    default:
      return css`
        background: ${props => tint(0.95, props.theme.colors.black)};
        border-color: ${props => tint(0.9, props.theme.colors.black)};
      `;
  }
};

export const LegendRedItem = styled.li<LegendRedItemProps>`
  position: relative;
  margin-top: 10px;
  padding-left: 30px;
  width: 33.333%;
  float: left;

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    border: 2px solid transparent;
    border-radius: 8px;

    ${props => legendRedItemTypeVariations(props.variant || '')}
  }
`;
