import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrorred: boolean;
}

interface ContentProps {
  isShowCode: boolean;
}

interface RichTextProps {
  height?: number;
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.white};
  width: 100%;
  padding: 0 8px 8px;
  border-radius: 10px;
  overflow: hidden;

  border: 2px solid ${props => tint(0.95, props.theme.colors.black)};
  color: ${props => props.theme.colors.text};

  transition: border-color 0.2s;

  ${props =>
    props.isErrorred &&
    css`
      border-color: ${props.theme.colors.error};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props.theme.colors.primary};
    `}

  textarea {
    display: none;
  }
`;

export const Toolbar = styled.div`
  border-bottom: 2px solid ${props => tint(0.95, props.theme.colors.black)};
  display: flex;

  button {
    padding: 10px;
    color: ${props => tint(0.2, props.theme.colors.black)};
    transition: color 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Content = styled.div<ContentProps>`
  position: relative;

  ${props =>
    props.isShowCode &&
    css`
      background: ${tint(0.95, props.theme.colors.black)};
    `}
`;

export const RichText = styled.div<RichTextProps>`
  position: relative;
  width: 100%;
  min-height: ${props => (props.height ? `${props.height}px` : '300px')};
  padding: 10px;
  resize: vertical;
  overflow: auto;
  z-index: 2;

  ul,
  ol {
    margin-left: 20px;
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${props => tint(0.8, props.theme.colors.black)};
  pointer-events: none;
  z-index: 1;
`;

export const Error = styled.div`
  margin-top: 5px;
  color: ${props => props.theme.colors.error};
`;
