import React, { useCallback, useRef, useState } from 'react';
import { FiKey, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import Input from '../../Input';
import Button from '../../Button';

import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';

interface SignInProps {
  description?: string;
  onSignUp(): void;
  onClose(): void;
  onFinished?(): void;
}

interface FormData {
  login: string;
  password: string;
}

const SignIn: React.FC<SignInProps> = ({
  description,
  onSignUp,
  onClose,
  onFinished,
}) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          login: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          login: data.login,
          password: data.password,
        });

        onFinished && onFinished();
        onClose();
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Error na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [addToast, signIn, onClose, onFinished],
  );

  return (
    <Container>
      <header>
        <strong>Login</strong>
        {description && <p>{description}</p>}
      </header>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="login" type="email" placeholder="E-mail" icon={FiMail} />

        <Input
          name="password"
          type="password"
          placeholder="Senha"
          icon={FiKey}
        />
      </Form>

      <footer>
        <Link to="/">Esqueceu a senha?</Link>

        <Button
          variant="primary"
          loading={loading}
          block
          onClick={() => formRef.current?.submitForm()}
        >
          Entrar
        </Button>

        <Button block onClick={onSignUp}>
          Criar conta
        </Button>
      </footer>
    </Container>
  );
};

export default SignIn;
