import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { AnimatePresence, motion } from 'framer-motion';

import { RequirementCategory } from '../../../hooks/reds';

import RequirementTitle from '../RequirementTitle';

import { Container, RequirementItem } from './styles';

interface RequirementsProps {
  categories: RequirementCategory[];
  isSon?: boolean;
}

const Requirements: React.FC<RequirementsProps> = ({
  isSon = false,
  categories,
}) => {
  const [isHover, setIsHover] = useState<false | number>(false);

  return (
    <>
      {categories.length > 0 && (
        <Container className={isSon ? 'child-requirement' : ''}>
          {categories.map(category => (
            <RequirementItem key={category.id} type={category.legend}>
              <RequirementTitle
                title={category.description}
                type={category.legend}
                isFull={
                  (category.requirement_categories &&
                    category.requirement_categories?.length > 0) ||
                  (category.requirements && category.requirements?.length > 0)
                }
              />

              <ul>
                {category.requirement_categories &&
                  category.requirement_categories.map(item => (
                    <RequirementItem key={item.id} type={item.legend}>
                      <RequirementTitle
                        title={item.description}
                        type={item.legend}
                        isFull={
                          (item.requirement_categories &&
                            item.requirement_categories?.length > 0) ||
                          (item.requirements && item.requirements?.length > 0)
                        }
                      />

                      {item.requirement_categories && (
                        <Requirements
                          isSon
                          categories={item.requirement_categories}
                        />
                      )}

                      <ul className="child-requirement">
                        {item.requirements &&
                          item.requirements.map(requirement => (
                            <RequirementItem
                              key={requirement.id}
                              type={requirement.legend}
                            >
                              <div
                                className="container-requirement-popover"
                                onMouseEnter={() => setIsHover(requirement.id)}
                                onMouseLeave={() => setIsHover(false)}
                              >
                                <RequirementTitle
                                  title={requirement.description}
                                  type={requirement.legend}
                                />

                                <AnimatePresence initial={false}>
                                  {isHover === requirement.id && (
                                    <motion.div
                                      key="content"
                                      className="requirement-popover"
                                      initial="closed"
                                      animate="open"
                                      exit="closed"
                                      variants={{
                                        open: { opacity: 1, x: 0 },
                                        closed: { opacity: 0, x: '-10px' },
                                      }}
                                    >
                                      <strong>{requirement.description}</strong>
                                      <div>
                                        {ReactHtmlParser(
                                          requirement.full_description || '',
                                        )}
                                      </div>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </div>
                            </RequirementItem>
                          ))}
                      </ul>
                    </RequirementItem>
                  ))}

                {category.requirements &&
                  category.requirements.map(requirement => (
                    <RequirementItem
                      key={requirement.id}
                      type={requirement.legend}
                      onMouseEnter={() => setIsHover(requirement.id)}
                      onMouseLeave={() => setIsHover(false)}
                    >
                      <div
                        className="container-requirement-popover"
                        onMouseEnter={() => setIsHover(requirement.id)}
                        onMouseLeave={() => setIsHover(false)}
                      >
                        <RequirementTitle
                          title={requirement.description}
                          type={requirement.legend}
                        />

                        <AnimatePresence initial={false}>
                          {isHover === requirement.id && (
                            <motion.div
                              key="content"
                              className="requirement-popover"
                              initial="closed"
                              animate="open"
                              exit="closed"
                              variants={{
                                open: { opacity: 1, scale: 1, y: '-50%' },
                                closed: { opacity: 0, scale: 0.9, y: '-50%' },
                              }}
                            >
                              <strong>{requirement.description}</strong>
                              <div>
                                {ReactHtmlParser(
                                  requirement.full_description || '',
                                )}
                              </div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </RequirementItem>
                  ))}
              </ul>
            </RequirementItem>
          ))}
        </Container>
      )}
    </>
  );
};

export default Requirements;
