import React, { useCallback, useEffect, useState } from 'react';
import {
  FiFileText,
  FiLogIn,
  FiMinus,
  FiPlus,
  FiXCircle,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { BiSitemap } from 'react-icons/bi';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import HeaderTitle from '../../components/HeaderTitle';
import Row from '../../components/Row';
import Col from '../../components/Col';
import Progress from '../../components/Progress';
import Urls from '../../routes/urls';
import ButtonLink from '../../components/ButtonLink';
import Button from '../../components/Button';
import ModalDiagram from '../../components/ModalDiagram';
import HeaderLayout from '../../components/HeaderLayout';
import Result from '../../components/Result';

import { URL_NEW_SPECIFICATION } from '../../utils/const';

import {
  Container,
  Main,
  LoaderSpecifications,
  CardItem,
  CardItemButton,
  CardItemContent,
  CardActions,
  DateCreate,
} from './styles';

interface Specification {
  id: string;
  red: {
    description: string;
  };
  additional_reds: {
    id: string;
    description: string;
  }[];
  progress: number;
  finished: boolean;
  dateFormatted: string;
  created_at: string;
}

const Specifications: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [specifications, setSpecifications] = useState<Specification[]>([]);
  const [idSpecification, setIdSpecification] = useState('');
  const [visibleDiagram, setVisibleDiagram] = useState(false);

  const { signOut } = useAuth();

  const { addToast } = useToast();

  const options = [
    { id: '2', title: 'Sair', value: 'close', icon: FiLogIn, action: signOut },
  ];

  const handleExportFile = useCallback((id: string, type = 'pdf') => {
    const params = new FormData();
    params.append('id', `${id}`);
    params.append('type', type);
    params.append('contentDisposition', 'attachment');

    api
      .post(`specifications/report`, params, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Especificação.${type}`);
        document.body.appendChild(link);
        link.click();
      });
  }, []);

  const handleSpecificationView = useCallback((id: string) => {
    setIdSpecification(id);
    setVisibleDiagram(true);
  }, []);

  const handleCloseModalDiagram = useCallback(() => {
    setIdSpecification('');
    setVisibleDiagram(false);
  }, []);

  useEffect(() => {
    async function getSpecifications() {
      try {
        setLoading(true);

        const response = await api.get('users/specifications');

        setSpecifications(
          response.data.map((specification: Specification) => ({
            ...specification,
            dateFormatted: format(
              parseISO(specification.created_at),
              'dd MMM yyyy',
              {
                locale: ptBR,
              },
            ),
          })),
        );

        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(true);

        addToast({
          type: 'error',
          title: 'Error!',
          description:
            'Ocorreu um erro no carregamento das especificações, tente novamente.',
        });
      }
    }

    getSpecifications();
  }, [addToast]);

  return (
    <>
      <Container>
        <HeaderLayout options={options} />

        <Main>
          {loading && <LoaderSpecifications />}

          {error && (
            <Result
              icon={FiXCircle}
              status="error"
              title="Erro!"
              description="Ocorreu um erro no carregamento das especificações, tente novamente."
            />
          )}

          {!loading && !error && (
            <div>
              <HeaderTitle title="Especificações" />

              <Row gutter={[20, 20]}>
                {specifications?.map(specification => (
                  <Col key={specification.id} lg={4} md={6} sm={12}>
                    <CardItem>
                      <CardItemContent>
                        <FiFileText />

                        <DateCreate>{specification.dateFormatted}</DateCreate>

                        <div>
                          <strong>{specification.red?.description}</strong>

                          <ul>
                            {specification.additional_reds.map(red => (
                              <li key={red.id}>
                                <FiMinus />
                                {red.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </CardItemContent>

                      <Progress
                        title={`${specification.progress || 0}% concluida`}
                        progress={specification.progress || 0}
                      />

                      <CardActions>
                        {specification.finished ? (
                          <>
                            <Button
                              icon={FiFileText}
                              block
                              onClick={() => handleExportFile(specification.id)}
                            >
                              PDF
                            </Button>
                            <Button
                              icon={BiSitemap}
                              block
                              onClick={() =>
                                handleSpecificationView(specification.id)
                              }
                            >
                              Ver
                            </Button>
                          </>
                        ) : (
                          <ButtonLink
                            to={`/${specification.id}${Urls.SPECIFICATION.url}`}
                            block
                          >
                            Continuar
                          </ButtonLink>
                        )}
                      </CardActions>
                    </CardItem>
                  </Col>
                ))}

                <Col lg={4} md={6} sm={12}>
                  <Link
                    to={`/${URL_NEW_SPECIFICATION}${Urls.SPECIFICATION.url}`}
                  >
                    <CardItemButton>
                      <FiPlus />
                    </CardItemButton>
                  </Link>
                </Col>
              </Row>
            </div>
          )}
        </Main>
      </Container>

      <ModalDiagram
        idSpecification={idSpecification}
        visible={visibleDiagram}
        title="Minha especificação"
        onClose={handleCloseModalDiagram}
      />
    </>
  );
};

export default Specifications;
