import { tint } from 'polished';
import styled, { css } from 'styled-components';
import { LEGEND } from '../../../utils/const';

interface ContainerProps {
  variant: 'vertical' | 'horizontal';
  legend: string;
}

interface ItemProps {
  legend: string;
}

const typeStyle = {
  vertical: css`
    flex-direction: column;
    padding-left: 20px;

    &:after {
      display: none;
    }

    li div {
      width: 100%;

      span {
        width: 100%;
      }
    }

    > li {
      position: relative;
      margin-top: 15px;

      &:before {
        content: '';
        position: absolute;
        width: 19px;
        left: -11.5px;
        top: 19px;
        border-top-width: 1px;
        border-top-style: solid;
      }

      &::after {
        content: '';
        position: absolute;
        height: calc(100% + 42px);
        left: -12.5px;
        bottom: 0;
        border-left-width: 1px;
        border-left-style: solid;
      }

      &:last-child::after {
        bottom: calc(100% - 20px);
        height: 42px;
      }
    }
  `,
  horizontal: css`
    > li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0 0 0;

      &:before {
        content: '';
        position: absolute;
        top: 15px;
        width: 100%;
        border-top-width: 1px;
        border-top-style: solid;
      }

      &:after {
        content: '';
        position: absolute;
        top: 15px;
        left: 50%;
        height: 15px;
        border-left-width: 1px;
        border-left-style: solid;
      }

      &:first-child {
        &:before {
          left: 50%;
          width: 50%;
        }
      }

      &:last-child {
        &:before {
          right: 50%;
          width: 50%;
        }
      }

      &:only-child {
        &:before {
          display: none;
        }
      }
    }
  `,
};

const typeVariations = (legend: string) => {
  switch (legend) {
    case LEGEND.OPCIONAL:
      return css`
        border-color: ${props => props.theme.colors.green};
      `;

    case LEGEND.AO_MENOS_UM:
      return css`
        border-color: ${props => props.theme.colors.yellow};
      `;

    case LEGEND.DIVISAO_SECAO:
      return css`
        border-color: ${props => tint(0.9, props.theme.colors.black)};
      `;

    case LEGEND.UM_E_APENAS_UM:
      return css`
        border-color: ${props => props.theme.colors.indigo};
      `;

    case LEGEND.OBRIGATORIO:
      return css`
        border-color: ${props => props.theme.colors.error};
      `;

    default:
      return css`
        border-color: ${props => tint(0.9, props.theme.colors.black)};
      `;
  }
};

export const Container = styled.ul<ContainerProps>`
  position: relative;
  display: flex;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 15px;
    border-left-width: 1px;
    border-left-style: solid;
    ${props => typeVariations(props.legend)}
  }

  > ul {
    position: relative;
    display: flex;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 15px;
      border-left-width: 1px;
      border-left-style: solid;
      ${props => typeVariations(props.legend)}
    }
  }

  ${props => typeStyle[props.variant]}
`;

export const Item = styled.li<ItemProps>`
  &:before,
  &:after {
    ${props => typeVariations(props.legend)}
  }
`;
