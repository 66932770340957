import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.header`
  position: fixed;
  background: ${props => props.theme.colors.secundary};
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
  padding: 0 30px;
  box-shadow: 0 8px 15px
    ${props => transparentize(0.75, props.theme.colors.black)};
  z-index: 999;

  & > div {
    max-width: 1120px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > button {
      margin-right: 20px;
    }

    > div {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        height: 100%;
        padding: 15px 0;
      }

      > button {
        height: 100%;

        > svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    height: 70px;
    padding: 0 20px;
  }
`;
