import styled from 'styled-components';
import Col from '../../Col';

export const Container = styled.div`
  padding: 30px;

  header {
    text-align: center;

    strong {
      font-size: 28px;
      font-weight: 900;
      color: ${props => props.theme.colors.secundary};
    }

    p {
      margin-top: 15px;
    }
  }

  form {
    margin: 30px 0 15px 0;
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 10px;
      height: 58px;
    }
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const ColSelectCity = styled(Col)`
  @media (max-width: ${props => props.theme.screen.sm - 1}px) {
    margin-top: 10px;
  }
`;
