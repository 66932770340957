import React from 'react';

import { Container } from './styles';

interface RequirementTitleProps {
  title: string;
  type?: string;
  isFull?: boolean;
}

const RequirementTitle: React.FC<RequirementTitleProps> = ({
  title,
  type,
  isFull = false,
}) => {
  return (
    <Container type={type} isFull={isFull}>
      <span>{title}</span>
    </Container>
  );
};

export default RequirementTitle;
