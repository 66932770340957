import React, { LabelHTMLAttributes } from 'react';

import { Container } from './styles';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  fieldName?: string;
}

const Label: React.FC<LabelProps> = ({ fieldName, children, ...rest }) => (
  <Container htmlFor={fieldName} {...rest}>
    {children}
  </Container>
);

export default Label;
