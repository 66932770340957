import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronUp, FiDownload, FiFileText, FiXCircle } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import { useResponding } from '../../hooks/responding';
import { useCalculateProdess } from '../../hooks/CalculateProdess';
import { useRoutesRequirements } from '../../hooks/routesRequirements';
import { useToast } from '../../hooks/toast';

import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import HeaderTitle from '../../components/HeaderTitle';
import Button from '../../components/Button';
import Result from '../../components/Result';
import ViewPDF from '../../components/ViewPDF';

import Urls from '../../routes/urls';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';

import { Container, Content, ActionsResult, PDFView } from './styles';

const ReviewSpecification: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingTypeExport, setLoadingTypeExport] = useState(null);
  const [errorPdf, setErrorPdf] = useState(false);
  const [pdfDocument, setPdfDocument] = useState('');
  const [isExport, setIsExport] = useState(false);

  const { responding, updateResponding } = useResponding();
  const { routesRequirement } = useRoutesRequirements();
  const { generateProgress } = useCalculateProdess();
  const { addToast } = useToast();

  const history = useHistory();

  const handlePrev = useCallback(() => {
    history.push(
      getIdSpecificationRoute(Urls.FILL_IN_FINAL_DATA.url, responding.id),
    );
  }, [history, responding.id]);

  const handleNext = useCallback(async () => {
    if (!isExport) {
      try {
        setLoading(true);
        setIsExport(true);

        await updateResponding({
          responding: { ...responding, finished: true },
          progress: 100,
          isUpdade: true,
        });

        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error!',
          description:
            'Ocorreu um erro e não foi possível salvar, tente novamente.',
        });

        setLoading(false);
      }
    }
  }, [isExport, updateResponding, responding, addToast]);

  const handleExportFile = useCallback(
    async (type = 'pdf') => {
      const params = new FormData();
      params.append('id', `${responding.id}`);
      params.append('type', type);
      params.append('contentDisposition', 'attachment');

      try {
        setLoadingTypeExport(type);

        const response = await api.post(`specifications/report`, params, {
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Especificação.${type}`);
        document.body.appendChild(link);
        link.click();

        setLoadingTypeExport(null);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error!',
          description: 'Ocorreu um erro e não foi exportar, tente novamente.',
        });

        setLoadingTypeExport(null);
      }
    },
    [responding.id, addToast],
  );

  useEffect(() => {
    if (responding?.finished && !isExport && !loading) {
      history.replace(getIdSpecificationRoute(Urls.SPECIFICATION.url));
    }
  }, [history, responding.finished, isExport, loading]);

  useEffect(() => {
    async function getPdf() {
      const params = new FormData();

      params.append('id', `${responding.id}`);
      params.append('type', 'pdf');
      params.append('contentDisposition', 'inline');

      setLoadingPdf(true);

      try {
        const { data } = await api.post(`specifications/report`, params, {
          responseType: 'arraybuffer',
        });

        setPdfDocument(data);
        setLoadingPdf(false);
      } catch (error) {
        setErrorPdf(true);
        setLoadingPdf(false);
      }
    }

    if (responding?.id) {
      getPdf();
    }
  }, [responding.id]);

  useEffect(() => {
    if (routesRequirement.length > 0) {
      generateProgress(routesRequirement.length + 2);
    }
  }, [generateProgress, routesRequirement]);

  return (
    <ContentTechnicalSpecification
      loading={loading}
      title="Exportar Especificação"
      prev={
        !isExport
          ? {
              name: 'Voltar',
              icon: FiChevronUp,
              disabled: loading,
              onAction: handlePrev,
            }
          : undefined
      }
      done={
        !isExport
          ? {
              name: 'Finalizar',
              variant: 'primary',
              loading,
              isSave: true,
              onAction: handleNext,
            }
          : {
              name: 'Próximos Passos',
              variant: 'primary',
              onAction: () =>
                history.push(
                  getIdSpecificationRoute(Urls.NEXT_STEPS.url, responding.id),
                ),
            }
      }
    >
      <Container>
        {!isExport && <HeaderTitle title="Revisar especificação" />}

        <Content>
          {!isExport && loadingPdf && (
            <Result loading title="Carregando especificação..." />
          )}

          {!isExport && errorPdf && (
            <Result
              icon={FiXCircle}
              status="error"
              title="Erro..."
              description="Ocorreu um erro no carregamento da especificação, tente novamente."
            />
          )}

          {pdfDocument && !isExport && (
            <PDFView>
              <ViewPDF fileUrl={pdfDocument} />
            </PDFView>
          )}

          {isExport && (
            <Result
              icon={FiDownload}
              title="Exportar especificação"
              description="Clique no botão abaixo referente ao formato desejado."
            >
              <ActionsResult>
                <Button
                  icon={FiFileText}
                  loading={loadingTypeExport === 'pdf'}
                  onClick={() => handleExportFile('pdf')}
                >
                  Exportar em PDF
                </Button>
                <Button
                  icon={FiFileText}
                  loading={loadingTypeExport === 'docx'}
                  onClick={() => handleExportFile('docx')}
                >
                  Exportar em Docx
                </Button>
              </ActionsResult>
            </Result>
          )}
        </Content>
      </Container>
    </ContentTechnicalSpecification>
  );
};

export default ReviewSpecification;
