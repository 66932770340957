import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrorred: boolean;
}

interface SelectDropdownProps {
  isFocused: boolean;
}

interface DropdownProps {
  isFocused: boolean;
  isTopDropdown: boolean;
}

interface OptionProps {
  selected?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid ${props => tint(0.95, props.theme.colors.black)};
  color: ${props => props.theme.colors.text};

  transition: border-color 0.2s;

  display: flex;
  align-items: center;

  > svg {
    margin-right: 15px;
  }

  ${props =>
    props.isErrorred &&
    css`
      border-color: ${props.theme.colors.error};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props.theme.colors.primary};

      > svg {
        color: ${props.theme.colors.primary};
      }
    `}
`;

export const SelectDropdown = styled.div<SelectDropdownProps>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transition: transform 0.2s;

    /* ${props =>
      props.isFocused &&
      css`
        transform: rotate(180deg);
      `} */
  }

  span.placeholder {
    color: ${props => tint(0.8, props.theme.colors.black)};
  }
`;

export const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  background: ${props => props.theme.colors.white};
  width: 100%;
  left: 0;
  top: calc(100% + 5px);
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  overflow: hidden;
  z-index: 15;

  > div {
    max-height: 300px;
    padding: 10px 0;
    overflow: auto;
  }

  ${props =>
    props.isTopDropdown &&
    css`
      top: auto;
      bottom: calc(100% + 5px);
    `}
`;

export const Option = styled.div<OptionProps>`
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: ${props => tint(0.98, props.theme.colors.black)};
  }

  ${props =>
    props.selected &&
    css`
      background: ${tint(0.9, props.theme.colors.primary)};
      color: ${props.theme.colors.primary};
      font-weight: bold;

      &:hover {
        background: ${tint(0.9, props.theme.colors.primary)};
      }
    `}
`;

export const Error = styled.div`
  margin-top: 5px;
  color: ${props => props.theme.colors.error};
`;
