import styled from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  progress: number;
}

export const Container = styled.div<ContainerProps>`
  span {
    margin-bottom: 5px;
    font-size: 14px;
    display: block;
  }

  > div {
    position: relative;
    background: ${props => tint(0.9, props.theme.colors.black)};
    width: 100%;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      background: ${props => props.theme.colors.primary};
      width: ${props => `${props.progress}%`};
      height: 6px;
      left: 0;
      top: 0;
      border-radius: 3px;
      transition: width 0.2s;
    }
  }
`;
