import React, { useCallback, useRef, useState } from 'react';
import {
  FiCornerDownLeft,
  FiEye,
  FiFileText,
  FiHelpCircle,
  FiInfo,
  FiLogIn,
  FiTrash2,
  FiUser,
} from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useResponding } from '../../hooks/responding';
import { useToast } from '../../hooks/toast';

import useKeyPress from '../../hooks/useKeyPress';
import Urls from '../../routes/urls';
import api from '../../services/api';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';

import Button from '../Button';
import HeaderTitle from '../HeaderTitle';
import Loader from '../Loader';
import Modal from '../Modal';
import User from '../User';

import {
  Container,
  Header,
  DropdownUser,
  Main,
  Footer,
  Actions,
  ProgressFooter,
  ModalContent,
} from './styles';

interface Action {
  name: string;
  variant?: 'default' | 'primary';
  icon?: React.ComponentType<IconBaseProps>;
  loading?: boolean;
  disabled?: boolean;
  onAction?(): void;
}

interface ActionDone extends Action {
  isSave?: boolean;
}

interface ContentTechnicalSpecificationProps {
  title: string;
  loading?: boolean;
  handleInfo?(): void;
  handleDiagram?(): void;
  prev?: Action | null;
  next?: Action | null;
  done?: ActionDone | null;
  isFooter?: boolean;
}

const ContentTechnicalSpecification: React.FC<ContentTechnicalSpecificationProps> = ({
  title,
  loading = false,
  handleInfo,
  handleDiagram,
  prev,
  next,
  done,
  isFooter,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();

  const { responding } = useResponding();
  const { user, signOut } = useAuth();
  const { addToast } = useToast();

  const refMain = useRef<HTMLElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);

  const options = [
    {
      id: '1',
      title: 'Especificações',
      value: 'specifications',
      icon: FiFileText,
      action: () => history.push(Urls.SPECIFICATIONS.url),
    },
    { id: '2', title: 'Sair', value: 'close', icon: FiLogIn, action: signOut },
  ];

  useKeyPress('ArrowDown', () => {
    if (next && !next.loading && !next.disabled) {
      next.onAction && next.onAction();

      refMain.current?.scrollTo(0, 0);
      refContainer.current?.scrollTo(0, 0);
    }
  });

  useKeyPress('ArrowUp', () => {
    if (prev && !prev.loading && !prev.disabled) {
      prev.onAction && prev.onAction();

      refMain.current?.scrollTo(0, 0);
      refContainer.current?.scrollTo(0, 0);
    }
  });

  useKeyPress('Enter', () => {
    if (done && !done.loading && !done.disabled) {
      handleDone();

      refMain.current?.scrollTo(0, 0);
      refContainer.current?.scrollTo(0, 0);
    }
  });

  const handleNext = useCallback(() => {
    if (next && !next.loading && !next.disabled) {
      next.onAction && next.onAction();

      refMain.current?.scrollTo(0, 0);
      refContainer.current?.scrollTo(0, 0);
    }
  }, [next]);

  const handleDone = useCallback(async () => {
    if (done?.isSave) {
      if (!user) {
        setVisibleUserModal(true);
      } else {
        done?.onAction && done?.onAction();
      }
    } else {
      done?.onAction && done?.onAction();
    }

    refMain.current?.scrollTo(0, 0);
    refContainer.current?.scrollTo(0, 0);
  }, [done, user]);

  const handleToClean = useCallback(() => {
    history.push(getIdSpecificationRoute(Urls.SPECIFICATION.url));
    setVisible(false);

    addToast({
      type: 'success',
      title: 'Especificação reiniciada com sucesso!',
    });
  }, [history, addToast]);

  const handleCloseUserModal = useCallback(async () => {
    setVisibleUserModal(false);

    setTimeout(() => {
      setIsSignIn(true);
    }, 250);
  }, []);

  const handleFinishedUserModal = useCallback(async () => {
    try {
      setLoadingSave(true);

      await api.put(`/specifications/${responding.id}/assignToMe`);

      done?.onAction && done?.onAction();

      setLoadingSave(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error!',
        description:
          'Ocorreu um erro e não foi possível vincular a especificação, tente novamente.',
      });

      setLoadingSave(false);
    }

    refMain.current?.scrollTo(0, 0);
    refContainer.current?.scrollTo(0, 0);
  }, [responding, done, addToast]);

  return (
    <>
      <Container ref={refContainer}>
        <Header>
          <div>
            <HeaderTitle title={title} />

            <div>
              {handleInfo && <Button icon={FiInfo} onClick={handleInfo} />}

              {handleDiagram && (
                <Button
                  className="button-diagram"
                  icon={FiEye}
                  onClick={handleDiagram}
                />
              )}

              {!responding.finished && (
                <Button icon={FiTrash2} onClick={() => setVisible(true)} />
              )}

              {user && (
                <DropdownUser
                  placement="bottomRight"
                  options={options}
                  button={{ icon: FiUser }}
                />
              )}
            </div>
          </div>
        </Header>

        <Main ref={refMain}>{children}</Main>

        {!isFooter && (
          <Footer>
            <div>
              <Actions>
                <div className={prev && next ? 'action-group-button' : ''}>
                  {prev && (
                    <Button
                      icon={prev.icon}
                      loading={prev.loading}
                      disabled={prev.disabled || loadingSave}
                      variant={prev.variant}
                      onClick={prev.onAction}
                    >
                      {prev.name}
                    </Button>
                  )}

                  {next && (
                    <Button
                      icon={next.icon}
                      loading={next.loading}
                      disabled={next.disabled || loadingSave}
                      variant={next.variant}
                      onClick={handleNext}
                    >
                      {next.name}
                    </Button>
                  )}
                </div>

                {done && (
                  <Button
                    icon={done.icon}
                    loading={done.loading || loadingSave}
                    disabled={done.disabled}
                    variant={done.variant}
                    onClick={handleDone}
                  >
                    {done.name}
                  </Button>
                )}

                {done && (
                  <div className="enter">
                    Pressione Enter
                    <FiCornerDownLeft />
                  </div>
                )}
              </Actions>

              <ProgressFooter
                title={`${responding.progress || 0}% concluído`}
                progress={responding.progress || 0}
              />
            </div>
          </Footer>
        )}
      </Container>

      <Modal visible={visible} onClose={() => setVisible(false)}>
        <ModalContent>
          <FiHelpCircle />
          <div>
            <strong>
              Você tem certeza que deseja descartar a especificação em
              andamento?
            </strong>
            <p>
              Ao clicar no botão “sim”, não será possível recuperar as escolhas
              feitas até o momento.
            </p>
          </div>

          <footer>
            <Button onClick={() => setVisible(false)}>Não</Button>
            <Button variant="primary" onClick={handleToClean}>
              Sim
            </Button>
          </footer>
        </ModalContent>
      </Modal>

      <User
        visible={visibleUserModal}
        info="Se desejar salvar suas escolhas para exportar a especificação técnica ao final da navegação, crie sua conta e realize o login na plataforma."
        isSignIn={isSignIn}
        setIsSignIn={setIsSignIn}
        onClose={handleCloseUserModal}
        onFinished={handleFinishedUserModal}
      />

      {loading && <Loader isInFront />}
    </>
  );
};

export default ContentTechnicalSpecification;
