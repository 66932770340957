import styled from 'styled-components';
import { tint, transparentize } from 'polished';

import Progress from '../Progress';
import Dropdown from '../Dropdown';

import imgBGMain from '../../assets/bg-content.svg';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1199px) {
    height: calc(100% - 80px);
    margin-top: 80px;
    overflow: auto;
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    height: calc(100% - 70px);
    margin-top: 70px;
  }
`;

export const Header = styled.header`
  position: relative;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 12px
    ${props => transparentize(0.95, props.theme.colors.black)};
  z-index: 1;

  > div {
    height: 80px;
    padding: 0 30px;
    border-bottom: 1px solid ${props => tint(0.95, props.theme.colors.black)};

    display: flex;
    justify-content: space-between;
    align-items: center;

    > h1 {
      padding-right: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    > div {
      height: 70px;
      padding: 0 20px;

      .button-diagram {
        display: none;
      }
    }
  }
`;

export const DropdownUser = styled(Dropdown)`
  @media (max-width: 1199px) {
    display: none;
  }
`;

export const Main = styled.main`
  flex: 1;
  background: transparent url(${imgBGMain}) 0% 0% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;

  @media (max-width: 1199px) {
    overflow: visible;
  }
`;

export const Footer = styled.footer`
  position: relative;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 12px
    ${props => transparentize(0.95, props.theme.colors.black)};
  z-index: 1;

  > div {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid ${props => tint(0.95, props.theme.colors.black)};

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    > div {
      height: 70px;
      padding: 0 20px;
    }
  }
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  > div {
    margin-right: 15px;

    display: flex;
    align-items: center;

    &.action-group-button {
      button {
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }

        &:last-child {
          position: relative;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;

          &:after {
            content: '';
            position: absolute;
            background: ${props => tint(0.9, props.theme.colors.black)};
            width: 1px;
            height: 50%;
            left: -0.5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    &.enter {
      margin-left: 15px;

      > svg {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 1199px) {
    justify-content: space-between;

    .enter {
      display: none;
    }
  }
`;

export const ProgressFooter = styled(Progress)`
  width: 150px;

  @media (max-width: 1199px) {
    display: none;
  }
`;

export const ModalContent = styled.div`
  margin: 30px 0;
  text-align: center;

  svg {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    color: ${props => props.theme.colors.warning};
  }

  div {
    margin-top: 30px;

    strong {
      font-size: 18px;
      color: ${props => props.theme.colors.secundary};
    }

    p {
      margin-top: 15px;
    }
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    button:last-child {
      margin-left: 20px;
    }
  }
`;
