import styled, { css, keyframes } from 'styled-components';
import { tint, transparentize } from 'polished';

import HeaderLayout from '../../components/HeaderLayout';

import imgBGNavigation from '../../assets/bg-navigation.svg';

interface MenuItemProps {
  isActive: boolean;
  isCheck: boolean;
}

interface NavigationProps {
  collapsed?: boolean;
}

export const HeaderLayoutCustom = styled(HeaderLayout)`
  display: none;

  @media (max-width: 1199px) {
    display: block;
  }
`;

export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
`;

const appearNavigationRigth = keyframes`
  from {
    opacity: 0;
    max-width: 380px;
  }
  to {
    opacity: 1;
    max-width: 350px;
  }
`;

const appearNavRigth = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Navigation = styled.div<NavigationProps>`
  flex-shrink: 0;
  background: ${props => props.theme.colors.secundary} url(${imgBGNavigation})
    0% 0% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  /* animation: ${appearNavigationRigth} 1s; */

  nav,
  img {
    animation: ${appearNavRigth} 1s;
  }

  > div,
  > nav {
    padding: 30px;
  }

  nav {
    flex: 1;
    overflow: auto;
  }

  @media (max-width: 1199px) {
    position: fixed;
    max-width: none;
    height: 100vh;
    left: 0;
    top: 0;
    transition: all 0.5s;
    transform: translateX(-100%);
    z-index: 9;

    ${props =>
      props.collapsed &&
      css`
        transform: translateX(0);
      `}

    > div {
      display: none;
    }

    > nav {
      padding-top: 130px;
    }
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    > nav {
      padding-top: 120px;
    }
  }
`;

export const MenuItem = styled.li<MenuItemProps>`
  position: relative;
  padding-bottom: 30px;

  display: flex;

  > span {
    flex-shrink: 0;

    width: 50px;
    height: 50px;
    border-radius: 12px;
    border: 1px solid ${props => transparentize(0.9, props.theme.colors.white)};
    color: ${props => props.theme.colors.white};
    font-size: 20px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-item-title {
    margin-left: 15px;

    span {
      margin-top: 10px;
      color: ${props => transparentize(0.4, props.theme.colors.white)};
      font-size: 12px;
      display: block;
    }

    strong {
      color: ${props => props.theme.colors.white};
      font-size: 16px;

      display: block;
    }

    > ul {
      margin-top: 30px;

      > li {
        color: ${props => props.theme.colors.white};
        font-size: 18px;

        & + li {
          margin-top: 20px;
        }

        > ul li {
          margin-top: 10px;

          color: ${props => transparentize(0.2, props.theme.colors.white)};

          &.item-active {
            color: ${props => transparentize(0.2, props.theme.colors.primary)};
          }
        }
      }
    }
  }

  ${props =>
    props.isActive &&
    css`
      > span {
        background: ${props.theme.colors.primary};
        border-color: ${props.theme.colors.primary};
      }

      .nav-item-title {
        span {
          color: ${props.theme.colors.white};
        }

        strong {
          color: ${props.theme.colors.primary};
        }
      }
    `}

  ${props =>
    props.isCheck &&
    css`
      > span {
        background: ${tint(0.8, props.theme.colors.primary)};
        border-color: ${tint(0.8, props.theme.colors.primary)};

        > svg {
          color: ${props.theme.colors.primary};
          font-size: 22px;
        }
      }
    `}
`;

export const TailItem = styled.div`
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 24.5px;
  padding-top: 55px;
  padding-bottom: 5px;

  &::after {
    content: '';
    background: ${props => transparentize(0.8, props.theme.colors.white)};
    width: 1px;
    height: 100%;
    display: inline-block;
  }
`;

export const Content = styled.div`
  flex: 1;
  background: ${props => tint(0.981, props.theme.colors.black)};
  overflow: hidden;
`;
