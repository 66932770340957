import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  BiBold,
  BiCode,
  BiCodeAlt,
  BiItalic,
  BiListOl,
  BiListUl,
  BiUnderline,
} from 'react-icons/bi';

import {
  Container,
  Toolbar,
  Content,
  RichText,
  Placeholder,
  Error,
} from './styles';
import Label from '../Label';

interface RichTextEditorProps {
  name: string;
  placeholder?: string;
  label?: string;
  height?: number;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  name,
  placeholder,
  label,
  height,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const richTextEditor = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isShowCode, setIsShowCode] = useState(false);
  const [code, setCode] = useState('');

  const { fieldName, error, registerField, defaultValue } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const execCommandWithArg = useCallback(
    (command: string, arg?: string) => {
      if (richTextEditor.current) {
        switch (command) {
          case 'showCode':
            if (richTextEditor.current) {
              if (isShowCode) {
                richTextEditor.current.innerHTML = `${richTextEditor.current.textContent}`;
                setIsShowCode(false);
              } else {
                richTextEditor.current.textContent =
                  richTextEditor.current.innerHTML;
                setIsShowCode(true);
              }
            }
            break;

          default:
            richTextEditor.current.ownerDocument.execCommand(
              command,
              false,
              arg,
            );
            break;
        }

        if (inputRef.current) {
          inputRef.current.value = `${richTextEditor.current?.innerHTML}`;
        }
      }
    },
    [isShowCode],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(_, value: string) {
        if (inputRef.current) {
          inputRef.current.value = value || '';
        }

        setCode(value || '');
      },
      clearValue() {
        if (inputRef.current) {
          inputRef.current.value = '';
        }

        setCode('');
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (richTextEditor.current) {
      richTextEditor.current.addEventListener('keyup', () => {
        if (inputRef.current) {
          if (richTextEditor.current?.textContent) {
            inputRef.current.value = `${richTextEditor.current?.innerHTML}`;
          } else {
            inputRef.current.value = '';
            setCode('');
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (richTextEditor.current && code) {
      richTextEditor.current.innerHTML = code;
    }
  }, [code]);

  return (
    <>
      {label && <Label fieldName={fieldName}>{label}</Label>}

      <Container isErrorred={!!error} isFilled={isFilled} isFocused={isFocused}>
        <Toolbar>
          {/* <button type="button" onClick={() => execCommandWithArg('justif')}>
            <BiAlignLeft />
          </button>
          <button
            type="button"
            onClick={() => execCommandWithArg('justifCenter')}
          >
            <BiAlignMiddle />
          </button>
          <button
            type="button"
            onClick={() => execCommandWithArg('justifFull')}
          >
            <BiAlignJustify />
          </button>
          <button
            type="button"
            onClick={() => execCommandWithArg('justifRight')}
          >
            <BiAlignRight />
          </button> */}

          <button type="button" onClick={() => execCommandWithArg('bold')}>
            <BiBold />
          </button>
          <button type="button" onClick={() => execCommandWithArg('italic')}>
            <BiItalic />
          </button>
          <button type="button" onClick={() => execCommandWithArg('underline')}>
            <BiUnderline />
          </button>

          <button
            type="button"
            onClick={() => execCommandWithArg('insertOrderedList')}
          >
            <BiListOl />
          </button>
          <button
            type="button"
            onClick={() => execCommandWithArg('insertUnorderedList')}
          >
            <BiListUl />
          </button>

          {/* <button
            type="button"
            onClick={() => execCommandWithArg('insertImage')}
          >
            <BiImage />
          </button>
          <button
            type="button"
            onClick={() => execCommandWithArg('createLink')}
          >
            <BiLink />
          </button> */}

          <button
            type="button"
            onClick={() => execCommandWithArg('showCode')}
            name="active"
          >
            {isShowCode ? <BiCode /> : <BiCodeAlt />}
          </button>
        </Toolbar>

        <Content isShowCode={isShowCode}>
          <RichText
            ref={richTextEditor}
            contentEditable="true"
            height={height}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />

          {placeholder && !isFocused && !inputRef.current?.value && (
            <Placeholder>{placeholder}</Placeholder>
          )}
        </Content>
        <textarea defaultValue={defaultValue} ref={inputRef} />
      </Container>

      {error && <Error>{error}</Error>}
    </>
  );
};

export default RichTextEditor;
