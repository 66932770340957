import styled from 'styled-components';
import { tint } from 'polished';

export const Container = styled.div`
  background: ${props => props.theme.colors.white};
  margin-top: 30px;
  padding: 30px;
  border: 1px solid ${props => tint(0.95, props.theme.colors.black)};
  border-radius: 12px;

  strong {
    font-size: 20px;
  }

  ul {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const LegendRedItem = styled.li`
  position: relative;
  margin-top: 10px;
  padding-right: 30px;
  width: 100%;
  max-width: 330px;

  display: flex;
  align-items: center;

  > div {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
  }
`;
