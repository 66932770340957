import React, { useCallback, useRef } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';

import { FiFileText, FiImage } from 'react-icons/fi';
import { BiSitemap } from 'react-icons/bi';
import Items from './Items';
import Node from './Node';

import HeaderTitle from '../HeaderTitle';
import Result from '../Result';
import Button from '../Button';

import {
  Container,
  Header,
  Content,
  LegendDiagram,
  ActionsResult,
} from './styles';

export interface ItemProps {
  id: number;
  title: string;
  legend: string;
  is_vertical?: boolean;
  items?: ItemProps[];
}

export interface treeProps {
  title: string;
  legend: string;
  items?: ItemProps[];
}

interface DiagramProps {
  title?: string;
  tree: treeProps;
  isScroll?: boolean;
  isBorder?: boolean;
  isMdHide?: boolean;
}

const Diagram: React.FC<DiagramProps> = ({
  title,
  tree,
  isBorder = false,
  isScroll = false,
  isMdHide = false,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refDiagram = useRef<HTMLUListElement>(null);
  const refDiagramContent = useRef<HTMLDivElement>(null);

  const handleExport = useCallback((type: string) => {
    const container = refContainer.current;
    const diagram = refDiagram.current;
    const content = refDiagramContent.current;

    if (container && diagram && content) {
      const widthContianer = diagram.clientWidth;
      const heightContianer = diagram.clientHeight + 300;

      html2canvas(container, {
        onclone: doc => {
          const diagramElement = doc.getElementById('diagram');
          const contentElement = doc.getElementById('diagram-content');

          if (diagramElement) {
            diagramElement.classList.add('export-diagram');
            diagramElement.style.width = `${diagram.clientWidth}px`;
          }

          if (contentElement) {
            contentElement.style.height = `${diagram.clientHeight}px`;
          }
        },
        width: widthContianer,
        height: heightContianer,
      }).then(canvas => {
        const imgDataUrl = canvas.toDataURL('image/png');

        if (type === 'pdf') {
          const doc = new JsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [widthContianer, heightContianer],
          });
          const { width } = doc.internal.pageSize;
          // const { height } = doc.internal.pageSize;
          doc.addImage(imgDataUrl, 'PNG', 0, 0, width, 0);
          doc.save('Especificação.pdf');
        }

        if (type === 'png') {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = 'Especificação.png';
          a.click();
        }
      });
    }
  }, []);

  return (
    <>
      <Container id="diagram" ref={refContainer} isMdHide={isMdHide}>
        {title && (
          <Header>
            <HeaderTitle title={title} />

            <div>
              <Button icon={FiFileText} onClick={() => handleExport('pdf')}>
                Exportar em PDF
              </Button>
              <Button icon={FiImage} onClick={() => handleExport('png')}>
                Exportar em PNG
              </Button>
            </div>
          </Header>
        )}

        <Content
          id="diagram-content"
          ref={refDiagramContent}
          isScroll={isScroll}
          isBorder={isBorder}
        >
          <div>
            <div>
              <ul ref={refDiagram}>
                <li>
                  <Node
                    title={tree.title}
                    legend={tree.legend}
                    isFull={!!tree.items}
                  />

                  {tree.items && <Items items={tree.items} />}
                </li>
              </ul>
            </div>
          </div>
        </Content>

        <LegendDiagram />
      </Container>

      {isMdHide && (
        <Result
          icon={BiSitemap}
          title={title || 'Árvore da especificação'}
          description="Clique no botão abaixo referente ao formato desejado."
        >
          <ActionsResult>
            <Button icon={FiFileText} onClick={() => handleExport('pdf')}>
              Exportar em PDF
            </Button>
            <Button icon={FiImage} onClick={() => handleExport('png')}>
              Exportar em PNG
            </Button>
          </ActionsResult>
        </Result>
      )}
    </>
  );
};

export default Diagram;
