import React, { useEffect, useState } from 'react';

import { Red } from '../../hooks/reds';

import ItemsReds from './ItemsReds';

import { Container } from './styles';

interface RedsProps {
  reds: Red[];
  onSelected(red: Red): void;
}

const Reds: React.FC<RedsProps> = ({ reds, onSelected }) => {
  const [contents, setContents] = useState<Red[]>([]);
  const [tools, setTools] = useState<Red[]>([]);
  const [platforms, setPlatforms] = useState<Red[]>([]);
  // const [hardwares, setHardwares] = useState<Red[]>([]);

  useEffect(() => {
    setContents(reds.filter(item => item.red_category.key === 'conteudos'));

    setTools(reds.filter(item => item.red_category.key === 'ferramentas'));

    setPlatforms(reds.filter(item => item.red_category.key === 'plataformas'));

    // setHardwares(reds.filter(item => item.red_category.key === 'hardware'));
  }, [reds]);

  return (
    <>
      <Container>
        <div>
          <h2>Software</h2>

          <div>
            <div>
              <h2>Conteúdos</h2>
              <ItemsReds reds={contents} onSelected={onSelected} />
            </div>
            <div>
              <h2>Ferramentas</h2>
              <ItemsReds reds={tools} onSelected={onSelected} />
            </div>
            <div>
              <h2>Plataformas</h2>
              <ItemsReds reds={platforms} onSelected={onSelected} />
            </div>
          </div>
        </div>

        {/* <div>
          <h2>Hardware</h2>
          <ItemsReds reds={hardwares} onSelected={onSelected} />
        </div> */}
      </Container>
    </>
  );
};

export default Reds;
