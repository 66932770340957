import React from 'react';

import { getLegentStatus, LEGEND } from '../../utils/const';

import { Container, LegendRedItem } from './styles';

interface LegendProps {
  className?: string;
}

const Legend: React.FC<LegendProps> = ({ className }) => (
  <Container className={className}>
    <strong>Legenda:</strong>

    <ul>
      <LegendRedItem>
        <div className={getLegentStatus(LEGEND.DIVISAO_SECAO)} />
        <span>Divisões de seção (ignoradas)</span>
      </LegendRedItem>
      <LegendRedItem>
        <div className={getLegentStatus(LEGEND.OPCIONAL)} />
        <span>Opcional (0,n)</span>
      </LegendRedItem>
      <LegendRedItem>
        <div className={getLegentStatus(LEGEND.AO_MENOS_UM)} />
        <span>Ao menos um (1,n)</span>
      </LegendRedItem>
      <LegendRedItem>
        <div className={getLegentStatus(LEGEND.UM_E_APENAS_UM)} />
        <span>Um e apenas um (1,1)</span>
      </LegendRedItem>
      <LegendRedItem>
        <div className={getLegentStatus(LEGEND.OBRIGATORIO)} />
        <span>Obrigatório (n,n)</span>
      </LegendRedItem>
    </ul>
  </Container>
);

export default Legend;
