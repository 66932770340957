import React, { useEffect, useRef, useCallback } from 'react';
import { useField } from '@unform/core';

import { FiCheck } from 'react-icons/fi';
import Label from '../Label';

import { Container, Error } from './styles';
import Row from '../Row';
import Col, { ColProps } from '../Col';

interface Option {
  id: string;
  value: string;
  label: string;
}

interface CheckboxInputProps extends Omit<ColProps, 'flex'> {
  name: string;
  label?: string;
  gutter?: number;
  options: Option[];
  onChange?(options: Option[]): void;
}
const CheckboxInput: React.FC<CheckboxInputProps> = ({
  name,
  label,
  gutter,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  options,
  onChange,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, error, defaultValue = [] } = useField(name);

  const handleChange = useCallback(() => {
    if (inputRefs.current.length > 0) {
      const actives = inputRefs.current
        .filter(ref => ref.checked)
        .map(ref => ref.value);

      const activeOptions = options.filter(
        o => actives.indexOf(`${o.value}`) > -1,
      );

      onChange && onChange(activeOptions);
    }
  }, [onChange, options]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref, index) => {
          inputRefs.current[index].checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref, index) => {
          if (values.includes(ref.id)) {
            inputRefs.current[index].checked = false;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <>
      {label && <Label fieldName={fieldName}>{label}</Label>}
      <Container isErrorred={!!error}>
        <Row gutter={gutter}>
          {options.map((option, index) => (
            <Col
              key={option.id}
              span={span}
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
              xxl={xxl}
            >
              <input
                defaultChecked={defaultValue.find(
                  (dv: string) => dv === option.id,
                )}
                ref={ref => {
                  inputRefs.current[index] = ref as HTMLInputElement;
                }}
                value={option.value}
                type="checkbox"
                id={option.id}
                onChange={() => handleChange()}
              />
              <label htmlFor={option.id}>
                <span>
                  <FiCheck />
                </span>
                {option.label}
              </label>
            </Col>
          ))}
        </Row>
      </Container>

      {error && <Error>{error}</Error>}
    </>
  );
};
export default CheckboxInput;
