import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons/lib';

import { FiChevronDown } from 'react-icons/fi';
import Label from '../Label';

import { Container, SelectDropdown, Dropdown, Option, Error } from './styles';

interface Option {
  id: string;
  label: string;
  value: string;
}

interface PropsSelect {
  label?: string;
  name: string;
  placeholder?: string;
  icon?: React.ComponentType<IconBaseProps>;
  options: Option[];
  onChange?(option: Option): void;
}

const Select: React.FC<PropsSelect> = ({
  name,
  label,
  placeholder,
  options,
  icon: Icon,
  onChange,
}) => {
  const [activeOption, setActiveOption] = useState<Option>({} as Option);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isTopDropdown, setIsTopDropdown] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: containerRef.current,
      getValue: () => {
        return activeOption.value;
      },
    });
  }, [fieldName, registerField, activeOption]);

  const handleUpdatePosition = useCallback(() => {
    const elementPosition = containerRef.current?.getBoundingClientRect();
    const heigth = window.innerHeight;

    if (elementPosition) {
      if (elementPosition.top + elementPosition.height + 330 < heigth) {
        setIsTopDropdown(false);
      } else {
        setIsTopDropdown(true);
      }
    }

    // // resolução navegador
    // document.write(`${screen.width}x${screen.height}`);

    // // resolução 'real' navegador
    // const width = window.innerWidthyarn star > 0 ? window.innerWidth : screen.width;
    // const height = window.innerHeight > 0 ? window.innerHeight : screen.height;
    // document.write(`${width}x${height}`);
  }, []);

  const handleSelectFocus = useCallback(() => {
    setIsFocused(!isFocused);
    handleUpdatePosition();
  }, [handleUpdatePosition, isFocused]);

  const handleSelectBlur = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (containerRef.current && !containerRef.current.contains(target)) {
      setIsFocused(false);
    }

    // setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleChange = useCallback(
    (newOption: Option) => {
      setActiveOption(newOption);
      onChange && onChange(newOption);
    },
    [onChange],
  );

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('mousedown', handleSelectBlur);
      // document.addEventListener('resize', handleUpdatePosition);
    }

    return () => {
      document.removeEventListener('mousedown', handleSelectBlur);
      // document.removeEventListener('resize', handleUpdatePosition);
    };
  }, [containerRef, isFocused, handleUpdatePosition, handleSelectBlur]);

  return (
    <>
      {label && <Label fieldName={fieldName}>{label}</Label>}

      <Container
        ref={containerRef}
        isErrorred={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        onClick={handleSelectFocus}
      >
        {Icon && <Icon size={20} />}

        <SelectDropdown isFocused={isFocused}>
          {activeOption.value ? (
            <span>{activeOption.label}</span>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}

          {/* <input
            type="search"
            autoComplete="new-off"
            // onFocus={handleInputFocus}
            // onBlur={handleInputBlur}
            // defaultValue={defaultValue}
          /> */}

          <FiChevronDown />
        </SelectDropdown>

        {isFocused && (
          <Dropdown isFocused={isFocused} isTopDropdown={isTopDropdown}>
            <div>
              {options.map(option => (
                <Option
                  key={option.id}
                  selected={option.value === activeOption.value}
                  onClick={() => handleChange(option)}
                >
                  {option.label}
                </Option>
              ))}

              {options.length === 0 && <Option>Nenhum item encontrado.</Option>}
            </div>
          </Dropdown>
        )}
      </Container>

      {error && <Error>{error}</Error>}
    </>
  );
};
export default Select;
