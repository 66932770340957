import { tint } from 'polished';
import styled from 'styled-components';
import Col from '../../components/Col';
import Row from '../../components/Row';

export const Container = styled.div`
  padding: 30px;

  > p {
    margin-top: 15px;
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 20px;
  }
`;

export const ModelSpecification = styled.div`
  background: #ffffff;
  padding: 30px;
  margin-top: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #000000;

  > h1 {
    text-align: center;
    font-size: 18px;
  }

  > h2 {
    margin: 15px 0;
    font-size: 16px;
  }

  > button {
    margin-top: 20px;
  }

  @media (max-width: ${props => props.theme.screen.md - 1}px) {
    padding: 15px 15px;
  }
`;

export const ExempleWhatIs = styled.div`
  margin-top: 30px;
  padding: 20px;
  border: 1px solid ${props => tint(0.7, props.theme.colors.info)};
  background: ${props => tint(0.95, props.theme.colors.info)};
  border-radius: 12px;

  > div {
    margin-top: 20px;
  }

  strong {
    display: block;
    font-weight: bold;
  }

  p {
    margin-top: 8px;
  }

  ol {
    margin-top: 8px;
    list-style: none;
    counter-reset: exemple-item-counter;

    li {
      counter-increment: exemple-item-counter;
      display: flex;

      & + li {
        margin-top: 10px;
      }

      &::before {
        flex: 0 0 1.5em;
        content: counter(exemple-item-counter) '.';
      }

      span {
        flex: 1;
      }
    }
  }
`;

export const TableCustom = styled.div`
  border: 1px solid #000000;

  > div {
    + div {
      border-top: 1px solid #000000;
    }

    > div {
      padding: 10px;

      + div {
        border-left: 1px solid #000000;
      }

      > button {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: ${props => props.theme.screen.lg - 1}px) {
    > div > div {
      + div {
        padding-top: 0;
        border-left: none;
      }

      &:last-child {
        flex: 1;
      }
    }
  }
`;

export const ColTitleItem = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.lg - 1}px) {
    display: block;

    span {
      display: inline;
      margin-right: 5px;
    }
  }
`;

export const RowTitle = styled(Row)`
  background: #ccc;
  font-weight: bold;
`;

export const RowTitleCol = styled(Row)`
  @media (max-width: ${props => props.theme.screen.lg - 1}px) {
    display: none;
  }
`;
