import styled, { css } from 'styled-components';
import { tint, transparentize } from 'polished';
import { LEGEND } from '../../../utils/const';

interface RequirementItemProps {
  type?: string;
}

export const Container = styled.ul`
  display: flex;
  position: relative;

  &.child-requirement {
    flex-direction: column;

    > li {
      width: 100%;
      margin-left: 0;
    }
  }

  > li {
    position: relative;
    display: inline-block;
    /* width: 25%; */
    max-width: 250px;
    /* z-index: 1; */

    div {
      position: relative;
      /* z-index: 2; */
    }

    + li {
      margin-left: 30px;
    }

    ul {
      position: relative;
      padding-left: 20px;
      /* z-index: 1; */

      li {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          /* background: ${props => tint(0.9, props.theme.colors.black)}; */
          width: 30px;
          height: 1px;
          left: -20px;
          top: 25px;
        }

        &::after {
          content: '';
          position: absolute;
          /* background: ${props => tint(0.9, props.theme.colors.black)}; */
          width: 1px;
          height: calc(100% + 40px);
          left: -20px;
          bottom: 0;
        }

        &:last-child::after {
          bottom: calc(100% - 25px);
          height: 40px;
        }
      }
    }
  }
`;

const requirementItemTypeVariations = (type: string) => {
  switch (type) {
    case LEGEND.OPCIONAL:
      return css`
        &::before,
        &::after {
          background: ${props => props.theme.colors.green};
        }
      `;

    case LEGEND.AO_MENOS_UM:
      return css`
        &::before,
        &::after {
          background: ${props => props.theme.colors.yellow};
        }
      `;

    case LEGEND.DIVISAO_SECAO:
      return css`
        &::before,
        &::after {
          background: ${props => tint(0.9, props.theme.colors.black)};
        }
      `;

    case LEGEND.UM_E_APENAS_UM:
      return css`
        &::before,
        &::after {
          background: ${props => props.theme.colors.indigo};
        }
      `;

    case LEGEND.OBRIGATORIO:
      return css`
        &::before,
        &::after {
          background: ${props => props.theme.colors.error};
        }
      `;

    default:
      return css`
        &::before,
        &::after {
          background: ${props => props.theme.colors.green};
        }
      `;
  }
};

export const RequirementItem = styled.li<RequirementItemProps>`
  ${props => requirementItemTypeVariations(props.type || '')}

  .container-requirement-popover {
    position: relative;
    padding: 0;
  }

  .requirement-popover {
    background-color: ${prosp => prosp.theme.colors.white};
    position: absolute;
    left: 80%;
    top: 50%;
    width: 250px;
    max-width: 270px;
    padding: 15px;
    border-radius: 8px;
    /* transform: translateY(-50%); */
    box-shadow: 0 0 10px
      ${prosp => transparentize(0.9, prosp.theme.colors.black)};
    z-index: 99999;

    &::before {
      content: '';
      position: absolute;
      left: -9px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid ${prosp => prosp.theme.colors.white};
      border-bottom: 10px solid transparent;
    }

    strong {
      display: block;
      margin-bottom: 10px;
      font-size: 18px;
      color: ${props => props.theme.colors.primary};
    }
  }
`;
