import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../services/api';
import isEmptyObject from '../utils/isEmptyObject';

import { useCalculateProdess } from './CalculateProdess';
import { useNonFunctional } from './nonFunctional';
import { useReds } from './reds';
import { useRoutesRequirements } from './routesRequirements';

interface Amount {
  description: string;
  estimated_quantity: number;
  unit_of_measurement: string;
}

export interface RespondingData {
  id?: string;
  what_is: string;
  how: string;
  what_you_want_acquire: string;
  description_requirement_nf: string;
  description_requirement_f: string;
  observation_1: string;
  observation_2: string;
  functional_requirements: number[];
  non_functional_requirements: number[];
  validate_requirement_category_id?: number;
  quantities: Amount[];
  red_id: number;
  additional_reds_ids: number[];
  progress?: number;
  finished?: boolean;
}

interface NewRespondingData {
  responding: RespondingData;
  progress: number;
  isUpdade: boolean;
}

interface RespondingContextData {
  responding: RespondingData;
  updateResponding(data: NewRespondingData): Promise<RespondingData>;
  createResponding(progress: number): Promise<RespondingData>;
  setRespondingData(newResponding: RespondingData): void;
  initResponding(): void;
}

const RespondingContext = createContext<RespondingContextData>(
  {} as RespondingContextData,
);

const RespondingProvider: React.FC = ({ children }) => {
  const [responding, setResponding] = useState<RespondingData>(() => {
    const respondingStorage = localStorage.getItem('@Taxonomia:responding');

    if (respondingStorage) {
      return JSON.parse(respondingStorage);
    }

    return {} as RespondingData;
  });

  const { initReds, primaryRed, selectedReds } = useReds();
  const { initRoutesRequirements } = useRoutesRequirements();
  const { initNonFunctional } = useNonFunctional();
  const { startProgress } = useCalculateProdess();

  const createResponding = useCallback(
    async (progress: number): Promise<RespondingData> => {
      if (isEmptyObject(responding)) {
        const { data } = await api.post<RespondingData>('specifications', {
          what_is: '',
          how: '',
          what_you_want_acquire: '',
          description_requirement_nf: '',
          description_requirement_f: '',
          observation_1: '',
          observation_2: '',
          functional_requirements: [],
          non_functional_requirements: [],
          quantities: [],
          red_id: primaryRed.id,
          additional_reds_ids: selectedReds
            .filter(r => r.id !== primaryRed.id)
            .map(red => red.id),
          progress,
        });

        localStorage.setItem('@Taxonomia:responding', JSON.stringify(data));

        setResponding(data);

        return data;
      }

      return responding;
    },
    [responding, primaryRed, selectedReds],
  );

  const updateResponding = useCallback(
    async ({
      responding: data,
      progress: newProgress,
      isUpdade,
    }: NewRespondingData): Promise<RespondingData> => {
      let progress = responding?.progress || 0;

      if (newProgress > progress) {
        progress = newProgress;
      }

      const newResponding = {
        ...data,
        progress,
        red_id: primaryRed.id,
        additional_reds_ids: selectedReds
          .filter(r => r.id !== primaryRed.id)
          .map(red => red.id),
      };

      setResponding(newResponding);

      localStorage.setItem(
        '@Taxonomia:responding',
        JSON.stringify(newResponding),
      );

      if (isUpdade) {
        await api.put<RespondingData>(
          `specifications/${newResponding?.id}`,
          newResponding,
        );
      }

      return newResponding;
    },
    [responding, selectedReds, primaryRed],
  );

  const setRespondingData = useCallback(
    (newResponding: RespondingData): void => {
      setResponding(newResponding);

      localStorage.setItem(
        '@Taxonomia:responding',
        JSON.stringify(newResponding),
      );
    },
    [],
  );

  const initResponding = useCallback(() => {
    initReds();
    initRoutesRequirements();
    initNonFunctional();
    startProgress(0);

    localStorage.removeItem('@Taxonomia:responding');
    setResponding({} as RespondingData);
  }, [initReds, initRoutesRequirements, initNonFunctional, startProgress]);

  return (
    <RespondingContext.Provider
      value={{
        responding,
        createResponding,
        updateResponding,
        setRespondingData,
        initResponding,
      }}
    >
      {children}
    </RespondingContext.Provider>
  );
};

function useResponding(): RespondingContextData {
  const context = useContext(RespondingContext);

  if (!context) {
    throw new Error('useResponding must be used within an RespondingProvider');
  }

  return context;
}

export { RespondingProvider, useResponding };
