import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { useRoutesRequirements } from '../../hooks/routesRequirements';
import { useCalculateProdess } from '../../hooks/CalculateProdess';
import { useResponding } from '../../hooks/responding';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import ContentTechnicalSpecification from '../../components/ContentTechnicalSpecification';
import Diagram, { treeProps } from '../../components/Diagram';

import isEmptyObject from '../../utils/isEmptyObject';
import { LEGEND } from '../../utils/const';
import Urls from '../../routes/urls';
import getIdSpecificationRoute from '../../utils/getIdSpecificationRoute';

import { Container } from './styles';

const SpecificationTree: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState<treeProps>({} as treeProps);

  const { responding, updateResponding } = useResponding();
  const { routesRequirement } = useRoutesRequirements();
  const { generateProgress, progress } = useCalculateProdess();
  const { addToast } = useToast();

  const history = useHistory();

  const handlePrev = useCallback(() => {
    const indexRoute = routesRequirement.length - 1;

    history.push(routesRequirement[indexRoute].path);
  }, [routesRequirement, history]);

  const handleNext = useCallback(async () => {
    try {
      setLoading(true);

      await updateResponding({
        responding,
        progress,
        isUpdade: true,
      });

      setLoading(false);

      history.push(
        getIdSpecificationRoute(Urls.FILL_IN_FINAL_DATA.url, responding.id),
      );
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error!',
        description:
          'Ocorreu um erro e não foi possível salvar, tente novamente.',
      });

      setLoading(false);
    }
  }, [responding, progress, history, updateResponding, addToast]);

  useEffect(() => {
    if (routesRequirement.length > 0) {
      generateProgress(routesRequirement.length + 1);
    }
  }, [generateProgress, routesRequirement]);

  useEffect(() => {
    if (responding?.finished) {
      history.replace(getIdSpecificationRoute(Urls.SPECIFICATION.url));
    }
  }, [history, responding?.finished]);

  useEffect(() => {
    if (!isEmptyObject(responding)) {
      api.get(`specifications/${responding.id}/hierarchy`).then(({ data }) => {
        setTree({
          title: 'Especifição',
          legend: LEGEND.DIVISAO_SECAO,
          items: [
            {
              id: 1,
              title: 'Requisitos funcionais',
              legend: LEGEND.DIVISAO_SECAO,
              items: data.functional_requirements,
            },
            {
              id: 2,
              title: 'Requisitos não funcionais',
              legend: LEGEND.DIVISAO_SECAO,
              items: data.non_functional_requirements,
            },
          ],
        });
      });
    }
  }, [responding]);

  return (
    <ContentTechnicalSpecification
      loading={loading}
      title="Exportar Especificação"
      prev={{
        name: 'Voltar',
        icon: FiChevronUp,
        disabled: loading,
        onAction: handlePrev,
      }}
      next={{
        name: 'Avançar',
        icon: FiChevronDown,
        loading,
        onAction: handleNext,
      }}
      done={{
        name: 'Salvar',
        variant: 'primary',
        disabled: loading,
        isSave: true,
        onAction: handleNext,
      }}
    >
      <Container>
        <Diagram
          title="Árvore da especificação"
          tree={tree}
          isScroll
          isBorder
          isMdHide
        />
      </Container>
    </ContentTechnicalSpecification>
  );
};

export default SpecificationTree;
