import styled, { css } from 'styled-components';
import { tint } from 'polished';

interface ContainerProps {
  type: 'column' | 'row';
}

interface ItemTreeProps {
  current: number;
  type: 'column' | 'row';
}

export const Container = styled.ul<ContainerProps>`
  display: flex;
  flex-direction: ${props => props.type};

  ${props =>
    props.type === 'row' &&
    css`
      align-items: center;
    `}
`;

export const ItemTree = styled.li<ItemTreeProps>`
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    line-height: 1.2;
  }

  svg {
    flex-shrink: 0;
    margin-right: 8px;
    font-size: 20px;
    color: ${props => tint(0.8, props.theme.colors.black)};

    ${props =>
      props.type === 'row' &&
      css`
        margin-left: 8px;
      `}
  }

  ${props =>
    props.current > 1 &&
    props.type === 'column' &&
    css`
      margin-left: ${15 * props.current}px;
    `};

  ${props =>
    props.current === 0 &&
    css`
      span {
        font-weight: 700;
        text-transform: uppercase;
        color: ${props.theme.colors.primary};
      }
    `};

  ${props =>
    props.current === 1 &&
    css`
      span {
        font-weight: 500;
      }
    `};
`;
